import { ElasticFile } from '@outmind/types';
import React, { memo } from 'react';

import { useStyles } from '../styles';
import { File } from '.';

/**
 * Displays the list of duplicate files
 */
const DuplicateFileListNoMemo: React.FC<DuplicateFileListProps> = ({
  duplicateFiles,
  maxDisplayedElements,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.duplicateFilesList}>
      {duplicateFiles.slice(0, maxDisplayedElements).map((duplicate) => (
        <File key={duplicate.id} isSimilarFile result={{ document: duplicate, score: 1 }} />
      ))}
    </div>
  );
};

export const DuplicateFileList = memo(DuplicateFileListNoMemo);

interface DuplicateFileListProps {
  duplicateFiles: ElasticFile[];
  maxDisplayedElements: number;
}
