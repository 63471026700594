import { makeStyles } from '../../material';

const baseFlexAlignCenter = {
  alignItems: 'center',
  display: 'flex',
};
export const useStyles = makeStyles(
  {
    container: {
      ...baseFlexAlignCenter,
      height: '100vh',
      justifyContent: 'center',
    },
    errorBoundryContainer: {
      ...baseFlexAlignCenter,
      flexDirection: 'column',
    },
    messageContainer: {
      ...baseFlexAlignCenter,
      flexDirection: 'column',
      margin: '24px 0 24px 0',
    },
    outmindLogo: {
      width: '80px',
    },
  },
  { index: 1 },
);
