import React from 'react';

import { ArrowRightIcon } from '../../../../material';
import { useStyles } from '../styles';

export const TreeDots: React.FC<TreeDotsProps> = ({ tailArr }) => {
  const classes = useStyles();
  return (
    <div className={classes.dots}>
      ...
      {tailArr ? <ArrowRightIcon className={classes.arrow} /> : null}
    </div>
  );
};

interface TreeDotsProps {
  tailArr?: string[];
}
