import React from 'react';

import { Tree } from './Tree';

/**
 * Injected when the user's window size is too small, it slices the path
 * to render a responsive arborescence
 * @returns the arborescence to render
 */
export const SlicedTree: React.FC<SlicedTreeProps> = ({ headSize, pathArr, tailSize }) => {
  const head = pathArr.slice(0, headSize);

  return (
    <Tree
      headArr={head}
      tailArr={tailSize ? pathArr.slice(pathArr.length - tailSize) : undefined}
    />
  );
};

interface SlicedTreeProps {
  headSize: number;
  pathArr: string[];
  tailSize?: number;
}
