import { Document as DocumentFilled } from '@styled-icons/fluentui-system-filled';
import { Document as DocumentRegular } from '@styled-icons/fluentui-system-regular';
import React from 'react';

export const FileIcon: React.FC<FileIconProps> = ({ className, isFilled, size }) =>
  isFilled ? (
    <DocumentFilled className={className} size={size} title="Document" />
  ) : (
    <DocumentRegular className={className} size={size} title="Document" />
  );

interface FileIconProps {
  className?: string;
  isFilled?: boolean;
  size?: number;
}
