import React from 'react';

import { useSpellingSuggestions, useTranslations } from '../../hooks';
import { Typography } from '../../material';
import { Actions, useDispatch, useSelector } from '../../store';
import { useStyles } from './styles';

/**
 * Displays the suggestion of the userQuery
 */
export const SpellingSuggestion: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { t } = useTranslations();

  const { data: spellingSuggestion } = useSpellingSuggestions(false);

  const { userQ, q } = useSelector((s) => s.search);

  const keepUserQuery = (): void => {
    dispatch(Actions.setSearchQuery(userQ));
    dispatch(Actions.refreshSearch());
  };

  const keepSuggestedQuery = (): void => {
    dispatch(Actions.setSearchUserQuery(q));
  };

  const searchWithCorrectedQuery = (): void => {
    if (spellingSuggestion?.suggestionQuery) {
      dispatch(Actions.setSearchUserQuery(spellingSuggestion.suggestionQuery));
      dispatch(Actions.setSearchQuery(spellingSuggestion.suggestionQuery));
      dispatch(Actions.refreshSearch());
    }
  };

  if (
    spellingSuggestion?.suggestionFound &&
    spellingSuggestion?.suggestionFormatedQuery &&
    q === userQ &&
    spellingSuggestion.suggestionQuery !== userQ
  ) {
    return (
      <div className={classes.spellingSuggestionContainer}>
        <div className={classes.spellingSuggestionInnerContainer}>
          <Typography className={classes.spellingSuggestionText} variant="subtitle1">
            {t('did_you_mean')}
          </Typography>
          <Typography
            className={classes.spellingSuggestionTextButton}
            dangerouslySetInnerHTML={{ __html: spellingSuggestion.suggestionFormatedQuery }}
            onClick={searchWithCorrectedQuery}
            variant="subtitle1"
          />
        </div>
      </div>
    );
  }

  if (!spellingSuggestion?.suggestionFound || q === userQ) return null;

  return (
    <div className={classes.spellingSuggestionContainer}>
      <div className={classes.spellingSuggestionInnerContainer}>
        <Typography className={classes.spellingSuggestionText} variant="subtitle1">
          {t('showing_results_for')}
        </Typography>
        <Typography
          className={classes.spellingSuggestionTextButton}
          dangerouslySetInnerHTML={{ __html: spellingSuggestion.suggestionFormatedQuery! }}
          onClick={keepSuggestedQuery}
          variant="subtitle1"
        />
      </div>
      <div className={classes.searchInsteadInnerContainer}>
        <Typography className={classes.spellingSuggestionText} variant="subtitle2">
          {t('search_instead_for')}
        </Typography>
        <Typography
          className={classes.spellingSuggestionTextButton}
          onClick={keepUserQuery}
          variant="subtitle2"
        >
          {spellingSuggestion.originalQuery}
        </Typography>
      </div>
    </div>
  );
};
