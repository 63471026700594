import { ElasticDoc } from '@outmind/types';
import React from 'react';

import { FilterChip, FilterChipProps } from './FilterChip';

/**
 * Displays a `FilterChip` for filtering by author through the provided `ElasticPerson`
 * @param props `PersonFilterChipProps`
 */
export const RelatedDocumentFilterChip: React.FC<RelatedDocumentFilterChipProps> = ({
  document,
  ...props
}) => {
  const { title } = document;
  const MAX_LENGTH = 20;
  return (
    <FilterChip {...props} label={title.slice(0, MAX_LENGTH)} type="filter:byRelatedDocument" />
  );
};

interface RelatedDocumentFilterChipProps extends Omit<FilterChipProps, 'type'> {
  document: ElasticDoc;
}
