import React from 'react';

import { useTranslations } from '../../hooks';
import { CustomDialog } from '../CustomDialog';
import { useStyles } from './styles';

export const FileNotFoundDialog: React.FC<DownloadAppDialogProps> = ({ close, openDialog }) => {
  const classes = useStyles();
  const { t } = useTranslations();

  return (
    <CustomDialog onClose={() => close()} open={openDialog} title={t('unable_to_access_document')}>
      <p className={classes.fileNotFoundDescription}>
        {t('check_if_file_is_stored_somehere_else')}
      </p>
    </CustomDialog>
  );
};

interface DownloadAppDialogProps {
  close: () => void;
  openDialog: boolean;
}
