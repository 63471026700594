import i18n from 'i18next';
import lngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { enTranslation } from './en';
import { frTranslation } from './fr';

/**
 * Loads the translations and initializes the "i18next-browser-languagedetector" and
 * the "react-i18next" modules
 */
export const loadI18n = async (): Promise<void> => {
  await i18n
    .use(lngDetector)
    .use(initReactI18next)
    .init({
      detection: {
        lookupFromPathIndex: 0,
        order: ['cookie', 'navigator', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
      },
      fallbackLng: 'en',
      resources: {
        en: { translation: enTranslation },
        fr: { translation: frTranslation },
      },
    });
};
