import React from 'react';

import HappyFolderImgSrc from '../../assets/happy-folder.png';
import { useTranslations } from '../../hooks';
import { Button, Typography } from '../../material';
import { Actions, useDispatch } from '../../store';
import { useStyles } from './styles';

export const DataLoadingBackground: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { t } = useTranslations();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.backgroundImageContainer}>
        <img alt="HappyFolder" className={classes.backgroundImage} src={HappyFolderImgSrc} />
      </div>
      <Typography className={classes.textPrimary} color="textPrimary">
        {t('your_data_is_coming')}
      </Typography>
      <Typography className={classes.textSecondary} color="textSecondary">
        {t('data_loading_helper')}
      </Typography>
      <Button
        className={classes.actionButton}
        color="primary"
        onClick={() => dispatch(Actions.refreshSearch())}
        onKeyPress={() => dispatch(Actions.refreshSearch())}
        size="large"
        variant="outlined"
      >
        {t('reload_the_page')}
      </Button>
    </div>
  );
};
