// Environement variables
const {
  REACT_APP_MAIN_IFRAME_URL: IFRAME_URL,
  REACT_APP_APP_NAME: APP_NAME,
  REACT_APP_MODULE_NAME: MODULE_NAME,
} = process.env;

const hashHistoryBasedComponents = [
  '@outmind/newtab-extension',
  '@outmind/spotlight-extension',
  '@outmind/spotlight-popup',
];

/**
 * Describes whether we should use an `HashBasedRouting` or `BrowserBasedRouting`
 */
export const shouldUseHashRouting =
  hashHistoryBasedComponents.indexOf(APP_NAME!) > -1 ||
  (APP_NAME === '@outmind/app-desktop' && MODULE_NAME === 'main' && !IFRAME_URL) ||
  (APP_NAME === '@outmind/app-desktop' && MODULE_NAME === 'spotlight');
