import { ActionTypes } from '../actions';
import { AppActions } from '../types';
import { initialState } from './defaults';
import { UserState } from './types';

/**
 * Reduce incoming action for state.user branch in Redux
 */
export const reducer = (state: UserState = initialState, action: AppActions): UserState => {
  switch (action.type) {
    case ActionTypes.MICROSOFT_TEAMS_DETECTED:
      return {
        ...state,
        inMicrosoftTeams: true,
      };

    default:
      return state;
  }
};
