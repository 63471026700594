import { Checkmark } from '@styled-icons/fluentui-system-filled';
import React from 'react';

import { useStyles } from './styles';

/**
 * SortByIcon render the sort by associated icon
 */
export const SortByIcon: React.FC<SortByIconProps> = ({
  chosenSortBy,
  currentSortBy,
  icon,
  isSelectOpen,
}) => {
  const classes = useStyles();

  if (chosenSortBy === currentSortBy && isSelectOpen) {
    return <Checkmark className={classes.sortItemIcon} size={16} />;
  }
  if (!isSelectOpen) {
    return icon;
  }
  return null;
};

interface SortByIconProps {
  chosenSortBy: string;
  currentSortBy: string;
  icon: JSX.Element;
  isSelectOpen: boolean;
}
