import React from 'react';
import { BrowserRouter, BrowserRouterProps, HashRouter, HashRouterProps } from 'react-router-dom';

import { shouldUseHashRouting } from './utils';

/**
 * Renders an `HashRouter` when we are not in a browser and a `BrowserRouter` otherwise
 */
export const Router: React.FC<HashRouterProps & BrowserRouterProps> = ({ children }) => {
  if (shouldUseHashRouting) {
    return <HashRouter>{children}</HashRouter>;
  }

  return <BrowserRouter>{children}</BrowserRouter>;
};
