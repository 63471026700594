/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      error: {
        color: '#f44336',
        fontSize: '0.75rem',
      },
      errorContainer: {
        height: '20px',
        paddingTop: '4px',
      },
      formControlLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      labelTreeContainer: {
        border: '1px solid #ccc',
        borderRadius: 3,
        height: '160px',
        overflow: 'auto',
        padding: '8px 16px',
        position: 'relative',
      },
      labelTreeSelectorContainer: {
        marginTop: '10px',
      },
      nestingDisabled: {
        overflow: 'hidden',
        pointerEvents: 'none',
      },
      nestingDisabledOverlay: {
        background: '#f3f3f3cf',
        height: '100%',
        left: 0,
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 2,
      },
    },
    { index: 1 },
  )();
