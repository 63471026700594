import { ExtendedSources, Source } from '@outmind/types';
import React from 'react';

import { useTranslations } from '../../../hooks';
import { ButtonBase, Card, CardContent, Grid, Typography } from '../../../material';
import { SourceLogo } from '../../SourceLogo';
import { useStyles } from './styles';

export const AddGSuiteButton: React.FC<AddGSuiteButtonProps> = ({
  disabled,
  setChosenSource,
  setShowAddSourceConfirmationDialog,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  return (
    <Grid item md={6} sm={12}>
      <Card className={classes.oneClickCard}>
        <ButtonBase
          classes={{ disabled: classes.sourceButtonDisabled }}
          className={classes.sourceButton}
          disabled={disabled}
          onClick={() => {
            setChosenSource('gsuite');
            setShowAddSourceConfirmationDialog(true);
          }}
        >
          <CardContent
            classes={{ root: classes.cardContentRoot }}
            className={classes.oneClickCardContent}
          >
            <div className={classes.oneClickIconsContainer}>
              <div className={classes.googleLogoContainer}>
                <SourceLogo className={classes.sourceLogo} source="google" />
              </div>
              <div className={classes.sourceMiniLogoContainer}>
                <SourceLogo className={classes.sourceLogo} source={Source.Drive} />
              </div>
              <div className={classes.sourceMiniLogoContainer}>
                <SourceLogo className={classes.sourceLogo} source={Source.Gmail} />
              </div>
            </div>
            <div className={classes.oneClickTextContainer}>
              <Typography className={classes.oneClickTextMain} variant="subtitle2">
                {t('cta_google_workspace')}
              </Typography>
            </div>
          </CardContent>
        </ButtonBase>
      </Card>
    </Grid>
  );
};

interface AddGSuiteButtonProps {
  disabled?: boolean;
  setChosenSource: (source: ExtendedSources) => void;
  setShowAddSourceConfirmationDialog: (bool: boolean) => void;
}
