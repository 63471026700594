import { FlowType } from '@outmind/types';

import { useRuntimeEnv } from '../useRuntimeEnv';

/**
 * Hook used to retrieve the `FlowType` needed for authentication
 */
export const useFlowType = (): FlowType => {
  const { inElectron, inMicrosoftTeams } = useRuntimeEnv();

  if (inElectron) return 'deep';
  if (inMicrosoftTeams) return 'teams';
  return 'web';
};
