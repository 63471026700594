/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';

export enum ActionTypes {
  SHOW_PRECONFIGURED_SOURCES_DIALOG = 'SHOW_PRECONFIGURED_SOURCES_DIALOG',
}

export const Actions = {
  showPreconfiguredSourcesDialog: (bool: boolean) =>
    action(ActionTypes.SHOW_PRECONFIGURED_SOURCES_DIALOG, bool),
};
