import React, { useCallback, useState } from 'react';

import MicrosoftLogo from '../../assets/auth/mslogo.svg';
import { useMicrosoftLogin, useRuntimeEnv } from '../../hooks';
import { ButtonBase } from '../../material';
import { MicrosoftButton } from '../MicrosoftButton';
import { useStyles } from './styles';

const { REACT_APP_WEBAPP_URL: WEBAPP_URL } = process.env;

export const MicrosoftLoginButton: React.FC<MicrosoftLoginButtonProps> = ({
  authCallback,
  buttonText,
  clientId,
  isError,
  variant,
}) => {
  const classes = useStyles({ variant });

  const [isLoading, setIsLoading] = useState(false);

  const { inElectron, inExtension } = useRuntimeEnv();

  const { login } = useMicrosoftLogin({
    authCallback: useCallback((err, res) => {
      if (res) authCallback(undefined, res);
      if (err) setIsLoading(false);
    }, []),
    clientId,
  });

  const loginStart = useCallback(() => {
    if (inElectron) {
      window.electron?.openWithDefaultHandler(
        `${WEBAPP_URL}/signin/start?flow=deep&provider=microsoft`,
      );
    } else if (inExtension) {
      window.open(`${WEBAPP_URL}/signin/start?flow=extension&provider=microsoft`);
      window.addEventListener('message', (e) => {
        if (e.data === 'will-close') window.document.location.reload();
      });
    }
  }, [inExtension, inElectron]);

  if (inElectron || inExtension) {
    return (
      <div className={classes.loginButtonContainer}>
        <ButtonBase className={classes.loginButton} onClick={loginStart}>
          <img alt="" className={classes.logo} height="18" src={MicrosoftLogo} width="18" />
          {buttonText}
        </ButtonBase>
      </div>
    );
  }

  return (
    <MicrosoftButton
      buttonText={buttonText}
      isLoading={isLoading && !isError}
      onClick={() => {
        setIsLoading(true);
        login();
      }}
      variant={variant}
    />
  );
};

interface MicrosoftLoginButtonProps {
  /**
   * Callback function for handling eventual error and success data
   */
  authCallback: (error?: Error, idToken?: string) => void;
  /**
   * Text to be displayed on the MicrosoftLoginButton
   * Default to "Connexion avec Microsoft"
   */
  buttonText: string;
  /**
   * Additional class name string.
   */
  className?: string;
  /**
   * Id of the `MicrosoftGraph` client used for logging in the users
   */
  clientId: string;
  isError?: boolean;
  variant?: 'medium' | 'large';
}
