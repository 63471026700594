/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      iconButton: {
        color: '#FAD271',
        padding: '4px',
        pointerEvents: 'auto',
      },
      reconnectButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '16px',
      },
    },
    { index: 1 },
  )();
