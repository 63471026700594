import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';

import { useTranslations } from '../../hooks';
import { TranslationKey } from '../../locales/types';
import { Button, ClearIcon } from '../../material';
import { useSelector } from '../../store';
import { useStyles } from './styles';

/**
 * Displays the notifications on the app state through Snackbars using notistack
 */
export const NotificationSnackbars: React.FC = () => {
  const { data, message, notificationId, type } = useSelector((s) => s.notifications);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();

  const { t } = useTranslations();

  useEffect(() => {
    if (message && type) {
      const displayedMessage = (
        <span className={classes.message}>{t(message as TranslationKey, data)}</span>
      );

      const action = (key: string): JSX.Element => (
        <Button onClick={() => closeSnackbar(key)}>
          <ClearIcon />
        </Button>
      );

      const options = {
        action,
        autoHideDuration: 5000,
        key: notificationId,
        preventDuplicate: true,
        variant: type,
      };
      enqueueSnackbar(displayedMessage, options);
    }
  }, [message, notificationId, type]);

  return null;
};
