/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      chevronContainer: {
        alignItems: 'center',
        display: 'flex',
      },
      chevronIcon: {
        color: theme.designSystem.colors.aux.main,
      },
      chevronIconHovered: {
        color: theme.designSystem.colors.secondary.main,
      },
      chevronText: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '14px',
        marginLeft: '12px',
      },
      chevronTextHovered: {
        color: theme.designSystem.colors.secondary.main,
        fontSize: '14px',
        marginLeft: '12px',
      },
      container: {
        cursor: 'pointer',
        display: 'flex',
        // paddingTop: '12px',
        position: 'relative',
      },
      hovered: {
        background: theme.designSystem.colors.active.main,
      },
      hoverOverlay: {
        height: '100%',
        marginLeft: '-32px',
        opacity: 0.05,
        paddingBottom: '4px',
        paddingTop: '4px',
        position: 'absolute',
        width: 'calc(100% + 64px)',
      },
    },
    { index: 1 },
  )();
};
