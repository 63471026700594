import React from 'react';

import { useTranslations } from '../../hooks';
import { Chip, ChipProps, ClearIcon, Typography } from '../../material';
import { useStyles } from './styles';

/**
 * Base element used to build other `FilterChips` like `FolderFilterChip` or `PersonFilterChip`
 * @param props `FilterChipProps`
 */
export const FilterChip: React.FC<FilterChipProps> = ({ label, type, ...props }) => {
  const classes = useStyles();

  const { t } = useTranslations();

  let chipLabelHelperText = '';
  if (type === 'filter:byPerson') chipLabelHelperText = t('by');
  if (type === 'filter:byRelatedDocument') chipLabelHelperText = t('related_to');
  if (type === 'filter:inFolder') chipLabelHelperText = t('in');

  const chipLabelElmnt = (
    <div className={classes.chipLabel}>
      <Typography className={classes.chipLabelHelperText}>{chipLabelHelperText}</Typography>
      <Typography className={classes.chipLabelMainText}>{label}</Typography>
    </div>
  );

  return <Chip {...props} deleteIcon={<ClearIcon />} label={chipLabelElmnt} size="small" />;
};

export interface FilterChipProps extends ChipProps {
  type: 'filter:byPerson' | 'filter:inFolder' | 'filter:byRelatedDocument';
}
