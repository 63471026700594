/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      link: {
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
        color: theme.designSystem.colors.active.main,
        textDecoration: 'none',
      },
    },
    { index: 1 },
  )();
};
