/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../../../hooks';
import { makeStyles } from '../../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      connectorStatusContainer: {},
      container: {
        alignItems: 'center',
        display: 'flex',
        height: '32px',
      },
      filterContainer: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        width: '100%',
      },
      filterLabel: {
        color: theme.designSystem.colors.secondary.main,
        fontSize: '14px',
        overflow: 'hidden',
        paddingLeft: '5px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      filterRoot: {
        height: '32px',
        width: '100%',
      },
      hovered: {
        background: theme.designSystem.colors.active.main,
      },
      hoverOverlay: {
        height: '100%',
        marginLeft: '-32px',
        opacity: 0.05,
        position: 'absolute',
        width: 'calc(100% + 64px)',
      },
      logo: {
        marginRight: '3px',
        maxHeight: '100%',
        width: '18px',
      },
      logoContainer: {
        display: 'flex',
        marginRight: '2px',
        overflow: 'visible',
        position: 'relative',
      },
      personAvatar: {
        borderRadius: '50%',
        bottom: '-2px',
        filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1))',
        height: '14px',
        position: 'absolute',
        right: '-2px',
        width: '14px',
      },
      pointerNone: {
        pointerEvents: 'none',
      },
      sharedIcon: {
        color: theme.designSystem.colors.active.main,
        fontSize: '1.2rem',
      },
      sharedIconContainer: {
        margin: '6px 2px 0 2px',
      },
      syncProgressContainer: {},
      tooltipPopper: { left: '-32px', width: 'fit-content' },
    },
    { index: 1 },
  )();
};
