import { String } from '@outmind/helpers';
import React, { memo, useEffect, useState } from 'react';

import { Label, useTranslations } from '../../../hooks';
import { Chip, InputBase, Menu, Typography } from '../../../material';
import { LabelChipList } from '../LabelChipList';
import { useStyles } from './styles';

const AddLabelMenuNP: React.FC<AddLabelMenuProps> = ({
  addLabel,
  anchorEl,
  createNewLabel,
  documentLabels,
  labels,
  onClose,
  menuPosition,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();
  const [filteredLabels, setFilteredLabels] = useState<Label[]>(labels);
  const [labelInputValue, setLabelInputValue] = useState('');
  const [shouldShowCreateButton, setShouldShowCreateButton] = useState(true);

  useEffect(() => {
    if (labelInputValue === '') {
      setFilteredLabels(labels.slice(0, 5));
      setLabelInputValue('');
    }
  }, [labelInputValue, labels]);

  useEffect(() => {
    setLabelInputValue('');
  }, [anchorEl]);

  const filterLabel = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setLabelInputValue(event.target.value);
    if (event.target.value === '') setFilteredLabels(labels);
    else {
      setFilteredLabels(
        labels.filter((label) =>
          String.normalize(label.fullName).includes(String.normalize(event.target.value)),
        ),
      );
      setShouldShowCreateButton(
        !labels.some(
          (label) =>
            String.normalize(event.target.value) === String.normalize(label.name) &&
            !label.parentId,
        ) &&
          !documentLabels.some(
            (label) =>
              String.normalize(event.target.value) === String.normalize(label.name) &&
              !label.parentId,
          ),
      );
    }
  };

  const preventPropagation = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorPosition={
        menuPosition ? { left: menuPosition.mouseX, top: menuPosition.mouseY } : undefined
      }
      anchorReference={menuPosition ? 'anchorPosition' : 'anchorEl'}
      disableAutoFocusItem
      keepMounted
      onClick={(e) => preventPropagation(e as React.MouseEvent)}
      onClose={() => {
        setFilteredLabels(labels.slice(0, 5));
        setLabelInputValue('');
        onClose();
      }}
      open={Boolean(anchorEl)}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
    >
      <div />
      <div className={classes.menuContainer}>
        <div className={classes.inputContainer}>
          <InputBase
            classes={{ root: classes.inputRoot }}
            onChange={filterLabel}
            placeholder={t('search_option')}
            value={labelInputValue}
          />
        </div>
        <div className={classes.labelsFilterContainer}>
          <Typography className={classes.selectOrCreateTip}>{t('select_or_create_tag')}</Typography>
          <LabelChipList
            displayWrap
            labels={filteredLabels}
            onClickLabel={(label: Label, e?: React.MouseEvent<HTMLDivElement>): void => {
              addLabel(label);
              onClose(e);
            }}
          />
          {filteredLabels.length === 0 && (
            <Typography className={classes.noLabelFoundTip}>{t('no_tag_found')}</Typography>
          )}
        </div>
        {labelInputValue && shouldShowCreateButton ? (
          <div className={classes.createContainer}>
            <span className={classes.createCallToAction}>{t('create')}</span>
            <Chip
              classes={{
                colorPrimary: classes.colorPrimary,
                root: classes.createTag,
              }}
              color="primary"
              label={labelInputValue}
              onClick={(): void => {
                onClose();
                createNewLabel(labelInputValue);
              }}
              size="small"
              variant="outlined"
            />
          </div>
        ) : null}
      </div>
    </Menu>
  );
};

interface AddLabelMenuProps {
  addLabel: (label: Label) => void;
  anchorEl: HTMLElement | null;
  createNewLabel: (labelName: string) => void;
  documentLabels: Label[];
  labels: Label[];
  menuPosition?: { mouseX: number; mouseY: number };
  onClose: (e?: React.MouseEvent<HTMLDivElement>) => void;
}

export const AddLabelMenu = memo(AddLabelMenuNP);
