/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      indexTabIcon: {
        color: theme.designSystem.colors.aux.main,
        fill: 'inherit',
        fontSize: '22px',
        padding: '8px 8px 0 0',
      },
      titleIcon: {
        marginBottom: '4px',
        marginRight: '8px',
      },
    },
    { index: 1 },
  )();
};
