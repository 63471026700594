import { v4 as uuid } from 'uuid';

import { SearchState } from './types';

export const initialState: SearchState = {
  chosenIndexTab: '*',
  chosenSortBy: 'relevance',
  mode: 'GET_LAST_ELEMENTS',
  q: '',
  queryId: uuid(),
  userQ: '',
};
