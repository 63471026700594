import React, { memo } from 'react';

import { Label } from '../../../hooks';
import { LabelChip } from '../LabelChip';
import { useStyles } from '../styles';

const LabelChipListNP: React.FC<LabelChipListProps> = ({
  labels,
  onClickLabel,
  onDeleteLabel,
  displayWrap,
}) => {
  const classes = useStyles({});

  const labelChips = labels.map((label) => (
    <LabelChip key={label.id} label={label} onClick={onClickLabel} onDelete={onDeleteLabel} />
  ));
  if (displayWrap) return <div className={classes.labelChipsContainer}>{labelChips}</div>;
  return <>{labelChips}</>;
};

interface LabelChipListProps {
  displayWrap?: boolean;
  labels: Label[];
  onClickLabel?: (label: Label, e?: React.MouseEvent<HTMLDivElement>) => void;
  onDeleteLabel?: (label: Label, e?: React.MouseEvent<HTMLDivElement>) => void;
}

export const LabelChipList = memo(LabelChipListNP);
