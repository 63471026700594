import { makeStyles } from '../../../material';

export const useStyles = makeStyles(
  {
    contactPersonButton: {
      width: '100%',
    },
    contactPersonButtons: {
      width: '100%',
    },
    contactPersonButtonsContainer: {
      padding: '0px 20px 20px 20px',
    },
    leadStatusChipContainer: {
      marginTop: '8px',
    },
    leadStatusChipLabel: {
      alignItems: 'center',
      display: 'flex',
      padding: '4px',
    },
    leadStatusChipLabelArrow: {
      fontSize: '1rem',
    },
    leadStatusChipLabelText: {
      lineHeight: 1,
    },
    name: {
      color: 'inherit',
      display: 'block',
      fontSize: '0.875rem',
      fontWeight: 600,
      marginRight: '5px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    nameContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
      maxWidth: '100%',
    },
    personAvatar: {
      height: '70px',
      width: '70px',
    },
    personCard: {
      borderRadius: '10px',
      boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.08)',
      color: '#586774',
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
    personCardContent: {
      '&:last-child': {
        paddingBottom: '0px',
      },
      color: '#222222',
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
      textDecoration: 'none',
    },
    personContainer: { padding: '8px' },
    personInfo: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '0.875rem',
      height: '180px',
      padding: '10px 20px 10px 20px',
    },
    position: {
      fontWeight: 'bold',
    },
    subInfos: {
      color: '#666',
      display: 'block',
      fontSize: '0.825rem',
      lineHeight: '1.25rem',
      overflow: 'hidden',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
    },
  },
  { index: 1 },
);
