import { SearchMissionsResult } from '@outmind/types';
import React from 'react';

import { useLinkHandler, useTranslations } from '../../hooks';
import { Grid, Paper } from '../../material';
import { DocumentBusiness } from './DocumentBusiness';
import { DocumentContent } from './DocumentContent';
import { DocumentDate } from './DocumentDate';
import { DocumentHeader } from './DocumentHeader';
import { DocumentParticipant } from './DocumentParticipant';
import { useStyles } from './styles';

/**
 * Displays an ElasticMission document
 */
export const Mission: React.FC<MissionProps> = ({ result }) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const { document, highlights } = result;

  const isCreatedDate = document.date === document.createdDate;
  const isLastModifiedDate = document.date === document.lastModifiedDate;

  const { prestations = [] } = document;
  const prestationsElements = prestations.map(
    ({ document: prestation, highlights: prestationHighlights }) => {
      const displayedConsultants =
        prestationHighlights?.consultantNames || prestation.consultants.map((col) => col.name);
      const cleanedDisplayedConsultants = `${displayedConsultants.slice(0, 5).join(', ')}${
        displayedConsultants.length > 5
          ? t('and_x_other_persons', { count: displayedConsultants.length - 5 })
          : ''
      }`;

      const displayedContent = prestationHighlights?.content || prestation.summary;
      const displayedTitle = prestationHighlights?.title || prestation.title;

      return (
        <Grid item md={6} xs={12}>
          <div className={classes.prestation}>
            <DocumentHeader documentId={document.id} title={displayedTitle} />
            <DocumentContent content={displayedContent} />
            <DocumentParticipant name={cleanedDisplayedConsultants} />
          </div>
        </Grid>
      );
    },
  );

  const displayedCommercialName = highlights?.commercialName || document.commercial.name;
  const displayedContent = highlights?.content || document.summary;
  const displayedCustomerName = highlights?.customerName || document.customerName;
  const displayedTitle = highlights?.title || document.title;

  const goToMission = useLinkHandler(document.accessLink);

  return (
    <Paper className={classes.documentContainer} elevation={0} onClick={goToMission}>
      <DocumentHeader documentId={document.id} source={document.source} title={displayedTitle} />
      <div className={classes.resultElementMetas}>
        <DocumentParticipant name={displayedCommercialName} />
        <DocumentBusiness name={displayedCustomerName} />
      </div>
      <DocumentContent content={displayedContent} />
      <Grid className={classes.prestationsContainer} container spacing={2}>
        {prestationsElements}
      </Grid>
      <DocumentDate
        date={document.date}
        isCreatedDate={isCreatedDate}
        isLastModifiedDate={isLastModifiedDate}
      />
    </Paper>
  );
};

interface MissionProps {
  result: SearchMissionsResult;
}
