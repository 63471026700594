import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const CheckedBoxIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#245EE5" height="15" rx="1.5" stroke="#245EE5" width="15" x="0.5" y="0.5" />
      <g clipPath="url(#clip0_95_1506)">
        <path
          d="M6.22883 11.6094C5.94497 11.6095 5.67274 11.4967 5.47219 11.2958L3.18461 9.00909C2.93846 8.76287 2.93846 8.36374 3.18461 8.11752C3.43083 7.87137 3.82996 7.87137 4.07618 8.11752L6.22883 10.2702L11.9238 4.57517C12.17 4.32903 12.5692 4.32903 12.8154 4.57517C13.0615 4.8214 13.0615 5.22052 12.8154 5.46675L6.98547 11.2958C6.78492 11.4967 6.51269 11.6095 6.22883 11.6094Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_95_1506">
          <rect fill="white" height="10" transform="translate(3 3)" width="10" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
