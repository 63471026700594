import React from 'react';
import { CloudDownload } from 'tabler-icons-react';

import { useGetUpdateState, useTranslations } from '../../hooks';
import { Paper, Typography } from '../../material';
import { useStyles } from './styles';

/**
 * Displays a banner that allow the user to update the app if there a new update
 */
export const UpdateNotificationBanner: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  const { data: updateState } = useGetUpdateState();

  if (!updateState?.shouldNotifyUpdate || !updateState?.updateAvailable) return null;

  return (
    <Paper
      className={classes.notificationContainer}
      elevation={0}
      onClick={() => window.electron?.restartAndUpdate()}
    >
      <div className={classes.notificationInnerContainer}>
        <div className={classes.iconContainer}>
          <CloudDownload color="#2C65BA" size={28} strokeWidth={2} />
        </div>
        <div>
          <Typography className={classes.updateAvailableText} variant="subtitle1">
            {t('new_update_available')}
          </Typography>
          <Typography
            className={classes.updateAvailableTextButton}
            onClick={() => window.electron?.restartAndUpdate()}
            variant="subtitle2"
          >
            {t('click_here_to_install')}
          </Typography>
        </div>
      </div>
    </Paper>
  );
};
