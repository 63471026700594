import { makeStyles } from '../../material';

const buttonWrapperBasic = {
  alignItems: 'center' as const,
  display: 'flex' as const,
  justifyContent: 'center' as const,
  width: '400px',
};

export const useStyles = makeStyles(
  {
    buttonWrapper: buttonWrapperBasic,
    buttonWrapperMobile: {
      ...buttonWrapperBasic,
      width: '300px',
    },
  },
  { index: 1 },
);
