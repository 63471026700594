import React, { memo } from 'react';

import { Chip } from '../../material';
import { useStyles } from './styles';

const TagChipNP: React.FC<TagChipProps> = ({ tag }) => {
  const { color, value } = tag;

  const classes = useStyles({ color });

  return (
    <Chip
      classes={{
        colorPrimary: classes.colorPrimary,
        root: classes.chipRoot,
      }}
      color="primary"
      label={value}
      size="small"
    />
  );
};

interface TagChipProps {
  tag: { color: string; id: string; value: string };
}

export const DocumentTag = memo(TagChipNP);
