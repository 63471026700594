import { useQuery, UseQueryResult } from 'react-query';

/**
 * Retrieves the default spotlight shortcut set by the user
 */
export const useSpotlightShortcut = (): UseQueryResult<{ firstKey: string; secondKey: string }> => {
  const getShortcutQuery = useQuery<{ firstKey: string; secondKey: string }>(
    'GET_SPOTLIGHT_SHORTCUT',
    async () => {
      const shortcut = await window.electron?.getSpotlightShortcut();
      if (shortcut) {
        const shortcutCommands = shortcut.split('+');
        return {
          firstKey: shortcutCommands.slice(0, shortcutCommands.length - 1).join('+'),
          secondKey: shortcutCommands[shortcutCommands.length - 1],
        };
      }
      return {
        firstKey: 'Control',
        secondKey: 'Space',
      };
    },
  );

  return getShortcutQuery;
};
