import { colors, makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    error: {
      backgroundColor: colors.red[600],
    },
    icon: {
      fontSize: 17,
      marginRight: '3px',
      opacity: 0.9,
    },
    message: {
      alignItems: 'center',
      display: 'flex',
      width: '300px',
    },
    start: {
      backgroundColor: '#90caf9',
    },
    success: {
      backgroundColor: colors.green[600],
    },
    warning: {
      backgroundColor: colors.orange[600],
    },
  },
  { index: 1 },
);
