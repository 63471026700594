import React from 'react';

import { useTranslations } from '../../hooks';
import { Tooltip } from '../../material';
import { sourceLogosUrl } from './urls';

export const SourceLogo: React.FC<SourceLogoProps> = ({
  source,
  unknownSourceTooltip,
  ...rest
}) => {
  const { t } = useTranslations();

  const img = <img {...rest} alt="" src={sourceLogosUrl[source] ?? sourceLogosUrl['unknown']} />;

  if (!(source in sourceLogosUrl) && unknownSourceTooltip) {
    return (
      <Tooltip arrow title={t('unknown_source')}>
        {img}
      </Tooltip>
    );
  }

  return img;
};

interface SourceLogoProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  source: string;
  unknownSourceTooltip?: boolean;
}
