import { ExtendedSources } from '@outmind/types';
import React from 'react';

import { useGetAuthUrl, useLinkHandler, useRuntimeEnv, useTranslations } from '../../../../hooks';
import { IconButton, Tooltip, WarningRoundedIcon } from '../../../../material';
import { useStyles } from '../styles';

export const AddSourceWarning: React.FC<AddSourceWarningProps> = ({ source }) => {
  const { t } = useTranslations();
  const classes = useStyles();

  const { data: authUrl } = useGetAuthUrl(source);
  const { inElectron } = useRuntimeEnv();
  const onClick = useLinkHandler(authUrl, { targetBlank: inElectron });

  const tooltipTitle = `${t('click_to_access_source')}`;

  return (
    <Tooltip placement="right" title={tooltipTitle}>
      <IconButton aria-label="Info" classes={{ root: classes.iconButton }} onClick={onClick}>
        <WarningRoundedIcon />
      </IconButton>
    </Tooltip>
  );
};

interface AddSourceWarningProps {
  source: ExtendedSources;
}
