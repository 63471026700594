import { SearchContactsResult } from '@outmind/types';
import clsn from 'classnames';
import React from 'react';

import DefaultPersonImg from '../../../assets/default-person.png';
import { useTranslations } from '../../../hooks';
import {
  ArrowRightIcon,
  Avatar,
  Card,
  CardContent,
  Chip,
  Grid,
  Link,
  Typography,
} from '../../../material';
import { useSelector } from '../../../store';
import { SanitizedHtmlTypography } from '../..';
import { ContactPersonButtons } from '../../ContactPersonButtons';
import { useStyles } from './styles';

export const Contact: React.FC<ContactProps> = ({ result }) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const previewOpened = useSelector((s) => s.previews.isOpened);

  const {
    document: {
      accessLink,
      account,
      avatarUrl,
      category,
      email,
      name,
      phoneNumber,
      position,
      leadStatus,
    },
  } = result;

  const displayedName = name ?? email ?? '';
  const accountName = account?.name ?? '';

  const personName = (
    <Link className={classes.nameContainer} href={accessLink ?? '#'} target="_blank">
      <SanitizedHtmlTypography className={classes.name} html={displayedName} />
    </Link>
  );

  const personPosition = position ? (
    <Typography className={clsn(classes.position, classes.subInfos)} title={position}>
      {accountName ? t('position_at_account', { accountName, position }) : position}
    </Typography>
  ) : null;

  const personEmail = (
    <SanitizedHtmlTypography className={classes.subInfos} html={email ?? ''} title={email} />
  );

  const personHeader =
    displayedName !== email ? (
      <>
        {personName}
        {personPosition}
        {personEmail}
      </>
    ) : (
      <>
        {personName}
        {personPosition}
      </>
    );

  const leadStatusChip =
    category === 'lead' ? (
      <div className={classes.leadStatusChipContainer}>
        <Chip
          color="primary"
          label={
            <div className={classes.leadStatusChipLabel}>
              <Typography className={classes.leadStatusChipLabelText} variant="overline">
                {t('lead')}
              </Typography>
              <ArrowRightIcon className={classes.leadStatusChipLabelArrow} />
              <Typography className={classes.leadStatusChipLabelText} variant="overline">
                {leadStatus}
              </Typography>
            </div>
          }
          size="small"
        />
      </div>
    ) : null;

  return (
    <Grid
      item
      lg={previewOpened ? 6 : 4}
      md={previewOpened ? 12 : 6}
      onContextMenu={(e) => e.preventDefault()}
      sm={12}
      xl={previewOpened ? 6 : 3}
    >
      <div className={classes.personContainer}>
        <Card className={classes.personCard} elevation={0}>
          <CardContent className={classes.personCardContent}>
            <div className={classes.personInfo}>
              <Avatar className={classes.personAvatar} src={avatarUrl || DefaultPersonImg} />
              {personHeader}
              {leadStatusChip}
            </div>
            <div className={classes.contactPersonButtonsContainer}>
              <ContactPersonButtons
                alwaysEmail
                alwaysPhone
                email={email}
                phoneNumber={phoneNumber}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    </Grid>
  );
};

interface ContactProps {
  result: SearchContactsResult;
}
