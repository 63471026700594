import { ActionTypes } from '../actions';
import { AppActions } from '../types';
import { initialState } from './defaults';
import { PreconfiguredSourcesState } from './types';

/**
 * Reduce incoming action for state.preconfigured-sources branch in Redux
 */
export const reducer = (
  state: PreconfiguredSourcesState = initialState,
  action: AppActions,
): PreconfiguredSourcesState => {
  switch (action.type) {
    case ActionTypes.SHOW_PRECONFIGURED_SOURCES_DIALOG:
      return {
        ...state,
        shouldShowPreconfiguredSourcesDialog: action.payload as boolean,
      };

    default:
      return state;
  }
};
