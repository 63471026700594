import { makeStyles } from '../../material';

const useStyles = makeStyles(
  {
    userFirstName: {
      margin: '0 10px',
    },
    userProfileAvatarContainer: {
      marginLeft: '8px',
    },
    userProfileContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '40px',
    },
  },
  { index: 1 },
);

export { useStyles };
