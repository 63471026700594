// External modules
import { makeStyles } from '../../material';

export const useStyles = ({ variant }: { variant?: 'medium' | 'large' }) =>
  makeStyles(
    {
      buttonContent: {
        padding: '10px 10px 10px 0',
      },
      loginButton: {
        '&:hover': {
          cursor: 'pointer',
          opacity: 0.9,
        },
        alignItems: 'center',
        backgroundColor: '#fff',
        border: '1px solid transparent',
        borderRadius: '5px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 1px 0 rgba(0, 0, 0, .24)',
        color: 'rgba(0, 0, 0, .54)',
        cursor: 'pointer',
        display: 'inline-flex',
        fontFamily: 'Roboto, sans-serif',
        fontSize: variant === 'large' ? '18px' : '16px',
        fontWeight: 500,
        height: variant === 'large' ? '60px' : '48px',
        justifyContent: 'start',
        paddingLeft: '15px',
        width: '100%',
      },
      loginButtonContainer: {
        margin: '12px',
        position: 'relative',
        width: variant === 'large' ? '400px' : '270px',
      },
      loginButtonDisabled: {
        opacity: 0.6,
      },
      logo: {
        background: 'white',
        marginRight: '10px',
        padding: '10px',
      },
      progressContainer: {
        left: 'calc(50% - 12px)',
        position: 'absolute',
        top: variant === 'large' ? '18px' : '14px',
      },
    },
    { index: 1 },
  )();
