import { ActionTypes } from '../actions';
import { AppActions } from '../types';
import { SupportState } from './types';

/**
 * Reduce incoming action for state.support branch in Redux
 */
export const reducer = (state = initialState, action: AppActions): SupportState => {
  switch (action.type) {
    case ActionTypes.OPEN_SUPPORT:
      return {
        ...state,
        isOpened: true,
      };
    case ActionTypes.CLOSE_SUPPORT:
      return {
        ...state,
        isOpened: false,
      };
    default:
      return state;
  }
};

export const initialState: SupportState = {
  isOpened: false,
};
