import React from 'react';

import { CheckBoxIcon } from '../../../assets/icons/check-box-icon';
import { CheckedBoxIcon } from '../../../assets/icons/checked-box-icon';
import { Checkbox, FormControlLabel } from '../../../material';
import { useStyles } from './styles';

export const CheckBox: React.FC<CheckBoxProps> = ({
  checked,
  disabled,
  label,
  value,
  color,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.formControlLabelContainer}>
      <FormControlLabel
        classes={{ label: classes.formControlLabel, root: classes.formControlLabelRoot }}
        control={
          <Checkbox
            checked={checked}
            checkedIcon={<CheckedBoxIcon classes={{ root: classes.checkBoxIcon }} />}
            classes={{ root: classes.checkbox }}
            color={color}
            disabled={disabled}
            disableRipple
            icon={<CheckBoxIcon classes={{ root: classes.checkBoxIcon }} disabled={disabled} />}
            onChange={onChange}
            value={value}
          />
        }
        label={label}
      />
    </div>
  );
};

interface CheckBoxProps {
  checked?: boolean;
  color?: 'default' | 'primary' | 'secondary';
  disabled?: boolean;
  label: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  value?: unknown;
}
