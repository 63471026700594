import { MimeType, SearchMessagesResult, StrictElasticMailWithHtml } from '@outmind/types';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { ApiQuery, useApiRouteMaker } from '../useApi';

/**
 * Retrieves the preview URL for a certain file
 */
export const useFilePreview = (documentId: string): UseQueryResult<FilePreview> => {
  const makeRoute = useApiRouteMaker(ApiQuery.GET_FILE_PREVIEW);

  const getFilePreviewQuery = useQuery<FilePreview>(
    [ApiQuery.GET_FILE_PREVIEW, documentId],
    async () => {
      const route = makeRoute({ documentId });

      const result = await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });

      return {
        mimeType: result.data.fileMimeType,
        url: result.data.previewUrl,
      };
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 0, // This data should always be refetched because it contains a token that expires
    },
  );

  return getFilePreviewQuery;
};

interface FilePreview {
  mimeType: MimeType;
  url: string;
}

/**
 * Retrieves the preview URL for a certain mail
 */
export const useMailPreview = (documentId: string): UseQueryResult<StrictElasticMailWithHtml> => {
  const makeRoute = useApiRouteMaker(ApiQuery.GET_MAIL_THREADS_PREVIEW);

  const getMailPreviewQuery = useQuery<StrictElasticMailWithHtml>(
    [ApiQuery.GET_MAIL_THREADS_PREVIEW, documentId],
    async () => {
      const route = makeRoute({ documentId });

      const result = await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });

      return result.data;
    },
    {
      staleTime: 20 * 60 * 1000, // 20 minutes
    },
  );

  return getMailPreviewQuery;
};

/**
 * Retrieves the preview URL for a certain message
 */
export const useMessagePreview = (documentId: string): UseQueryResult<SearchMessagesResult[]> => {
  const makeRoute = useApiRouteMaker(ApiQuery.GET_MESSAGE_PREVIEW);

  const getMessagePreviewQuery = useQuery<SearchMessagesResult[]>(
    [ApiQuery.GET_MESSAGE_PREVIEW, documentId],
    async () => {
      const route = makeRoute({ documentId });

      const result = await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });

      return result.data;
    },
    {
      staleTime: 20 * 60 * 1000, // 20 minutes
    },
  );

  return getMessagePreviewQuery;
};
