/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      checkBoxroot: {
        color: '#245EE5',
      },
      formControlLabel: {
        color: '#0000008a',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      shareConnectorContainer: {
        display: 'flex',
      },
      shareConnectorSubtitle: {
        flex: '0 1 auto',
        marginBottom: '10px',
        marginTop: '20px',
      },
      shareConnectorSwitch: {
        color: theme.palette.secondary.main,
        flex: 'none',
        marginBottom: 'auto',
        marginTop: '14px',
      },
      shareIcon: {
        color: '#245EE5',
        marginBottom: '-6px',
      },
      shareIconContainer: {
        marginLeft: '8px',
      },
    },
    { index: 1 },
  )();
};
