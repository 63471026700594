import { DocumentIndiceNames, ElasticFile } from '@outmind/types';
import React, { memo, useEffect, useState } from 'react';

import { useSearch, useTranslations } from '../../../hooks';
import { TranslationKey } from '../../../locales/types';
import { ChevronDown, ChevronUp, Grid, Typography } from '../../../material';
import { CompactSourcesIcons } from '../../CompactSourcesIcons';
import { useStyles } from '../styles';
import { DuplicateFileList } from './DuplicateFileList';

/**
 * Displays the list of duplicate files
 */
const DuplicateFilesNoMemo: React.FC<DuplicateFilesProps> = ({
  clusterId,
  documentId,
  documentName,
  similar,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const [duplicateFiles, setDuplicateFiles] = useState(similar.documents);
  const [maxDisplayedElements, setMaxDisplayedElements] = useState(0);

  const { results: files = [], fetchNextPage } = useSearch(DocumentIndiceNames.Files, {
    clusterId,
    enabled: false,
  });

  useEffect(() => {
    if (files?.length) {
      const newRetrievedFiles = [
        ...duplicateFiles,
        ...files
          .filter(
            (file) =>
              ![documentId, ...duplicateFiles.map((doc) => doc.id)].includes(file.document.id),
          )
          .map((_file) => _file.document),
      ];
      if (newRetrievedFiles.length !== duplicateFiles.length) {
        setDuplicateFiles(newRetrievedFiles as ElasticFile[]);
      }
    }
  }, [files]);

  const showMoreFiles = (): void => {
    fetchNextPage();
    setMaxDisplayedElements((_maxDisplayedElements) => _maxDisplayedElements + 10);
  };

  const seeMoreButton = (
    <Typography className={classes.seeMoreButton} onClick={showMoreFiles}>
      <span>{t('see_more', { documentName })}</span>
    </Typography>
  );

  return (
    <div className={classes.duplicatesGlobalContainer}>
      <div className={classes.duplicatesContainer}>
        <div
          aria-hidden="true"
          className={classes.duplicatesHelperContainer}
          onClick={() => setMaxDisplayedElements(!maxDisplayedElements ? duplicateFiles.length : 0)}
        >
          <Typography className={classes.similarResultsTitle}>
            {t('duplicates_results' as TranslationKey, {
              count: similar.total,
            })}
          </Typography>
          {!maxDisplayedElements ? (
            <ChevronDown className={classes.chevronIcon} />
          ) : (
            <ChevronUp className={classes.chevronIcon} />
          )}
        </div>
        {!maxDisplayedElements ? (
          <CompactSourcesIcons
            size={14}
            sources={Array.from(new Set(similar.documents.map((doc) => doc.source)))}
          />
        ) : null}
      </div>
      {maxDisplayedElements ? (
        <Grid className={classes.duplicateFilesContainer}>
          <DuplicateFileList
            duplicateFiles={duplicateFiles}
            maxDisplayedElements={maxDisplayedElements}
          />
          <div>
            {maxDisplayedElements && similar.total > duplicateFiles.length ? seeMoreButton : null}
          </div>
        </Grid>
      ) : null}
    </div>
  );
};

export const DuplicateFiles = memo(DuplicateFilesNoMemo);

interface DuplicateFilesProps {
  clusterId: string;
  documentId: string;
  documentName: string;
  similar: {
    documents: ElasticFile[];
    total: number;
  };
}
