import React, { memo } from 'react';

import { DocumentsList } from '../DocumentsList';
import { SignInClassicView } from '../SignInViews';
import { StartUpLoader } from '../StartUpLoader';

const SpotlightBodyNP: React.FC<SpotlightBodyProps> = ({ isLoggedIn, shouldShowStartUpLoader }) => {
  if (shouldShowStartUpLoader) return <StartUpLoader inSpotlight />;

  if (isLoggedIn) return <DocumentsList inSpotlight />;

  return <SignInClassicView inSpotlight />;
};

export const SpotlightBody = memo(SpotlightBodyNP);

interface SpotlightBodyProps {
  isLoggedIn: boolean;
  shouldShowStartUpLoader: boolean;
}
