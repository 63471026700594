import { useMutation, UseMutationResult } from 'react-query';

export const useStartLocalSync = (): UseMutationResult<
  unknown,
  unknown,
  Params$UseStartLocalSync,
  unknown
> =>
  useMutation<unknown, unknown, Params$UseStartLocalSync>(async ({ connectorId, roots }) => {
    window.electron?.startLocalDiskUpload({
      connectorId,
      roots,
    });
  });

interface Params$UseStartLocalSync {
  connectorId: string;
  roots: string[];
}
