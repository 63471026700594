import { SigninProvider } from '@outmind/types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { GoogleLoginResponse, useGoogleLogin } from 'react-google-login';
import useRouter from 'use-react-router';

import { useMicrosoftLogin, useTranslations } from '../../hooks';
import { FullscreenLoader } from '..';
// Environment variables
const { REACT_APP_MICROSOFT_CLIENT_ID: MSFT_CLIENT_ID, REACT_APP_GAPI_CLIENT_ID: GAPI_CLIENT_ID } =
  process.env;

/**
 * View that automatically redirects the user to Google or Microsoft OAuth flow for signing in.
 * When successful, the flow will try to finish by triggering the `@outmind/app-desktop`
 * with deep-linking.
 */
export const SignInStartView: React.FC = () => {
  const [flowType, setFlowType] = useState<string>();
  const [isSuccess, setIsSuccess] = useState(false);
  const [provider, setProvider] = useState<SigninProvider>();
  const [tokenId, setTokenId] = useState<string>();

  const { history, location } = useRouter();

  const { t } = useTranslations();

  const startLogin = (): void => {
    if (provider === 'microsoft') microsoftLogin();
    if (provider === 'google' && loaded) startGoogleLogin();
  };

  const loginCallback = (): void => {
    const nextUrl = `signin/callback?provider=${provider}&flow=${flowType}&tokenId=${tokenId}`;
    if (flowType === 'extension') history.push(`/${nextUrl}`);
    if (flowType === 'deep') window.open(`outmind://${nextUrl}`);
    else history.push(`/${nextUrl}`);
  };

  const { loaded, signIn: startGoogleLogin } = useGoogleLogin({
    clientId: GAPI_CLIENT_ID as string,
    cookiePolicy: 'single_host_origin',
    onSuccess: (res): void => {
      const { tokenId: _tokenId } = res as GoogleLoginResponse;
      setTokenId(_tokenId);
      setIsSuccess(true);
    },
  });

  const { login: microsoftLogin } = useMicrosoftLogin({
    authCallback: (_, _tokenId): void => {
      if (_tokenId) {
        setTokenId(_tokenId);
        setIsSuccess(true);
      }
    },
    clientId: MSFT_CLIENT_ID as string,
  });

  useEffect(() => {
    const { flow: _flowType, provider: _provider } = queryString.parse(location.search);
    if (_provider && typeof _provider === 'string') setProvider(_provider as SigninProvider);
    if (_flowType && typeof _flowType === 'string') setFlowType(_flowType);
  }, [location]);

  useEffect(startLogin, [provider, loaded]);

  useEffect(() => {
    if (isSuccess) loginCallback();
  }, [isSuccess]);

  return (
    <FullscreenLoader
      actionButtonHelperText={isSuccess ? t('click_here') : t('click_if_no_popup')}
      actionWhileLoading
      helperText={isSuccess ? `${t('if_no_redirect')}:` : t('connection_in_progress')}
      onButtonClick={isSuccess ? loginCallback : startLogin}
      status={isSuccess ? 'success' : 'loading'}
    />
  );
};
