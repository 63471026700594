import axios from 'axios';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useQuery } from 'react-query';
import remarkGfm from 'remark-gfm';

import { useTranslations } from '../../../hooks';
import { FileContentSkeleton } from '../../DocumentSkeleton/FileSkeleton';
import { NoPreviewFoundBackground } from '../../DocumentsListBackgrounds/NoPreviewFoundBackground';
import { useStyles } from '../styles';

export const MarkdownPreview: React.FC<MarkdownPreviewProps> = ({ contentUrl }) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const {
    data: markdownContent,
    isError: loadPreviewError,
    isLoading: previewIsLoading,
  } = useQuery<string>(
    ['LOAD_MARKDOWN_RESOURCE', contentUrl],
    async () => {
      const result = await axios({
        method: 'GET',
        url: contentUrl,
      });

      return result.data;
    },
    {
      staleTime: 20 * 60 * 1000, // 20 minutes
    },
  );

  if (previewIsLoading) return <FileContentSkeleton />;

  if (loadPreviewError) return <NoPreviewFoundBackground />;

  const noContent = `*${t('no_content')}*`;

  return (
    <div className={classes.previewInnerContainer}>
      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
        {markdownContent?.length ? markdownContent : noContent}
      </ReactMarkdown>
    </div>
  );
};

interface MarkdownPreviewProps {
  contentUrl: string;
}
