import { ElasticPrestation } from '@outmind/types';
import React from 'react';

import { useLinkHandler } from '../../hooks';
import { Paper, Typography } from '../../material';
import { DocumentBusiness } from './DocumentBusiness';
import { DocumentHeader } from './DocumentHeader';
import { DocumentParticipant } from './DocumentParticipant';
import { useStyles } from './styles';

/**
 * Displays an ElasticPrestation document
 */
export const MissionPrestation: React.FC<MissionPrestationProps> = ({
  document,
  fromSearchPerson,
}) => {
  const classes = useStyles();

  const { accessLink, consultants, content, customerName, source, title } = document;

  const joinedConsultantNames = consultants.map((con) => con.name).join(', ');

  const goToMission = useLinkHandler(accessLink);

  return (
    <Paper className={classes.documentContainer} elevation={0} onClick={goToMission}>
      <DocumentHeader
        documentId={document.id}
        labels={document.labels}
        shouldDisplayLabels={!fromSearchPerson}
        source={source}
        title={title}
      />
      <Typography className={classes.prestationContent}>{content}</Typography>
      <div className={classes.resultElementMetas}>
        <DocumentBusiness name={customerName} />
        <DocumentParticipant name={joinedConsultantNames} />
      </div>
    </Paper>
  );
};

interface MissionPrestationProps {
  document: ElasticPrestation;
  fromSearchPerson?: boolean;
}
