import classnames from 'classnames';
import React, { memo, useState } from 'react';

import { Label, useTranslations } from '../../../../hooks';
import { Checkbox, FormControlLabel } from '../../../../material';
import { LabelTree } from '../../LabelTree';
import { useStyles } from './styles';

const LabelTreeSelectorNP: React.FC<LabelTreeSelectorProps> = ({
  error,
  helperText,
  isNested,
  labels,
  setIsNested,
  parentId,
  setSelectedLabel,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();
  const [activeLabelId, setActiveLabelId] = useState(parentId ?? '');

  const labelTreeContainerClasses = classnames(classes.labelTreeContainer, {
    [classes.nestingDisabled]: !isNested,
  });

  const getLabelClicked = (label: Label): void => {
    setActiveLabelId(label.id);
    setSelectedLabel(label);
  };

  return (
    <div className={classes.labelTreeSelectorContainer}>
      <FormControlLabel
        classes={{ label: classes.formControlLabel }}
        control={
          <Checkbox checked={isNested} color="primary" name="checkedB" onChange={setIsNested} />
        }
        label={t('nest_tag')}
      />
      <div className={labelTreeContainerClasses}>
        {!isNested && <div className={classes.nestingDisabledOverlay} />}

        <LabelTree
          activeLabelId={activeLabelId}
          createMode
          labels={labels}
          onClickHandler={getLabelClicked}
        />
      </div>
      <div className={classes.errorContainer}>
        {error && <div className={classes.error}>{helperText}</div>}
      </div>
    </div>
  );
};

interface LabelTreeSelectorProps {
  error: boolean;
  helperText: string;
  isNested: boolean;
  labels: Label[];
  parentId?: string;
  setIsNested: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSelectedLabel: (label: Label) => void;
}

export const LabelTreeSelector = memo(LabelTreeSelectorNP);
