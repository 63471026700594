import { Folder as FolderFilled } from '@styled-icons/fluentui-system-filled';
import { Folder as FolderRegular } from '@styled-icons/fluentui-system-regular';
import React from 'react';

export const FolderIcon: React.FC<FolderIconProps> = ({ className, isFilled, size }) =>
  isFilled ? (
    <FolderFilled className={className} size={size} title="folder" />
  ) : (
    <FolderRegular className={className} size={size} title="folder" />
  );

interface FolderIconProps {
  className?: string;
  isFilled?: boolean;
  size?: number;
}
