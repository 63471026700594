import {
  DocCategoryMapping,
  DocumentIndicesExtended,
  FileCategory,
  Filters,
  IndexFilters,
  indicesToSearchByFieldOptions,
  SearchByFieldOptions,
} from '@outmind/types';

import { ActionTypes } from '../actions';
import { AppActions } from '../types';
import { CategoryFilter, FieldFilter, FiltersState } from './types';

/**
 * Reduces an incoming `action` with the current `FiltersState` to emit the new `FiltersState`
 * @param state `FiltersState`
 * @param action `AppActions`
 */
export const reducer = (state: FiltersState = initialState, action: AppActions): FiltersState => {
  switch (action.type) {
    case ActionTypes.ADD_DOCUMENT_RELATION_FILTER:
      return {
        ...state,
        relatedDocuments: [action.payload],
      };
    case ActionTypes.REMOVE_DOCUMENT_RELATION_FILTER:
      return {
        ...state,
        relatedDocuments: [],
      };
    case ActionTypes.ADD_FOLDER_FILTER:
      return {
        ...state,
        folder: action.payload,
      };

    case ActionTypes.REMOVE_FOLDER_FILTER:
      return {
        ...state,
        folder: undefined,
      };

    case ActionTypes.ADD_LABEL_FILTER:
      return {
        ...state,
        label: action.payload,
        tabFilters: {
          ...state.tabFilters,
          filters: [...state.tabFilters.filters, Filters.byLabels],
        },
      };

    case ActionTypes.REMOVE_LABEL_FILTER:
      return {
        ...state,
        label: undefined,
      };

    case ActionTypes.RESET_SEARCH:
      return {
        ...initialState,
        connectors: {},
      };

    case ActionTypes.ADD_PERSON_FILTER:
      return {
        ...state,
        person: action.payload,
      };

    case ActionTypes.REMOVE_PERSON_FILTER:
      return {
        ...state,
        person: undefined,
      };

    case ActionTypes.SET_ACCOUNT_FILTER: {
      const { connectorId, bool } = action.payload;

      return {
        ...state,
        connectors: {
          ...state.connectors,
          [connectorId]: bool,
        },
        tabFilters: {
          ...state.tabFilters,
          filters: [...state.tabFilters.filters, Filters.byConnectorIds],
        },
      };
    }

    case ActionTypes.SET_CATEGORY_FILTER: {
      const { bool, category } = action.payload;

      const categoryFilters = state.categories.map((categoryFilter) => {
        if (categoryFilter.category === category) {
          return {
            category,
            isActive: bool,
          };
        }
        return categoryFilter;
      });

      return {
        ...state,
        categories: categoryFilters,
        tabFilters: {
          ...state.tabFilters,
          categories: categoryFilters,
          filters: [...state.tabFilters.filters, Filters.byCategories],
        },
      };
    }

    case ActionTypes.SET_DATE_FILTER_FROM: {
      const fromDate = action.payload;
      return {
        ...state,
        date: {
          ...state.date,
          from: fromDate,
        },
        tabFilters: {
          ...state.tabFilters,
          filters: [...state.tabFilters.filters, Filters.byDateFrom, Filters.byDateTo],
        },
      };
    }

    case ActionTypes.SET_DATE_FILTER_TO: {
      const toDate = action.payload;
      return {
        ...state,
        date: {
          ...state.date,
          to: toDate,
        },
        tabFilters: {
          ...state.tabFilters,
          filters: [...state.tabFilters.filters, Filters.byDateFrom, Filters.byDateTo],
        },
      };
    }

    case ActionTypes.SET_FIELD_FILTER: {
      const { bool, field } = action.payload;
      const fieldFilters = state.fields.map((fieldFilter) => {
        if (fieldFilter.field === field) {
          return {
            field,
            isActive: bool,
          };
        }
        return fieldFilter;
      });
      return {
        ...state,
        fields: fieldFilters,
        tabFilters: {
          ...state.tabFilters,
          fields: fieldFilters,
          filters: [...state.tabFilters.filters, Filters.byFields],
        },
      };
    }

    case ActionTypes.SET_CHOSEN_INDEX_TAB: {
      const chosenIndexTab = action.payload;
      return {
        ...state,
        categories: getDefaultCategoryFilters(chosenIndexTab, state.tabFilters.categories),
        fields: getDefaultFieldFilters(chosenIndexTab, state.tabFilters.fields),
        filters: getDefaultFilters(chosenIndexTab, state.tabFilters),
      };
    }

    default:
      return state;
  }
};

/**
 * Gets the list of `CategoryFilters` related to a certain `DocumentIndicesExtended`
 * @param index `DocumentIndicesExtended`
 */
export const getDefaultCategoryFilters = (
  index: DocumentIndicesExtended,
  selectedcategories?: CategoryFilter[],
): CategoryFilter[] => [
  ...(selectedcategories
    ? selectedcategories.filter(
        (category) => category.isActive && !DocCategoryMapping[index].includes(category.category),
      )
    : []),
  ...DocCategoryMapping[index].map((category: FileCategory) => ({
    category,
    isActive: !!selectedcategories?.find((fileCategory) => fileCategory.category === category)
      ?.isActive,
  })),
];

/**
 * Gets the list of `FieldFilters` related to a certain `DocumentIndicesExtended`
 * @param index `DocumentIndicesExtended`
 */
export const getDefaultFieldFilters = (
  index: DocumentIndicesExtended,
  selectedFields?: FieldFilter[],
): FieldFilter[] => [
  ...(selectedFields
    ? selectedFields.filter(
        (field) => field.isActive && !indicesToSearchByFieldOptions[index].includes(field.field),
      )
    : []),
  ...indicesToSearchByFieldOptions[index].map((field: SearchByFieldOptions) => ({
    field,
    isActive: !!selectedFields?.find((fieldFilter) => fieldFilter.field === field)?.isActive,
  })),
];

/**
 * Gets the list of available filters related to a certain `DocumentIndicesExtended`
 * @param index `DocumentIndicesExtended`
 */
export const getDefaultFilters = (
  index: DocumentIndicesExtended,
  activeFilters: { categories: CategoryFilter[]; fields: FieldFilter[]; filters: Filters[] },
): Filters[] => [...IndexFilters[index], ...activeFilters.filters];

/**
 * Initial value of the `FiltersState`
 */
export const initialState: FiltersState = {
  categories: getDefaultCategoryFilters('*'),
  connectors: {},
  date: { from: null, to: null },
  fields: getDefaultFieldFilters('*'),
  filters: getDefaultFilters('*', { categories: [], fields: [], filters: [] }),
  tabFilters: { categories: [], fields: [], filters: [] },
};
