import { NonRegisteredUserInterface } from '@outmind/types';
import React from 'react';

import { Avatar, PersonIcon } from '../../material';
import { useStyles } from './styles';

/**
 * UserProfileAvatar displays the avatar of the user in the UserProfile
 */
export const UserProfileAvatar: React.FC<UserProfileAvatarProps> = ({ big, user }) => {
  const classes = useStyles();

  if (user) {
    const { avatarUrl, familyName, givenName } = user;

    if (avatarUrl) {
      return (
        <Avatar className={big ? classes.userAvatarBig : classes.userAvatar} src={avatarUrl} />
      );
    }

    const ufLetterGivenName = givenName?.[0]?.toUpperCase() ?? '';
    const ufLetterFamilyName = familyName?.[0]?.toUpperCase() ?? '';
    const initials = ufLetterGivenName + ufLetterFamilyName;

    if (initials === '') {
      return (
        <Avatar className={big ? classes.userAvatarBig : classes.userAvatar}>
          <PersonIcon />
        </Avatar>
      );
    }

    return <Avatar className={big ? classes.userAvatarBig : classes.userAvatar}>{initials}</Avatar>;
  }

  return (
    <Avatar className={big ? classes.userAvatarBig : classes.userAvatar}>
      <PersonIcon />
    </Avatar>
  );
};

interface UserProfileAvatarProps {
  big?: boolean;
  user?: NonRegisteredUserInterface;
}
