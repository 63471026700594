import { LabelInterface, LabelTypes } from '@outmind/types';

/**
 * Label class implementing the LabelInterface
 */
export class Label implements LabelInterface {
  private fullLabelName?: string;

  public children: Label[];

  public color: string;

  public id: string;

  public name: string;

  public parentId?: string;

  public type?: LabelTypes;

  public constructor(label: LabelInterface, children?: LabelInterface[]) {
    this.children = [];
    this.color = label.color;
    this.id = label.id;
    this.name = label.name;
    this.parentId = label.parentId;
    this.type = label.type;
    if (children) this.fullLabelName = this.getFullName(label, children);
  }

  public addChild(label: Label): void {
    const labelDoesNotExist = !this.children.find((child) => child.id === label.id);
    if (labelDoesNotExist) this.children.push(label);
  }

  get fullName(): string {
    return this.fullLabelName || this.name;
  }

  private getParentName(
    label: LabelInterface | undefined,
    labels: LabelInterface[],
    attempts = 0,
  ): string {
    if (attempts === 5 || !label) return '';
    const parentLabel = labels.find((elt) => elt.id === label.parentId);
    return (
      this.getParentName(parentLabel, labels, attempts + 1) +
      (parentLabel ? ' / ' : '') +
      label.name
    );
  }

  private getFullName(label: LabelInterface, labels: LabelInterface[]): string {
    if (!label.parentId) return label.name;
    return this.getParentName(label, labels);
  }

  public getApproximateWidth(): number {
    return getStringApproximateWidth(this.fullName);
  }

  public getLabelInterface(): LabelInterface {
    return { color: this.color, id: this.id, name: this.name, parentId: this.parentId };
  }
}

const CANVAS: HTMLCanvasElement = document.createElement('canvas');

export const getStringApproximateWidth = (str: string, font = 'bold 13pt arial'): number => {
  const context = CANVAS.getContext('2d');
  if (context) {
    context.font = font;
    return context.measureText(str).width + (str.length < 4 ? 32 : 16);
  }
  return str.length * 8 + 16;
};

/**
 * Describes all colors available for labels
 */
export const labelColors = [
  '#D32F2F',
  '#7B1FA2',
  '#512DA8',
  '#303F9F',
  '#0288D1',
  '#388E3C',
  '#9E9D24',
  '#FFA000',
  '#D1620C',
  '#616161',
];

/**
 * Describes all background color for labels
 */
export const backgroundLabelColors = {
  '#0288D1': '#D9EDF8',
  '#7B1FA2': '#EBDEF1',
  '#9E9D24': '#F1F1DE',
  '#303F9F': '#E7EDF6',
  '#388E3C': '#E1EEE2',
  '#512DA8': '#E5E0F2',
  '#616161': '#E8E8E8',
  '#D32F2F': '#F9E0E0',
  '#D1620C': '#F8E8DB',
  '#FFA000': '#FFF1D9',
};

/**
 * Retrieves one random color from all available label colors
 */
export const getRandomLabelColor = (): string =>
  labelColors[Math.floor(Math.random() * labelColors.length)];

export const getBookmarkLabel = (userId?: string): Label =>
  new Label({
    color: '#F9CB6A',
    id: `${userId}-bookmark`,
    name: 'my_favorite_files',
    type: LabelTypes.Bookmark,
  });
