import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { useTranslations } from '../../hooks';
import { ChevronDown, ChevronUp } from '../../material';
import { useStyles } from './styles';

export const ExpandContent: React.FC<ExpandContentProps> = ({ children, itemsToShowCount }) => {
  const classes = useStyles();
  const { t } = useTranslations();
  const [itemsToShow, setItemsToShow] = useState(
    React.Children.toArray(children).slice(0, itemsToShowCount),
  );
  const [isHovered, setIsHovered] = useState(false);
  const [seeMore, setSeeMore] = useState(false);

  const onClick = (): void => {
    setSeeMore(!seeMore);
  };

  useEffect(() => {
    const childrenArray = React.Children.toArray(children);
    setItemsToShow(seeMore ? childrenArray : childrenArray.slice(0, itemsToShowCount));
  }, [seeMore, children]);

  const hoverOverlayClasses = classnames(classes.hoverOverlay, {
    [classes.hovered]: isHovered,
  });

  const chevronDisplayed = seeMore ? (
    <>
      <ChevronUp className={isHovered ? classes.chevronIconHovered : classes.chevronIcon} />
      <span className={isHovered ? classes.chevronTextHovered : classes.chevronText}>
        {t('less')}
      </span>
    </>
  ) : (
    <>
      <ChevronDown className={isHovered ? classes.chevronIconHovered : classes.chevronIcon} />
      <span className={isHovered ? classes.chevronTextHovered : classes.chevronText}>
        {t('more')}
      </span>
    </>
  );

  return (
    <>
      <div>{itemsToShow}</div>
      {itemsToShowCount < React.Children.toArray(children).length ? (
        <div
          className={classes.container}
          onClick={onClick}
          onKeyPress={onClick}
          role="button"
          tabIndex={0}
        >
          <div
            className={classes.chevronContainer}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className={hoverOverlayClasses} />
            {chevronDisplayed}
          </div>
        </div>
      ) : null}
    </>
  );
};

interface ExpandContentProps {
  itemsToShowCount: number;
}
