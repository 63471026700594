import {
  Connector,
  ConnectorCredentialsStatus,
  ConnectorSyncStatus,
  Source,
  sources,
} from '@outmind/types';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import {
  ApiQuery,
  useConnectorsSyncStates,
  usePrevious,
  useTranslations,
  useUserProfile,
} from '../../../../hooks';
import { PeopleAltIcon, Tooltip, Typography } from '../../../../material';
import { Actions, useDispatch } from '../../../../store';
import { ConnectorStatusWarning } from '../../../ConnectorStatusWarning';
import { CheckBox } from '../../../MaterialComponents/CheckBox';
import { SourceLogo } from '../../../SourceLogo';
import { SyncProgress } from '../../../SyncProgress';
import { useStyles } from './styles';

/**
 * List of sources that we ignore their avatar because they are scoped for the entire organisation
 */
const IGNORED_SOURCES_AVATAR = [Source.Salesforce];

export const ConnectorFilter: React.FC<ConnectorFilterProps> = ({
  connector: {
    avatarUrl,
    id: connectorId,
    email,
    name,
    source,
    ownerId: connectorOwnerId,
    isActive,
    status,
  },
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [isHovered, setIsHovered] = useState(false);

  const hoverOverlayClasses = classnames(classes.hoverOverlay, {
    [classes.hovered]: isHovered,
  });

  const queryClient = useQueryClient();

  const { data: user } = useUserProfile();

  const { t } = useTranslations();

  const syncState = useConnectorsSyncStates().data?.[connectorId];

  const prevSyncState = usePrevious(syncState);

  const setAccountFilter = (bool: boolean): void => {
    dispatch(Actions.setAccountFilter(connectorId, bool));
  };

  const accountSourceLogo = (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <SourceLogo className={classes.logo} source={source} />
        {avatarUrl && !IGNORED_SOURCES_AVATAR.includes(source) ? (
          <img
            alt="person-avatar"
            className={classes.personAvatar}
            onError={({ currentTarget }) => {
              const target = currentTarget;
              target.onerror = null;
              target.style.display = 'none';
            }}
            src={avatarUrl}
          />
        ) : null}
      </div>
      {connectorOwnerId !== user?.id ? (
        <div className={classes.sharedIconContainer}>
          <PeopleAltIcon className={classes.sharedIcon} />
        </div>
      ) : null}
      <Typography className={classes.filterLabel}>{name}</Typography>
    </div>
  );

  useEffect(() => {
    const prevProgression = prevSyncState?.progression ?? 0;
    const prevStatus = prevSyncState?.status ?? ConnectorSyncStatus.Unknown;
    const previouslyNoData =
      prevStatus === ConnectorSyncStatus.NotStarted ||
      (prevProgression === 0 && prevStatus === ConnectorSyncStatus.InProgress);

    const nowProgression = syncState?.progression ?? 0;
    const nowStatus = syncState?.status;
    const nowHasData =
      (nowProgression > 0 && nowStatus === ConnectorSyncStatus.InProgress) ||
      nowStatus === ConnectorSyncStatus.Done;

    if (previouslyNoData && nowHasData) {
      queryClient.invalidateQueries(ApiQuery.SEARCH);
      dispatch(Actions.notifySearchRefreshed());
    }
  }, [dispatch, prevSyncState, queryClient, syncState]);

  return (
    <div
      className={classnames(classes.filterContainer, classes.pointerNone)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={hoverOverlayClasses} />
      <div
        style={{
          width: `${
            status === ConnectorCredentialsStatus.Down ||
            syncState?.status === ConnectorSyncStatus.InProgress ||
            syncState?.status === ConnectorSyncStatus.Starting
              ? '80%'
              : '100%'
          }`,
        }}
      >
        <Tooltip
          arrow
          classes={{ tooltipPlacementRight: classes.tooltipPopper }}
          placement="right"
          title={`${name} · ${email}${source in sources ? '' : ` · ${t('unknown_source')}`}`}
        >
          <div style={{ width: '100%' }}>
            <CheckBox
              checked={isActive}
              color="primary"
              label={accountSourceLogo}
              onChange={(_, bool) => setAccountFilter(bool)}
              value={connectorId}
            />
          </div>
        </Tooltip>
      </div>
      <div style={{ display: 'flex' }}>
        {status === ConnectorCredentialsStatus.Down ? (
          <div className={classes.connectorStatusContainer}>
            <ConnectorStatusWarning connectorId={connectorId} email={email} source={source} />
          </div>
        ) : (
          <div className={classes.syncProgressContainer}>
            {syncState && (syncState.progression ?? 0) < 1 ? (
              <SyncProgress
                duration={syncState.duration}
                progression={syncState.progression}
                size={17}
                status={syncState.status}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

interface ConnectorFilterProps {
  connector: ConnectorFilterInterface;
}

interface ConnectorFilterInterface extends Connector {
  isActive: boolean;
  status?: ConnectorCredentialsStatus;
}
