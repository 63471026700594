import {
  SearchFilesResult,
  SearchMailsResult,
  SearchMessagesResult,
  SearchPagesResult,
} from '@outmind/types';
import { action } from 'typesafe-actions';

export enum ActionTypes {
  CLOSE_PREVIEW = 'CLOSE_PREVIEW ',
  OPEN_PREVIEW = 'OPEN_PREVIEW ',
  SELECT_FILE_PREVIEW = 'SELECT_FILE_PREVIEW',
  SELECT_MAIL_PREVIEW = 'SELECT_MAIL_PREVIEW',
  SELECT_MESSAGE_PREVIEW = 'SELECT_MESSAGE_PREVIEW',
  SELECT_SLIDE_PREVIEW = 'SELECT_SLIDE_PREVIEW',
}

export const Actions = {
  closePreview: (): { type: ActionTypes.CLOSE_PREVIEW } => action(ActionTypes.CLOSE_PREVIEW),
  openPreview: (): { type: ActionTypes.OPEN_PREVIEW } => action(ActionTypes.OPEN_PREVIEW),
  selectFilePreview: (
    res: SearchFilesResult,
  ): { payload: SearchFilesResult; type: ActionTypes.SELECT_FILE_PREVIEW } =>
    action(ActionTypes.SELECT_FILE_PREVIEW, res),
  selectMailPreview: (
    res: SearchMailsResult,
  ): { payload: SearchMailsResult; type: ActionTypes.SELECT_MAIL_PREVIEW } =>
    action(ActionTypes.SELECT_MAIL_PREVIEW, res),
  selectMessagePreview: (
    res: SearchMessagesResult,
  ): { payload: SearchMessagesResult; type: ActionTypes.SELECT_MESSAGE_PREVIEW } =>
    action(ActionTypes.SELECT_MESSAGE_PREVIEW, res),
  selectPagePreview: (
    res: SearchPagesResult,
  ): { payload: SearchPagesResult; type: ActionTypes.SELECT_SLIDE_PREVIEW } =>
    action(ActionTypes.SELECT_SLIDE_PREVIEW, res),
};
