import { makeStyles } from '../../material';

const useStyles = makeStyles(
  {
    footer: {
      display: 'flex',
    },
    footerLink: {
      '&:hover': {
        color: '#666666',
      },
      color: '#888888',
      margin: 10,
      textDecoration: 'none',
    },
    footerLinkText: {
      fontSize: '0.875rem',
      margin: 0,
      padding: 0,
    },
    footerLinkTextMobile: {
      fontSize: '0.505rem',
      margin: 0,
      padding: 0,
    },
  },
  { index: 1 },
);

export { useStyles };
