import { makeStyles } from '../../material';

const useStyles = makeStyles(
  {
    appBanner: {
      display: 'flex',
      height: '35px',
      justifyContent: 'center',
      outline: 'none',
    },
    appBannerLogo: {
      height: '100%',
      margin: 'auto',
    },
    appBannerLogoButton: {
      cursor: 'pointer',
      height: '100%',
    },
    bigAppBanner: {
      alignItems: 'center',
      display: 'flex',
      height: 70,
      outline: 'none',
    },
  },
  { index: 1 },
);

export { useStyles };
