import { ExtendedSources, GSuiteSources, O365Sources, Source } from '@outmind/types';
import React from 'react';

import { useTranslations } from '../../../../hooks';
import { Button } from '../../../../material';
import { GoogleButton } from '../../../GoogleButton';
import { MicrosoftButton } from '../../../MicrosoftButton';

/**
 * Renders the button that redirect to the source to be authenticated
 */
export const AuthButton: React.FC<GoogleButtonProps> = ({ source, onClick }) => {
  const { t } = useTranslations();

  if (source === 'o365' || O365Sources.includes(source as Source)) {
    return (
      <MicrosoftButton
        buttonText={`${t('login_with')} Microsoft`}
        onClick={onClick}
        variant="medium"
      />
    );
  }
  if (source === 'gsuite' || GSuiteSources.includes(source as Source)) {
    return <GoogleButton buttonText={`${t('login_with')} Google`} onClick={onClick} />;
  }
  return (
    <Button color="primary" onClick={onClick} variant="outlined">
      {t('login_verb')}
    </Button>
  );
};

interface GoogleButtonProps {
  onClick: () => void;
  source: ExtendedSources;
}
