import { shouldRetry } from '@outmind/ebo';
import { captureException } from '@sentry/browser';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import WebFont from 'webfontloader';

import { useTranslations } from '../../hooks';
import { loadI18n } from '../../locales';
import { MuiThemeProvider } from '../../material';
import { store } from '../../store';
import { ErrorBoundaryFallback } from '../ErrorBoundaryFallback';
import { NotificationSnackbars } from '../NotificationSnackbars';
import { useStyles } from './styles';
import { theme } from './theme';

WebFont.load({
  google: {
    families: ['Open Sans:300,400,600', 'sans-serif'],
  },
});

loadI18n();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: 'tracked',
      retry: (count, error) => (count < 3 ? shouldRetry(error) : false),
    },
  },
});

/**
 * Renders the app Providers (React Context, MaterialUI Theme, Snackbar ...)
 */
export const AppProvider: React.FC = ({ children }) => {
  useStyles();

  const { ready } = useTranslations();
  // Until the translations are correctly loaded we block the app rendering
  if (!ready) return null;

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onError={(err) => captureException(err)}
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <MuiThemeProvider theme={theme}>
            <SnackbarProvider
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
              maxSnack={3}
            >
              {children}
              <NotificationSnackbars />
            </SnackbarProvider>
          </MuiThemeProvider>
        </QueryClientProvider>
      </Provider>
    </ErrorBoundary>
  );
};
