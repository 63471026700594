import { makeStyles } from '../../../../material';

const logoContainer = {
  alignItems: 'center',
  animation: '$inverse-rotate 5s linear infinite',
  backgroundColor: '#fff',
  border: '1px solid #aad4f0',
  borderRadius: '50%',
  display: 'flex',
  height: '50px',
  justifyContent: 'center',
  padding: '8px',
  position: 'absolute',
  right: '-30px',
  top: '50%',
  width: '50px',
};

const circleContainer = {
  animation: '$rotate 20s linear infinite',
  borderRadius: '100%',
  height: '600px',
  position: 'relative',
  width: '600px',
};

export const useStyles = (props: SpinningSourceStylesProps) =>
  makeStyles(
    {
      '@keyframes inverse-rotate': {
        '0% ': {
          transform: `rotate(${
            (props.startRotation ? props.startRotation * -1 : 0) * (props.inverted ? -1 : 1)
          }deg)`,
        },
        '100%': {
          transform: `rotate(${
            (-360 - (props.startRotation ?? 0)) * (props.inverted ? -1 : 1)
          }deg)`,
        },
      },
      '@keyframes rotate': {
        '0% ': {
          transform: `rotate(${(props.startRotation ?? 0) * (props.inverted ? -1 : 1)}deg)`,
        },
        '100%': {
          transform: `rotate(${(360 + (props.startRotation ?? 0)) * (props.inverted ? -1 : 1)}deg)`,
        },
      },

      circleContainer: {
        position: 'absolute',
      },
      logoContainer: {
        ...logoContainer,
        animationDelay: `${props.animationDelay ?? 0}s`,
        animationDuration: `${props.animationDuration ?? 10}s`,
        position: 'absolute',
        transform: props.startRotation ? `rotate(-${props.startRotation}deg)` : undefined,
        ...props.logoPosition,
      },
      logoImage: {
        width: '45px',
      },
      rotatingCircle: {
        ...circleContainer,
        animationDelay: `${props.animationDelay ?? 0}s`,
        animationDuration: `${props.animationDuration ?? 10}s`,
        border: props.withBorder ? '1px solid #aad4f0' : undefined,
        height: `${props.height ?? 600}px`,
        position: 'relative',
        transform: props.startRotation ? `rotate(${props.startRotation}deg)` : undefined,
        width: `${props.width ?? 600}px`,
      },
    },
    { index: 1 },
  )(props);

export interface SpinningSourceStylesProps {
  animationDelay?: number;
  animationDuration?: number;
  height?: number;
  inverted?: boolean;
  logoPosition?: {
    bottom?: string;
    left?: string;
    right?: string;
    top?: string;
  };
  startRotation?: number;
  width?: number;
  withBorder?: boolean;
}
