import { ElasticFile } from '@outmind/types';
import { Folder } from '@styled-icons/fluentui-system-filled';
import React from 'react';

import { FilterChip, FilterChipProps } from './FilterChip';
import { useStyles } from './styles';

/**
 * Displays a `FilterChip` for filtering by parent folder through the provided `ElasticFolder`
 */
export const FolderFilterChip: React.FC<FolderFilterChipProps> = ({
  isFocused,
  isHovered,
  folder,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FilterChip
      {...props}
      className={isFocused || isHovered ? classes.filterChipFocused : classes.filterChip}
      icon={<Folder size="20" title="folder" />}
      label={folder.title}
      type="filter:inFolder"
    />
  );
};

interface FolderFilterChipProps extends Omit<FilterChipProps, 'type'> {
  folder: ElasticFile;
  isFocused: boolean;
  isHovered: boolean;
}
