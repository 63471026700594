import { Source, sources } from '@outmind/types';
import React from 'react';

import { useGetUpdateAuthUrl, useLinkHandler, useRuntimeEnv, useTranslations } from '../../hooks';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '../../material';
import { useStyles } from './styles';

export const ConnectorReconnectDialog: React.FC<ConnectorReconnectDialogProps> = ({
  connectorId,
  email,
  onClose,
  open,
  source,
}) => {
  const { t } = useTranslations();
  const classes = useStyles();

  const { data: authUrl } = useGetUpdateAuthUrl(connectorId, source);
  const { inElectron } = useRuntimeEnv();
  const onClick = useLinkHandler(authUrl, { targetBlank: inElectron });

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={() => onClose()}
      open={open}
    >
      <DialogTitle id="alert-dialog-title">
        {t('lost_auth', { source: sources[source].name })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('lost_auth_helper_text', { email, source: sources[source].name })}
        </DialogContentText>
        <div className={classes.reconnectButtonContainer}>
          <Button color="primary" onClick={onClick} variant="outlined">
            {t('reconnect')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface ConnectorReconnectDialogProps {
  connectorId: string;
  email: string;
  onClose: () => void;
  open: boolean;
  source: Source;
}
