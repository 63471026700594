import React, { useEffect } from 'react';
import useRouter from 'use-react-router';

import desktopAppImgSrc from '../../assets/desktop-app.svg';
import { useDownloadDesktopLink, useRuntimeEnv, useTranslations } from '../../hooks';
import { Button, CloudDownloadIcon, Typography } from '../../material';
import { Background } from '..';
import { useStyles } from './styles';

export const DownloadDesktopAppView: React.FC = () => {
  const classes = useStyles();

  const { history } = useRouter();

  const { t } = useTranslations();

  const { inMacOS, inWindows } = useRuntimeEnv();

  const { data: downloadUrl } = useDownloadDesktopLink();

  useEffect(() => {
    // We set a var in local storage to not show this view one more time
    localStorage.setItem(localStorageSkipViewKey, '1');
  }, []);

  return (
    <Background>
      <div className={classes.mainContainer}>
        {inMacOS || inWindows ? (
          <>
            <Typography className={classes.downloadText}>{t('download_desktop_helper')}</Typography>
            <Button
              className={classes.downloadButton}
              disableElevation
              href={downloadUrl}
              variant="contained"
            >
              <CloudDownloadIcon />
              <Typography className={classes.downloadButtonText}>
                {`${t('download_desktop')} ${inMacOS ? 'MacOS' : 'Windows'}`}
              </Typography>
            </Button>
          </>
        ) : null}
        <Typography className={classes.continueOnWebText} onClick={() => history.push('/')}>
          {t('prefer_web_app')}
        </Typography>
      </div>
      <img alt="" className={classes.desktopAppImg} src={desktopAppImgSrc} />
    </Background>
  );
};

/**
 * Returns a `boolean` describing whether this view should be skipped
 */
export const getSkipDownloadDesktopView = (): //   {
//   inMacOS,
//   inWindows,
//   inElectron,
// }: ReturnType<typeof useRuntimeEnv>
boolean => true;

const localStorageSkipViewKey = 'OUTMIND_SKIP_DOWNLOAD_DESKTOP_VIEW';
