import { findBest } from '@outmind/helpers';
import { SearchMailsResult } from '@outmind/types';
import React, { useEffect, useState } from 'react';

import { useLinkHandler, useTranslations } from '../../hooks';
import { Paper } from '../../material';
import { Actions, useDispatch, useSelector } from '../../store';
import { ContextMenu } from '../ContextMenu';
import { ArchiveName } from './ArchiveName';
import { DocumentContent } from './DocumentContent';
import { DocumentDate } from './DocumentDate';
import { DocumentHeader } from './DocumentHeader';
import { DocumentParticipant } from './DocumentParticipant';
import { File } from './File';
import { useStyles } from './styles';
/**
 * Displays an Elastic document when it is from a mail document
 */
export const Mail: React.FC<MailProps> = ({ result, index }) => {
  const { document, highlights } = result;
  const { attachments, labels, metas } = document;

  const { t } = useTranslations();
  const dispatch = useDispatch();
  const previewInfos = useSelector((state) => state.previews.infos);
  const isPreviewOpened = useSelector((s) => s.previews.isOpened);
  const classes = useStyles();

  const [isSelected, setIsSelected] = useState(false);

  const [mouseCoordinates, setMouseCoordinates] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({
    mouseX: null,
    mouseY: null,
  });

  const reduceSummary = (summary?: string, maxCharacters = 150): string | undefined => {
    if (!summary || summary.length <= maxCharacters) return summary;
    return `${summary.substr(0, maxCharacters - 1)}...`;
  };

  const openPreview = (): void => {
    dispatch(Actions.openPreview());
    dispatch(Actions.selectMailPreview(result));
  };

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (!mouseCoordinates.mouseX && !mouseCoordinates.mouseY) {
      setMouseCoordinates({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setIsSelected(true);
    }
  };

  const handleMenuClose = (): void => {
    setIsSelected(false);
    setMouseCoordinates({
      mouseX: null,
      mouseY: null,
    });
  };

  const onclickHandler = (): (() => void) | undefined => {
    if (mouseCoordinates.mouseX && mouseCoordinates.mouseY) return;
    return openPreview;
  };

  const goToMail = useLinkHandler(document.accessLink);

  useEffect(() => {
    setIsSelected(previewInfos?.document.id === document.id);
  }, [previewInfos?.document.id, document.id]);

  const { element: bestAttachmentResult, score: bestAttachmentScore } = findBest(
    attachments,
    (att) => att.score,
  );

  const noObjectTitle = `<i style='color: #222222; padding-right: 4px'>(${t('no_object')})</i>`;

  const displayedMailTitle = highlights?.title || document.title || noObjectTitle;
  const displayedMailContent = highlights?.content || reduceSummary(document.summary);

  const mailSender = document.from_new?.name || document.from;
  const mailReceiver = document.to_new
    ? document.to_new.map((p) => p.name).join(', ')
    : document.to;

  return (
    <Paper
      className={!isSelected ? classes.documentContainer : classes.documentContainerClicked}
      elevation={0}
      id={`mail-result-container-${index}`}
      onClick={onclickHandler()}
      onContextMenu={handleRightClick}
    >
      <div className={classes.documentHeaderContainer} style={{ position: 'relative' }}>
        <div style={{ width: '100%' }}>
          <DocumentHeader
            accessLink={document.accessLink}
            documentId={document.id}
            index={index}
            labels={labels}
            source={document.source}
            title={displayedMailTitle}
          />
        </div>
      </div>
      <div className={classes.resultElementMetas}>
        <div className={classes.mailMetasContainer}>
          {mailSender ? <DocumentParticipant name={mailSender} /> : null}
          {mailReceiver ? <DocumentParticipant isReceiver name={mailReceiver} /> : null}
          {metas?.archive && !isPreviewOpened ? (
            <span style={{ marginLeft: '8px' }}>
              <ArchiveName name={metas.archive.name} type={metas.archive.type} />
            </span>
          ) : null}
          <span className={classes.medianContainer}>·</span>
          <DocumentDate date={document.date} />
        </div>
      </div>
      <DocumentContent content={displayedMailContent} />
      {bestAttachmentScore > 0 ? <File displayAsAttachment result={bestAttachmentResult} /> : null}
      {mouseCoordinates.mouseX && mouseCoordinates.mouseY ? (
        <ContextMenu
          documentId={document.id}
          mouseX={mouseCoordinates.mouseX}
          mouseY={mouseCoordinates.mouseY}
          onClose={handleMenuClose}
          onOpen={goToMail}
          onPreview={openPreview}
        />
      ) : null}
    </Paper>
  );
};

interface MailProps {
  index?: string;
  result: SearchMailsResult;
}
