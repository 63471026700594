import { PublicClientApplication } from '@azure/msal-browser';
import { useCallback, useEffect, useState } from 'react';

const { REACT_APP_WEBAPP_URL } = process.env;

const CLIENT_ID_REGEX = /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;

/**
 * Hook used for handling Microsoft OAuth flow
 */
export const useMicrosoftLogin = ({
  authCallback,
  clientId,
}: MicrosoftLoginOptions): { login: () => Promise<void> } => {
  const makeNewMsal = useCallback(() => {
    const msal =
      clientId &&
      CLIENT_ID_REGEX.test(clientId) &&
      new PublicClientApplication({
        auth: {
          clientId,
          redirectUri: `${REACT_APP_WEBAPP_URL}/signin/callback`,
        },
      });
    if (msal) return msal;
    throw new Error(`Could not create MicrosoftUserAgentApplication with clientId=${clientId}`);
  }, [clientId]);

  const [msalInstance, setMsalInstance] = useState(makeNewMsal);

  const login = useCallback(async (): Promise<void> => {
    console.log('Login starting ...');
    try {
      const { idToken } = await msalInstance.loginPopup({
        prompt: 'select_account',
        scopes: ['email', 'profile', 'user.read'],
      });
      console.log('Login happened');
      authCallback(undefined, idToken);
    } catch (err) {
      console.log(`Login popup error happened : ${err}`);
      authCallback(err);
    }
  }, [authCallback, msalInstance]);

  useEffect(() => {
    setMsalInstance(makeNewMsal);
  }, [makeNewMsal]);

  return { login };
};

interface MicrosoftLoginOptions {
  /**
   * Callback function which takes two arguments (error, authData)
   */
  authCallback: (error?: Error, idToken?: string) => void;
  /**
   * Id of the `MicrosoftGraph` client used for logging in the users
   */
  clientId: string;
}
