import { Source } from '@outmind/types';
import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { ApiQuery, useApiRouteMaker } from '../../useApi';

/**
 * Hook used for updating the connector of the user
 */
export const useUpdateConnector = (): UseMutationResult<
  unknown,
  unknown,
  Params$UpdateConnector,
  unknown
> => {
  const queryClient = useQueryClient();

  const makeRoute = useApiRouteMaker(ApiQuery.UPDATE_CONNECTOR);

  const updateConnectorMutation = useMutation<unknown, unknown, Params$UpdateConnector>(
    async ({ connectorId, isShared, name, rootsToSyncIds, source, syncDiscoveredRoots }) => {
      const route = makeRoute({
        connectorId,
        source,
      });

      await axios({
        data: { isShared, name, rootsToSyncIds, syncDiscoveredRoots },
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
    },
    {
      onSettled: () => {
        // Always refetch after error or success
        queryClient.invalidateQueries(ApiQuery.GET_MY_CONNECTORS);
      },
    },
  );

  return updateConnectorMutation;
};

interface Params$UpdateConnector {
  connectorId: string;
  isShared?: boolean;
  name?: string;
  rootsToSyncIds?: Record<string, boolean>;
  source: Source;
  syncDiscoveredRoots?: boolean;
}
