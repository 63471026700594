import { ElasticPerson } from '@outmind/types';
import React from 'react';

import { PersonAvatar } from '../PersonAvatar';
import { FilterChip, FilterChipProps } from './FilterChip';
import { useStyles } from './styles';

/**
 * Displays a `FilterChip` for filtering by author through the provided `ElasticPerson`
 * @param props `PersonFilterChipProps`
 */
export const PersonFilterChip: React.FC<PersonFilterChipProps> = ({
  isFocused,
  isHovered,
  person,
  ...props
}) => {
  const { email, familyName, givenName } = person;
  const classes = useStyles();

  let displayName = '';
  if (givenName) {
    displayName = familyName ? `${givenName} ${familyName}` : givenName;
  } else {
    displayName = email;
  }

  return (
    <FilterChip
      {...props}
      avatar={<PersonAvatar person={person} />}
      className={isFocused || isHovered ? classes.filterChipFocused : classes.filterChip}
      label={displayName}
      type="filter:byPerson"
    />
  );
};

interface PersonFilterChipProps extends Omit<FilterChipProps, 'type'> {
  isFocused: boolean;
  isHovered: boolean;
  person: ElasticPerson;
}
