import { Source } from '@outmind/types';
import clsn from 'classnames';
import React, { useEffect, useState } from 'react';
import useRouter from 'use-react-router';

import { useRuntimeEnv, useTranslations } from '../../../hooks';
import { TranslationKey } from '../../../locales/types';
import { ButtonBase, Card, CardContent, Grid, Tooltip, Typography } from '../../../material';
import { SourceLogo } from '../../SourceLogo';
import { useStyles } from './styles';

export const AddLocalConnectorButton: React.FC<AddLocalConnectorButtonProps> = ({
  disabled,
  source,
  beta,
}) => {
  const { history } = useRouter();
  const { t } = useTranslations();
  const classes = useStyles();
  const { inElectron } = useRuntimeEnv();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (openDialog) {
      history.push(`/?action=sync:${source}`);
      setOpenDialog(false);
    }
  }, [history, openDialog]);

  const buttonJsx = (
    <Grid item sm={3}>
      <Card className={classes.sourceCard}>
        <ButtonBase
          classes={{ disabled: classes.sourceButtonDisabled }}
          className={classes.sourceButton}
          disabled={disabled || !inElectron}
          onClick={() => setOpenDialog(true)}
        >
          <CardContent
            classes={{ root: classes.cardContentRoot }}
            className={clsn({
              [classes.sourceButtonBeta]: beta,
            })}
          >
            <div className={classes.sourceLogoContainer}>
              <SourceLogo className={classes.sourceLogo} source={source} />
            </div>
            <Typography className={classes.sourceName} variant="subtitle2">
              {t(source as TranslationKey)}
            </Typography>
          </CardContent>
        </ButtonBase>
      </Card>
    </Grid>
  );

  if (inElectron && !disabled) return buttonJsx;

  return (
    <Tooltip
      placement="top"
      title={!inElectron ? t('source_not_available_in_web') : t('source_disabled')}
    >
      {buttonJsx}
    </Tooltip>
  );
};

interface AddLocalConnectorButtonProps {
  beta?: boolean;
  disabled?: boolean;
  source: Source;
}
