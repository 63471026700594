import React from 'react';
import useReactRouter from 'use-react-router';

import pageNotFound from '../../assets/page-not-found.png';
import { useTranslations } from '../../hooks';
import { Button, Typography } from '../../material';
import { useStyles } from './styles';

export const PageNotFound: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  const { history } = useReactRouter();

  const goBack = (): void => {
    history.push('/');
  };

  return (
    <div className={classes.container}>
      <div className={classes.pageNotFoundContainer} role="alert">
        <img alt="404 Not found" className={classes.pageNotFoundImage} src={pageNotFound} />
        <div className={classes.messageContainer}>
          <Typography variant="h6">{t('page_not_found_helper_text')}</Typography>
        </div>
        <Button color="primary" onClick={goBack} variant="contained">
          {t('go_back_to_outmind')}
        </Button>
      </div>
    </div>
  );
};
