import { LabelTypes } from '@outmind/types';
import { Tag as FilledTag } from '@styled-icons/fluentui-system-filled/Tag';
import { Tag as RegularTag } from '@styled-icons/fluentui-system-regular/Tag';
import React, { memo } from 'react';

import { Label, useTranslations } from '../../../hooks';
import { TranslationKey } from '../../../locales/types';
import { Chip, ClearIcon, StarRoundedIcon } from '../../../material';
import { useStyles } from './styles';

const LabelChipNP: React.FC<LabelChipProps> = ({
  label,
  onClick,
  onDelete,
  withIcon,
  isFilled,
  isFilter,
}) => {
  const { color, fullName, type } = label;

  const { t } = useTranslations();

  const classes = useStyles({ color });

  const handleAction = (
    e: React.MouseEvent<HTMLDivElement>,
    action?: (label: Label, e?: React.MouseEvent<HTMLDivElement>) => void,
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    action?.(label, e);
  };

  const chipIcon = (): JSX.Element => {
    if (type === LabelTypes.Bookmark) return <StarRoundedIcon />;
    if (isFilled) return <FilledTag color={color} size={16} title="tag" />;
    return <RegularTag color={color} size={16} title="tag" />;
  };

  return (
    <Chip
      classes={{
        colorPrimary: classes.colorPrimary,
        deleteIcon: classes.deleteIcon,
        iconSmall: classes.iconSmall,
        root: isFilled ? classes.chipRootFilter : classes.chipRoot,
      }}
      color="primary"
      deleteIcon={<ClearIcon />}
      icon={withIcon ? chipIcon() : undefined}
      label={type === LabelTypes.Bookmark ? t(fullName as TranslationKey) : fullName}
      onClick={
        !isFilter
          ? (e: React.MouseEvent<HTMLDivElement>) => {
              handleAction(e, onClick);
            }
          : undefined
      }
      onDelete={
        onDelete
          ? (e: React.MouseEvent<HTMLDivElement>): void => {
              handleAction(e, onDelete);
            }
          : undefined
      }
      size="small"
      variant="outlined"
    />
  );
};

interface LabelChipProps {
  isFilled?: boolean;
  isFilter?: boolean;
  label: Label;
  onClick?: (label: Label, e?: React.MouseEvent<HTMLDivElement>) => void;
  onDelete?: (label: Label, e?: React.MouseEvent<HTMLDivElement>) => void;
  withIcon?: boolean;
}

export const LabelChip = memo(LabelChipNP);
