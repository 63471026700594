import * as microsoftTeams from '@microsoft/teams-js';
import { Source } from '@outmind/types';
import React, { memo, useCallback, useEffect } from 'react';
import useReactRouter from 'use-react-router';

import {
  useAuthCallbackState,
  useConnectors,
  useCreateConnector,
  useTranslations,
  useUpdateCredentials,
  useUserProfile,
} from '../../hooks';
import { FullscreenLoader } from '../FullscreenLoader';

const AuthCallbackViewNP: React.FC = () => {
  const { history } = useReactRouter();

  const { t } = useTranslations();

  const { code, flow, shouldUpdateCredentials, source, connectorId } = useAuthCallbackState();

  const { isSuccess: userProfileRetrieved } = useUserProfile();
  const { isSuccess: connectorsRetrieved } = useConnectors();

  const createConnector = useCreateConnector();
  const updateCredentials = useUpdateCredentials();

  const onSuccessRedirect = useCallback((): void => {
    const newConnectorId = createConnector.data?.connectorId;
    const nextPath =
      source === Source.LocalDisk || source === Source.NetworkDisk
        ? ''
        : `?action=sync:${source}&connectorId=${newConnectorId}`;

    if (flow === 'deep') window.open(`outmind://${nextPath}`);
    else if (flow === 'teams') microsoftTeams.authentication.notifySuccess(nextPath);
    else history.push(`/${nextPath}`);
  }, [createConnector.data, flow, history, source]);

  useEffect(() => {
    if (!code || updateCredentials.isError || createConnector.isError) {
      history.push('/');
    }
  }, [code, createConnector.isError, updateCredentials.isError]);

  useEffect(() => {
    if (code && flow && shouldUpdateCredentials && connectorId) {
      updateCredentials.mutate({ code, connectorId, flow, source: source as Source });
    }
  }, []);

  useEffect(() => {
    if (code && flow && !shouldUpdateCredentials) {
      createConnector.mutate({ code, flow, source });
    }
  }, []);

  useEffect(() => {
    if (createConnector.isSuccess && userProfileRetrieved && connectorsRetrieved) {
      onSuccessRedirect();
    }
  }, [connectorsRetrieved, onSuccessRedirect, userProfileRetrieved]);

  return (
    <FullscreenLoader
      actionButtonHelperText={t('go_back_to_outmind')}
      helperText={
        createConnector.isSuccess
          ? t('your_new_source_is_now_created')
          : t('creating_new_source_in_progress')
      }
      onButtonClick={() => onSuccessRedirect()}
      status={createConnector.isSuccess ? 'success' : 'loading'}
    />
  );
};

export const AuthCallbackView = memo(AuthCallbackViewNP);
