import 'moment/locale/fr';

import moment from 'moment';
import { useCallback } from 'react';

import { useTranslations } from '../useTranslations';

// Time constants
const MS_PER_SECOND = 1000;
const MS_PER_MINUTE = 60 * MS_PER_SECOND;
const MS_PER_HOUR = 60 * MS_PER_MINUTE;
const MS_PER_DAY = 24 * MS_PER_HOUR;
const MS_PER_WEEK = 7 * MS_PER_DAY;

/**
 * Returns a function used for computing friendly internationalized duration
 * @param duration `number` in milliseconds
 */
export const useDuration = (): ((duration: number) => string) => {
  const { t } = useTranslations();

  const makeDuration = useCallback(
    (duration: number): string => {
      if (duration < MS_PER_MINUTE) {
        const roundedElapsed = Math.round(duration / MS_PER_SECOND);
        return t('x_seconds', { count: roundedElapsed });
      }
      if (duration < MS_PER_HOUR) {
        const roundedElapsed = Math.round(duration / MS_PER_MINUTE);
        return t('x_minutes', { count: roundedElapsed });
      }
      if (duration < MS_PER_DAY) {
        const roundedElapsed = Math.round(duration / MS_PER_HOUR);
        return t('x_hours', { count: roundedElapsed });
      }
      const roundedElapsed = Math.round(duration / MS_PER_DAY);
      return t('x_days', { count: roundedElapsed });
    },
    [t],
  );

  return makeDuration;
};

/**
 * Returns a friendly internationalized date expressed as a time ago
 * @param timestamp `number` in epoch milliseconds
 */
export const useTimeAgo = (): ((timestamp: number) => string) => {
  const {
    i18n: { language },
    t,
  } = useTranslations();

  const makeTimeAgo = useCallback(
    (timestamp: number): string => {
      const elapsed = Date.now() - timestamp;

      // Sometimes sources return dates in the futur.
      // We switch to display the full date and not just the time elapsed
      if (elapsed < 0) {
        const date = moment(timestamp).locale(language).format('LL');
        return t('on_x_day', { date });
      }

      if (elapsed < MS_PER_MINUTE) {
        const roundedElapsed = Math.round(elapsed / MS_PER_SECOND);
        return t('x_seconds_ago', { count: roundedElapsed });
      }
      if (elapsed < MS_PER_HOUR) {
        const roundedElapsed = Math.round(elapsed / MS_PER_MINUTE);
        return t('x_minutes_ago', { count: roundedElapsed });
      }
      if (elapsed < MS_PER_DAY) {
        const roundedElapsed = Math.round(elapsed / MS_PER_HOUR);
        return t('x_hours_ago', { count: roundedElapsed });
      }
      if (elapsed < 4 * MS_PER_WEEK) {
        const roundedElapsed = Math.round(elapsed / MS_PER_DAY);
        return t('x_days_ago', { count: roundedElapsed });
      }
      // When the date is too far (more than 1 month ago)
      // We switch to displaying the full date and not just the time elapsed
      const date = moment(timestamp).locale(language).format('LL');
      return t('on_x_day', { date });
    },
    [language, t],
  );

  return makeTimeAgo;
};
