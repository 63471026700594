import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    contactButtonIcon: {
      fontSize: '1.25rem',
    },
    contactButtonText: {
      fontSize: '0.75rem',
      marginLeft: '8px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    contactPersonButton: {
      padding: '8px 16px',
      width: '100%',
    },
    contactPersonButtons: {
      width: '100%',
    },
    contactTeamsButtonIcon: {
      maxHeight: '100%',
      width: '1.25rem',
    },
  },
  { index: 1 },
);
