import React from 'react';

import { Typography } from '../../material';
import { ArchiveLogos } from '../ArchiveLogos';
import { useStyles } from './styles';

/**
 * Renders the archive name and its logo
 */
export const ArchiveName: React.FC<ArchiveNameProps> = ({ name, type }) => {
  const classes = useStyles();

  return (
    <div className={classes.resultElementMeta}>
      <div className={classes.displayFlex}>
        <img alt="" className={classes.connectorLogo} src={ArchiveLogos[type]} />
      </div>
      <Typography className={classes.resultElementMetaText}>{name}</Typography>
    </div>
  );
};

interface ArchiveNameProps {
  name: string;
  type: string;
}
