import React from 'react';

import { useTranslations } from '../../../hooks';
import { Typography } from '../../../material';
import { useSelector } from '../../../store';
import { ExpandContent } from '../../ExpandContent';
import { useStyles } from '../styles';
import { FieldFilter } from './FieldFilter';

export const FieldFilters: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  const fields = useSelector((s) => s.filters.fields);

  const fieldFilters = fields.map((filterFilter) => (
    <FieldFilter key={filterFilter.field} filter={filterFilter} />
  ));

  return fields.length === 0 ? null : (
    <div className={classes.sectionContainer}>
      <Typography className={classes.sectionTitle}>{t('search_in')}</Typography>
      <ExpandContent itemsToShowCount={2}>{fieldFilters}</ExpandContent>
    </div>
  );
};
