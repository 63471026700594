import { Tag as TagFilled } from '@styled-icons/fluentui-system-filled/Tag';
import { Tag as TagRegular } from '@styled-icons/fluentui-system-regular/Tag';
import React, { useState } from 'react';

import { Label } from '../../../../../hooks';
import { ListItem } from '../../../../../material';
import { SanitizedHtmlTypography } from '../../../..';
import { useStyles } from '../styles';

export const LabelSuggestion: React.FC<LabelSuggestionProps> = ({
  index,
  isSelected,
  label,
  onClick,
}) => {
  const { color } = label;
  const classes = useStyles({ color });

  const [isHovered, setIsHovered] = useState(false);

  return (
    <ListItem
      className={isSelected || isHovered ? classes.querySelected : classes.queryItem}
      id={`label-suggestion-${index}`}
      onClick={() => onClick(label)}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      {isSelected || isHovered ? (
        <TagFilled className={classes.labelIcon} color={color} size="20" title="tag" />
      ) : (
        <TagRegular className={classes.labelIcon} color={color} size="20" title="tag" />
      )}
      <div className={classes.suggestionTextContainer}>
        <SanitizedHtmlTypography className={classes.suggestionTextMain} html={label.name} />
      </div>
    </ListItem>
  );
};

interface LabelSuggestionProps {
  index: number;
  isSelected: boolean;
  label: Label;
  onClick: (label: Label) => void;
}
