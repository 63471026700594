/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../../hooks';
import { makeStyles } from '../../../material';
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      clickHolder: {
        display: 'flex',
        width: '100%',
        zIndex: 1,
      },
      content: { paddingLeft: '12px', width: 'unset' },
      hover: {
        background: theme.designSystem.colors.active.main,
      },
      hoverBackground: {
        height: '100%',
        marginLeft: '-128px',
        opacity: 0.05,
        overflow: 'hidden',
        position: 'absolute',
        width: 'calc(100% + 132px)',
      },
      iconContainer: {
        color: theme.designSystem.colors.disabled.main,
      },
      iconContainerHover: {
        color: theme.designSystem.colors.secondary.main,
      },
      ignoreSelected: {
        '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
          backgroundColor: 'unset !important',
        },
      },

      label: {
        '&:hover': {
          backgroundColor: 'unset',
        },
        paddingLeft: 0,
        width: '100%',
      },
      labelIcon: {
        marginRight: '2px',
      },
      labelIconContainer: { marginRight: '4px' },
      labelPresentationContainer: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
      },
      labelRoot: {
        alignItems: 'center',
        display: 'flex',
        padding: '2px 0',
        position: 'relative',
      },
      labelText: {
        alignItems: 'center',
        color: theme.designSystem.colors.secondary.main,
        display: 'block',
        fontSize: '14px',
        fontWeight: 400,
        overflow: 'hidden',
        paddingLeft: '4px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      labelTextContainer: { maxWidth: '120px' },
      moreOptionButtonContainer: {
        position: 'absolute',
        right: '31px',
        zIndex: 3,
      },
      root: {
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
          backgroundColor: 'transparent !important',
        },
        '&:hover > $content': {
          backgroundColor: 'transparent !important',
        },
        width: '100%',
      },
      rootWithSelected: {
        '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
          backgroundColor: '#00ff7d !important',
        },
      },
      selected: {
        background: '#00ff7d',
        opacity: '0.2 !important',
      },
    },
    { index: 1 },
  )();
};
