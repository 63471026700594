import { ConnectorSyncStatus } from '@outmind/types';
import React, { memo, useRef } from 'react';

import { useAllSearch, useConnectors, useConnectorsSyncStates } from '../../hooks';
import { useSelector } from '../../store';
import { DataLoadingBackground, NoDataBackground } from '..';
import { ClassicTab } from './ClassicTab';
import { DocumentsListWrapper } from './DocumentsListWrapper';
import { HomeTab } from './HomeTab';

const DocumentsListNP: React.FC<DocumentsListProps> = ({ inSpotlight }) => {
  const { data: connectorStates = {} } = useConnectorsSyncStates();
  const oneIsSyncing = Object.values(connectorStates).reduce(
    (bool, syncState) =>
      bool ||
      syncState.status === ConnectorSyncStatus.InProgress ||
      syncState.status === ConnectorSyncStatus.Starting,
    false,
  );

  const { chosenIndexTab, mode } = useSelector((s) => s.search);

  const results = useAllSearch();

  const infiniteScrollRef = useRef<HTMLDivElement>(null);

  const { data: connectors = [] } = useConnectors();

  if (connectors.length === 0) {
    return (
      <DocumentsListWrapper ref={infiniteScrollRef} inSpotlight={inSpotlight}>
        <NoDataBackground />
      </DocumentsListWrapper>
    );
  }

  const resultsFound = Object.values(results).reduce(
    (bool, result) => bool || result.total > 0,
    false,
  );

  const resultsLoading = Object.values(results).reduce(
    (bool, result) => bool || result.isLoading,
    false,
  );

  if (oneIsSyncing && !resultsFound && !resultsLoading && mode === 'GET_LAST_ELEMENTS') {
    return (
      <DocumentsListWrapper ref={infiniteScrollRef} inSpotlight={inSpotlight}>
        <DataLoadingBackground />
      </DocumentsListWrapper>
    );
  }

  if (chosenIndexTab === '*') {
    return (
      <DocumentsListWrapper ref={infiniteScrollRef} inSpotlight={inSpotlight}>
        <HomeTab />
      </DocumentsListWrapper>
    );
  }

  return (
    <DocumentsListWrapper ref={infiniteScrollRef} inSpotlight={inSpotlight}>
      <ClassicTab scrollableTarget={infiniteScrollRef.current} tab={chosenIndexTab} />
    </DocumentsListWrapper>
  );
};

interface DocumentsListProps {
  inSpotlight?: boolean;
}

export const DocumentsList = memo(DocumentsListNP);
