import React from 'react';

import { useMessagePreview } from '../../../hooks';
import { SearchResultMessage } from '../../Document/SearchResultMessage';
import { MessageSkeleton } from '../../DocumentSkeleton/MessageSkeleton/MessageSkeleton';
import { NoPreviewFoundBackground } from '../../DocumentsListBackgrounds/NoPreviewFoundBackground';
import { useStyles } from '../styles';

export const MessagePreview: React.FC<MessagePreviewProps> = ({ accessLink, documentId }) => {
  const classes = useStyles();

  const { data: messages, isLoading } = useMessagePreview(documentId);

  const messagePreview = messages?.length ? (
    messages.map((_message) => (
      <>
        <SearchResultMessage fullContent highlightedMessage={documentId} result={_message} />
        <div className={classes.messageRepliesContainer}>
          {_message.document.replies?.map((_reply) => (
            <SearchResultMessage
              fullContent
              highlightedMessage={documentId}
              result={{
                document: {
                  ..._reply,
                  attachments: _reply.attachments.map((attachment) => ({
                    document: { ...attachment },
                    score: 1,
                  })),
                },
                score: 1,
              }}
            />
          ))}
        </div>
      </>
    ))
  ) : (
    <NoPreviewFoundBackground accessLink={accessLink} />
  );

  const messageSkeletons = [...Array(10)].map((_, i) => <MessageSkeleton key={i} />);

  return (
    <div className={classes.previewInnerContainer}>
      {isLoading ? messageSkeletons : messagePreview}
    </div>
  );
};

interface MessagePreviewProps {
  accessLink?: string;
  documentId: string;
}
