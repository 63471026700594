import { SigninProvider } from '@outmind/types';
import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { Actions, useDispatch } from '../../../store';
import { ApiQuery, useApiRouteMaker } from '../../useApi';

/**
 * Hook for retrieving the "react-query" mutation used for signing in
 */
export const useSignIn = (): UseMutationResult<unknown, unknown, Params$UseSignIn, unknown> => {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const makeRoute = useApiRouteMaker(ApiQuery.SIGN_IN);

  const signInMutation = useMutation<unknown, unknown, Params$UseSignIn>(
    async ({ idToken, provider }) => {
      const route = makeRoute({ provider });

      await axios({
        data: { idToken },
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
    },
    {
      onError: (err: AxiosError) => {
        if (err.response?.status === 400) dispatch(Actions.notifyUserAccountNotFound());
        else dispatch(Actions.notifySomethingWentWrong());
      },
      onSuccess: (_, { flowType }) => {
        queryClient.invalidateQueries();

        if (flowType === 'deep') {
          window.electron?.reloadSpotlightView();
        }
        if (flowType === 'extension') {
          (window.opener as Window).postMessage('will-close', '*');
          return window.close();
        }
      },
    },
  );

  return signInMutation;
};

interface Params$UseSignIn {
  flowType?: string;
  idToken: string;
  provider: SigninProvider;
}
