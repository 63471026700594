import React from 'react';
import Media from 'react-media';

import { Grid, Paper } from '../../material';
import { NavBarActions } from '../NavBarActions';
import { OutmindLogo } from '../OutmindLogo';
import { SearchBar } from '../SearchBar';
import { useStyles } from './styles';

/**
 * AppBar displays the main application bar on top of the screen
 */
export const AppBar: React.FC<AppBarProps> = ({ withSearchBar }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.appBarContainer} elevation={0}>
      <Media query="(max-width: 959px)">
        {
          (matches) =>
            !matches ? (
              <Grid className={classes.logoContainer} item lg={2} md={3}>
                <OutmindLogo clickGoHome />
              </Grid>
            ) : null
          // eslint-disable-next-line react/jsx-curly-newline
        }
      </Media>
      <Grid className={classes.appBarInnerContainer} item lg={10} md={9}>
        <div className={classes.searchBarContainer}>{withSearchBar ? <SearchBar /> : null}</div>
        <div className={classes.userProfileContainer}>
          <Media query="(max-width: 959px)">
            {(matches) => (!matches ? <NavBarActions /> : null)}
          </Media>
        </div>
      </Grid>
    </Paper>
  );
};

interface AppBarProps {
  withSearchBar?: boolean;
}
