import { ConnectorSyncState } from '@outmind/types';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { ApiQuery } from '../../useApi';

/**
 * Retrieves the `ConnectorSyncState` of a certain localdisk connector
 */
export const useLocalDiskConnectorStates = (): void => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (onSetSyncStateIsBinded) return;

    window.electron?.onSetSyncState((syncState) => {
      const { connectorId } = syncState;
      const syncStates = queryClient.getQueryData<Record<string, ConnectorSyncState>>([
        ApiQuery.GET_CONNECTOR_SYNC_STATE,
      ]);
      const connectorSyncState = syncStates?.[connectorId];
      if (JSON.stringify(connectorSyncState) !== JSON.stringify(syncState)) {
        queryClient.setQueryData([ApiQuery.GET_CONNECTOR_SYNC_STATE], {
          ...syncStates,
          [connectorId]: syncState,
        });
      }
    });

    onSetSyncStateIsBinded = true;
  }, [queryClient]);
};

/**
 * Whether the `useLocalDiskConnectorStates` hook is already binded. Avoids having the same listener
 * multiple times on `window.electron?.onSetSyncState`
 */
let onSetSyncStateIsBinded = false;
