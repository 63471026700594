import { LabelInterface } from '@outmind/types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
  getRandomLabelColor as getRandomColor,
  Label,
  labelColors as colors,
  useCreateLabel,
  useLabelsTree,
  useTranslations,
  useUpdateLabel,
} from '../../../hooks';
import { Actions, useDispatch } from '../../../store';
import { CustomDialog } from '../../CustomDialog';
import { ConfirmButton } from './ConfirmButton';
import { LabelColorSelector } from './LabelColorSelector';
import { LabelNameInput } from './LabelNameInput';
import { LabelTreeSelector } from './LabelTreeSelector';
import { useStyles } from './styles';

const MAX_CHARACTERS = 40;

export const LabelDialogNP: React.FC<LabelDialogProps> = ({ open, labelToEdit, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslations();

  const [labelNameValue, setLabelNameValue] = useState<string>('');
  const [selectedColor, setSelectedColor] = useState(getRandomColor());
  const [isNested, setIsNested] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<Label>();
  const [parentLabelId, setParentLabelId] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState({
    name: {
      error: false,
      helperText: '',
    },
    nesting: {
      error: false,
      helperText: '',
    },
  });

  const { data: labels = [] } = useLabelsTree();

  const parentLabelIds = labels.map((node) => node.id);

  useEffect(() => {
    if (labelToEdit) {
      setLabelNameValue(labelToEdit.name);
      setSelectedColor(labelToEdit.color);
      setParentLabelId(labelToEdit.parentId);
      setIsNested(!!labelToEdit.parentId);
    } else {
      resetForm();
    }
  }, [labelToEdit]);

  const resetForm = (): void => {
    setLabelNameValue('');
    setSelectedColor(getRandomColor());
    setSelectedLabel(undefined);
    setIsNested(false);
  };

  const handleLabelInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLabelNameValue(event.target.value);
    },
    [setLabelNameValue],
  );

  const handleNestedCheckChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsNested(event.target.checked);
    },
    [setIsNested],
  );

  const handleSelectLabel = useCallback(
    (label: Label) => {
      setParentLabelId(label.id);
      setSelectedLabel(label);
    },
    [setSelectedLabel],
  );

  const handleSelectColor = useCallback(
    (color: string) => {
      setSelectedColor(color);
    },
    [setSelectedColor],
  );

  const handleError = (formElement: string, helper: string): boolean => {
    setFormError({ ...formError, [formElement]: { error: true, helperText: helper } });
    return false;
  };

  const resetFormError = (): void => {
    setFormError({
      name: {
        error: false,
        helperText: '',
      },
      nesting: {
        error: false,
        helperText: '',
      },
    });
  };

  useEffect(() => {
    if (labelNameValue.length > MAX_CHARACTERS) {
      handleError('name', t('characters_exceeded', { maxCharacters: MAX_CHARACTERS }));
    } else {
      resetFormError();
    }
  }, [labelNameValue, selectedLabel]);

  const validateForm = (): boolean => {
    let isValidated = true;
    if (labelNameValue === '') {
      isValidated = handleError('name', t('tag_name_validator'));
    } else if (labelNameValue.length > MAX_CHARACTERS) {
      isValidated = handleError(
        'name',
        t('characters_exceeded', { maxCharacters: MAX_CHARACTERS }),
      );
    }

    if (isNested) {
      const noParentSelectedError = !parentLabelId ? t('tag_parent_validator') : '';
      const parentSelectedIsAChildError =
        selectedLabel && !parentLabelIds.includes(selectedLabel.id)
          ? t('tag_first_parent_validator')
          : '';
      const cycleDetectedError =
        labelToEdit && parentLabelId === labelToEdit.id ? t('tag_cycle_validator') : '';
      const updateLabelWithChildrenError =
        labelToEdit && labelToEdit.children.length > 0 ? t('nest_tag_with_children') : '';
      const error =
        noParentSelectedError ||
        parentSelectedIsAChildError ||
        cycleDetectedError ||
        updateLabelWithChildrenError;
      if (error) {
        isValidated = handleError('nesting', error);
      }
    }

    return isValidated;
  };

  const getNewLabelObject = (): LabelInterface => ({
    color: isNested && selectedLabel ? selectedLabel.color : selectedColor,
    id: uuid(),
    name: labelNameValue,
    parentId: isNested && selectedLabel ? selectedLabel.id : undefined,
  });

  const getUpdatedLabelObject = (): Omit<LabelInterface, 'id'> => ({
    color: isNested && selectedLabel ? selectedLabel.color : selectedColor,
    name: labelNameValue,
    parentId: isNested && parentLabelId ? parentLabelId : undefined,
  });

  const submitForm = (): void => {
    const formIsValid = validateForm();
    if (formIsValid) {
      setIsLoading(true);
      if (labelToEdit) {
        updateLabel(
          {
            label: {
              id: labelToEdit?.id,
              ...getUpdatedLabelObject(),
            },
          },
          {
            onSettled: () => setIsLoading(false),
            onSuccess: () => {
              resetForm();
              onClose();
              dispatch(Actions.notifyLabelUpdated());
            },
          },
        );
      } else {
        createLabel(
          {
            label: getNewLabelObject(),
          },
          {
            onSettled: () => setIsLoading(false),
            onSuccess: () => {
              resetForm();
              onClose();
              dispatch(Actions.notifyLabelCreated());
            },
          },
        );
      }
    }
  };

  const { mutate: createLabel } = useCreateLabel();

  const { mutate: updateLabel } = useUpdateLabel();

  return (
    <CustomDialog
      onClose={onClose}
      open={open}
      title={t(labelToEdit ? 'edit_a_tag' : 'create_new_tag')}
    >
      <form
        className={classes.formContainer}
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          submitForm();
        }}
      >
        <LabelNameInput
          error={formError.name.error}
          handleLabelInputChange={handleLabelInputChange}
          helperText={formError.name.helperText}
          labelNameValue={labelNameValue}
        />
        <LabelColorSelector
          colors={colors}
          selectedColor={selectedColor}
          setSelectedColor={handleSelectColor}
        />
        <LabelTreeSelector
          error={formError.nesting.error}
          helperText={formError.nesting.helperText}
          isNested={isNested}
          labels={labels}
          parentId={parentLabelId}
          setIsNested={handleNestedCheckChange}
          setSelectedLabel={handleSelectLabel}
        />
        <ConfirmButton editMode={!!labelToEdit} isLoading={isLoading} submit={submitForm} />
      </form>
    </CustomDialog>
  );
};

interface LabelDialogProps {
  labelToEdit?: Label;
  onClose: () => void;
  open: boolean;
}

export const LabelDialog = memo(LabelDialogNP);
