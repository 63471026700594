import React, { useState } from 'react';

import { useTranslations } from '../../../hooks';
import { AddIcon, Button } from '../../../material';
import { AddSourceDialog } from '../AddSourceDialog';
import { useStyles } from './styles';

export const AddSourceDialogButtonPrimary: React.FC = () => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const { t } = useTranslations();

  return (
    <div className={classes.addSourceButtonContainer}>
      <Button
        className={classes.addSourceButtonPrimary}
        color="primary"
        disableElevation
        onClick={() => setOpen(true)}
        size="large"
        startIcon={<AddIcon />}
        variant="contained"
      >
        {t('add_source')}
      </Button>
      <AddSourceDialog close={() => setOpen(false)} open={open} />
    </div>
  );
};
