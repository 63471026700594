/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      chipRoot: {
        margin: '0 20px 16px 0',
        maxWidth: '180px',
        padding: '0 8px',
      },
      colorPrimary: {
        borderColor: '#DBDBDB',
        color: '#757575',
      },
    },
    { index: 1 },
  )();
