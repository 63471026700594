/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../../../hooks';
import { makeStyles } from '../../../../material';

export const useStyles = (props?: SearchSuggestionsStyleProps) => {
  const theme = useTheme();
  const queryItemDefautStyle = {
    borderLeft: '3px solid transparent',
    color: theme.designSystem.colors.secondary.main,
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    padding: '4px 16px',
    position: 'relative' as const,
    textDecoration: 'none',
    width: '100%',
  };

  const documentIconDefaultStyle = {
    marginLeft: '5px',
    marginRight: '5px',
    maxHeight: '100%',
    width: '18px',
  };
  return makeStyles(
    {
      actionIcon: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '1.125rem',
        marginRight: '8px',
        paddingLeft: '5px',
      },
      actionSearchIcon: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '1.125rem',
        left: '28px',
        marginRight: '15px',
        position: 'absolute',
        top: '17px',
        zIndex: 2,
      },
      documentIcon: {
        ...documentIconDefaultStyle,
        borderRadius: '4px',
      },
      fileLogo: documentIconDefaultStyle,
      folderIcon: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '1.125rem',
        marginRight: props?.inSpotlight ? '1px' : '5px',
        paddingLeft: props?.inSpotlight ? '2px' : '5px',
        width: '25px',
      },
      infoContainer: {
        alignItems: 'center',
        display: 'flex',
      },
      labelIcon: {
        color: props?.color,
        fontSize: '1.125rem',
        marginRight: '8px',
        paddingLeft: '5px',
      },
      ownerIcon: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '1.125rem',
        marginRight: '5px',
      },
      ownerText: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '0.75rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      ownerTextContainer: {
        alignItems: 'center',
        display: 'flex',
        width: '130px',
      },
      personAvatar: {
        borderRadius: '30px',
        fontSize: '0.75rem',
        height: '18px',
        margin: '16px 8px 16px 0px',
        width: '18px',
      },
      queryItem: queryItemDefautStyle,
      querySelected: {
        ...queryItemDefautStyle,
        backgroundColor: `${theme.designSystem.colors.disabled.main}26`,
        borderLeftColor: theme.designSystem.colors.active.main,
      },
      suggestionElement: {
        textDecoration: 'none',
      },
      suggestionInfoContainer: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: 3,
      },
      suggestionTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '20px',
        width: '350px',
      },
      suggestionTextMain: {
        color: theme.designSystem.colors.secondary.main,
        fontSize: '0.875rem',
        marginBottom: '-2px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      suggestionTextSub: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '0.75rem',
        marginTop: '-2px',
      },
      timeTextContainer: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '0.75rem',
        marginRight: '30px',
        whiteSpace: 'nowrap',
        width: '150px',
      },
    },
    { index: 1 },
  )(props);
};

interface SearchSuggestionsStyleProps {
  color?: string;
  inSpotlight?: boolean;
  isSharp?: boolean;
}
