import { colors, makeStyles } from '../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = makeStyles(
  {
    continueOnWebText: {
      '&:hover': {
        textDecoration: 'underline',
      },
      cursor: 'pointer',
      fontSize: '1rem',
      marginTop: '16px',
      width: 'fit-content',
    },
    desktopAppImg: {
      bottom: '0',
      maxWidth: '50vw',
      position: 'absolute',
      right: '50px',
    },
    downloadButton: {
      '&:hover': {
        backgroundColor: colors.green[300],
      },
      backgroundColor: colors.green[500],
      color: '#fff',
      marginTop: '32px',
      textTransform: 'none',
    },
    downloadButtonText: {
      fontSize: '1.25em',
      marginLeft: '8px',
    },
    downloadText: {
      fontSize: '2.5em',
      fontWeight: 'bolder',
    },
    mainContainer: {
      marginLeft: '10vw',
      marginTop: '30vh',
      maxWidth: 'min(850px, 50vw)',
    },
  },
  { index: 1 },
);
