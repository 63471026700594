import { VariantType as NotificationsType } from 'notistack';
import { v4 as uuid } from 'uuid';

import { TranslationKey } from '../../locales/types';
import { NotificationsState } from './types';

export const makeInfoNotification = (
  message: TranslationKey,
  data?: Record<string, unknown>,
): NotificationsState => makeNotification('info', message, data);

export const makeErrorNotification = (
  message: TranslationKey,
  data?: Record<string, unknown>,
): NotificationsState => makeNotification('error', message, data);

export const makeSuccessNotification = (
  message: TranslationKey,
  data?: Record<string, unknown>,
): NotificationsState => makeNotification('success', message, data);

export const makeWarningNotification = (
  message: TranslationKey,
  data?: Record<string, unknown>,
): NotificationsState => makeNotification('warning', message, data);

const makeNotification = (
  type: NotificationsType,
  message: TranslationKey,
  data?: Record<string, unknown>,
): NotificationsState => ({
  data,
  message,
  notificationId: uuid(),
  type,
});
