import * as queryString from 'query-string';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import useRouter from 'use-react-router';

import { ApiQuery, useConnectors, useUserProfile } from '../../hooks';
import { Actions, useDispatch } from '../../store';
import { StartUpLoader } from '../StartUpLoader';
import { MainViewBase } from './MainViewBase';
import { useStyles } from './styles';

export const MainView: React.FC = () => {
  const dispatch = useDispatch();

  const { isLoading: loadingConnectors } = useConnectors();
  const { isLoading: loadingUser } = useUserProfile();
  const shouldShowStartUpLoader = loadingUser || loadingConnectors;

  const queryClient = useQueryClient();

  const classes = useStyles();

  const { history, location } = useRouter();

  const search = queryString.parse(location.search);

  const { action, connectorId, query } = search;
  const actionSource = action ? (action as string).split(':')[1] : undefined;
  const urlQuery = query ? decodeURIComponent(query as string) : undefined;

  useEffect(() => {
    if (actionSource) queryClient.invalidateQueries(ApiQuery.GET_MY_CONNECTORS);
  }, [actionSource, dispatch, queryClient]);

  useEffect(() => {
    if (urlQuery) {
      dispatch(Actions.setSearchUserQuery(urlQuery));
      dispatch(Actions.setSearchQuery(urlQuery));
      dispatch(Actions.refreshSearch());
    }
  }, [dispatch, urlQuery]);

  if (shouldShowStartUpLoader) return <StartUpLoader />;

  return (
    <MainViewBase
      actionSource={actionSource}
      classes={classes}
      connectorId={connectorId as string}
      goToDownloadDesktop={() => history.push('/download-desktop')}
    />
  );
};
