import React from 'react';

import { useTranslations } from '../../../../hooks';
import { DialogContentText, FormControl, TextField } from '../../../../material';
import { useStyles } from '../styles';

export const LabelNameInput: React.FC<LabelDialogProps> = ({
  labelNameValue,
  handleLabelInputChange,
  error,
  helperText,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();

  return (
    <FormControl className={classes.formControl}>
      <DialogContentText>{t('new_tag_input')}</DialogContentText>
      <TextField
        classes={{ root: classes.labelNameInputRoot }}
        className={classes.labelNameInput}
        error={error}
        helperText={helperText}
        onChange={handleLabelInputChange}
        placeholder={t('new_tag_input_placeholder')}
        value={labelNameValue}
        variant="outlined"
      />
    </FormControl>
  );
};

interface LabelDialogProps {
  error: boolean;
  handleLabelInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  helperText: string;
  labelNameValue: string;
}
