import React from 'react';

import appLogo from '../../assets/logo-256.png';
import { useTranslations } from '../../hooks';
import { Button, Typography } from '../../material';
import { useStyles } from './styles';

export const ErrorBoundaryFallback: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  const restart = (): void => {
    if (window.electron) {
      window.electron.relaunchDesktopApp();
    } else {
      window.location.reload();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.errorBoundryContainer} role="alert">
        <img alt="Outmind logo" className={classes.outmindLogo} src={appLogo} />
        <div className={classes.messageContainer}>
          <Typography variant="h6">{t('something_went_wrong')}</Typography>
          <Typography variant="h6">{t('app_crash_helper_text')}</Typography>
        </div>
        <Button color="primary" onClick={restart} variant="contained">
          {t('restart')}
        </Button>
      </div>
    </div>
  );
};
