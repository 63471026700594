import { ClientConnectorRoot, ConnectorRoot } from '@outmind/types';

export const getDeepestflatRoots = (_root: ClientConnectorRoot): Record<string, ConnectorRoot> => {
  if (_root.subRoots) {
    const deepestRootsIds = _root.isRootFolder ? { [_root.id]: _root.isSynced ?? true } : {};
    return {
      ...deepestRootsIds,
      ..._root.subRoots.reduce(
        (_rootsInfo, subRoot) => ({ ..._rootsInfo, ...getDeepestflatRoots(subRoot) }),
        {},
      ),
    };
  }
  return { [_root.id]: { ..._root } };
};

export const getDeepestRootsIds = (_root: ClientConnectorRoot): Record<string, boolean> => {
  if (_root.subRoots) {
    const deepestRootsIds = _root.isRootFolder ? { [_root.id]: _root.isSynced ?? true } : {};
    return {
      ...deepestRootsIds,
      ..._root.subRoots.reduce(
        (_rootsInfo, subRoot) => ({ ..._rootsInfo, ...getDeepestRootsIds(subRoot) }),
        {},
      ),
    };
  }
  return { [_root.id]: _root.isSynced };
};
