import { useState } from 'react';

import { useSelector } from '../../store';

const { REACT_APP_APP_NAME: APP_NAME } = process.env;

/**
 * Hook used to detect the runtime environment
 */
export const useRuntimeEnv = (): RuntimeEnv => {
  const [inChrome] = useState(detectChrome);
  const [inEdge] = useState(detectEdge);
  const [inElectron] = useState(detectElectron);
  const [inExtension] = useState(APP_NAME === '@outmind/spotlight-extension');
  const [inFirefox] = useState(detectFirefox);
  const [inIE] = useState(detectIE);
  const [inMacOS] = useState(detectMacOS);
  const inMicrosoftTeams = useSelector((s) => s.user.inMicrosoftTeams);
  const [inOpera] = useState(detectOpera);
  const [inSafari] = useState(detectSafari);
  const [inWindows] = useState(detectWindows);

  return {
    inChrome,
    inEdge,
    inElectron,
    inExtension,
    inFirefox,
    inIE,
    inMacOS,
    inMicrosoftTeams,
    inOpera,
    inSafari,
    inWindows,
  };
};

/**
 * Describes the output of the `useRuntimeEnv` hook
 */
interface RuntimeEnv {
  /**
   * Whether this code is runned in a Chrome browser
   */
  inChrome: boolean;
  /**
   * Whether this code is runned in an Edge browser
   */
  inEdge: boolean;
  /**
   * Whether this code is runned in a desktop app with Electron
   */
  inElectron: boolean;
  /**
   * Whether this code is runned from the @outmind/spotlight-extension
   */
  inExtension: boolean;
  /**
   * Whether this code is runned in a Firefox browser
   */
  inFirefox: boolean;
  /**
   * Whether this code is runned in an Internet Explorer browser
   */
  inIE: boolean;
  /**
   * Whether this code is runned on a MacOS device
   */
  inMacOS: boolean;
  /**
   * Whether this code is runned in Microsoft Teams
   */
  inMicrosoftTeams: boolean;
  /**
   * Whether this code is runned in an Opera browser
   */
  inOpera: boolean;
  /**
   * Whether this code is runned in a Safari browser
   */
  inSafari: boolean;
  /**
   * Whether this code is runned in a Windows browser
   */
  inWindows: boolean;
}

/**
 * Detects if the browser this app is running in is Opera
 */
const detectOpera = (): boolean =>
  (!!(window as any).opr && !!opr.addons) ||
  !!(window as any).opera ||
  navigator.userAgent.indexOf(' OPR/') >= 0;
declare const opr: any;

/**
 * Detects if the browser this app is running in is Firefox
 */
const detectFirefox = (): boolean => typeof InstallTrigger !== 'undefined';
declare const InstallTrigger: any;

/**
 * Detects if the browser this app is running in is Safari
 */
const detectSafari = (): boolean =>
  /constructor/i.test(window.HTMLElement as unknown as string) ||
  ((p): boolean => p.toString() === '[object SafariRemoteNotification]')(
    !(window as any).safari || (typeof safari !== 'undefined' && safari.pushNotification),
  );
declare const safari: any;

/**
 * Detects if the browser this app is running in is Internet Explorer
 */
// eslint-disable-next-line spaced-comment
const detectIE = (): boolean => /*@cc_on!@*/ false || !!(document as any).documentMode;

/**
 * Detects if the browser this app is running in is Edge
 */
const detectEdge = (): boolean => !detectIE() && !!(window as any).StyleMedia;

/**
 * Detects if the browser this app is running is Google Chrome
 */
const detectChrome = (): boolean =>
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

/**
 * Detects if the browser is running on a Mac environment
 */
const detectMacOS = (): boolean => navigator.platform.toUpperCase().indexOf('MAC') >= 0;

/**
 * Detects if the browser is running on a Windows environment
 */
const detectWindows = (): boolean => navigator.platform.toUpperCase().indexOf('WIN') >= 0;

/**
 * Detects if we are currently running in an Electron environment (and thus a desktop environment)
 */
const detectElectron = (): boolean => navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;
