import React from 'react';

import { AddSourceDialogButtonIcon } from './AddSourceDialogButtonIcon';
import { AddSourceDialogButtonOutlined } from './AddSourceDialogButtonOutlined';
import { AddSourceDialogButtonPrimary } from './AddSourceDialogButtonPrimary';
import { AddSourceDialogButtonSecondary } from './AddSourceDialogButtonSecondary';
import { AddSourceDialogButtonText } from './AddSourceDialogButtonText';

export const AddSourceDialogButton: React.FC<AddSourceDialogButtonProps> = ({ variant }) => {
  switch (variant) {
    case 'secondary':
      return <AddSourceDialogButtonSecondary />;
    case 'outlined':
      return <AddSourceDialogButtonOutlined />;
    case 'text':
      return <AddSourceDialogButtonText />;
    case 'primary':
      return <AddSourceDialogButtonPrimary />;
    case 'icon':
      return <AddSourceDialogButtonIcon />;

    default:
      return <AddSourceDialogButtonPrimary />;
  }
};

interface AddSourceDialogButtonProps {
  variant?: 'primary' | 'outlined' | 'secondary' | 'text' | 'icon';
}
