import clsn from 'classnames';
import DOMPurify from 'dompurify';
import React from 'react';

import { useLinkHandler } from '../../hooks';
import { Typography, TypographyProps } from '../../material';
import { useStyles } from './styles';

/**
 * Displays a `Typography` component by directly injecting the inner html
 */
export const SanitizedHtmlTypography: React.FC<SanitizedHtmlTypographyProps> = ({
  className,
  href,
  html,
  isLocalFile,
  ...rest
}) => {
  const cleanHtml = DOMPurify.sanitize(html);

  // Replaces all links in innerHTML with rel="noopener noreferrer" target="_blank"
  const finalHtml = cleanHtml.replace(/<a /g, '<a rel="noopener noreferrer" target="_blank" ');

  const goToHref = useLinkHandler(href, { isLocalFile, targetBlank: true });

  const classes = useStyles();

  if (href) {
    return (
      <Typography
        {...rest}
        className={clsn(className, classes.link)}
        dangerouslySetInnerHTML={{ __html: finalHtml }}
        onClick={(e) => {
          e.stopPropagation();
          goToHref();
        }}
      />
    );
  }

  return (
    <Typography {...rest} className={className} dangerouslySetInnerHTML={{ __html: finalHtml }} />
  );
};

interface SanitizedHtmlTypographyProps extends TypographyProps {
  href?: string;
  html: string;
  isLocalFile?: boolean;
}
