import React from 'react';

import { useTranslations } from '../../../hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '../../../material';

export const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({
  children,
  dialogTitle,
  deleteButtonText,
  onClose,
  onDeleteConfirmed,
  textHelper,
  open,
}) => {
  const { t } = useTranslations();

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={() => onClose()}
      open={open}
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{textHelper}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="default" onClick={() => onClose()}>
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onClose();
            onDeleteConfirmed();
          }}
        >
          {deleteButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ConfirmDeleteDialogProps {
  deleteButtonText: string;
  dialogTitle: string;
  onClose: () => void;
  onDeleteConfirmed: () => void;
  open: boolean;
  textHelper: string;
}
