import { SearchSuggestionsResult } from '@outmind/types';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { ApiQuery, useApiRouteMaker } from '../useApi';

/**
 * Retrieves the suggestions
 */
export const useSuggestions = ({
  connectorIds,
  q,
}: Params$UseSuggestions): UseQueryResult<SearchSuggestionsResult[]> => {
  const route = useApiRouteMaker(ApiQuery.GET_SUGGESTIONS)();

  const getSuggestionsQuery = useQuery<SearchSuggestionsResult[]>(
    [ApiQuery.GET_SUGGESTIONS, { connectorIds, q }],
    async () => {
      const result = await axios({
        method: route.method,
        params: { connectorIds, q },
        url: route.url,
        withCredentials: true,
      });

      return result.data.results;
    },
    {
      keepPreviousData: true,
      placeholderData: [],
    },
  );

  return getSuggestionsQuery;
};

interface Params$UseSuggestions {
  connectorIds: string[];
  q: string;
}
