import React, { memo, useState } from 'react';

import { Drawer, Fab, FullscreenIcon } from '../../material';
import { useSelector } from '../../store';
import { PreviewDialog } from '../CustomDialog/PreviewDialog';
import { ContentPreview } from './ContentPreview';
import { HeaderPreview } from './HeaderPreview';
import { useStyles } from './styles';

const DocumentPreviewNP: React.FC<DocumentPreviewProps> = ({ fullScreen }) => {
  const classes = useStyles();

  const { documentType, infos: previewInfos, isOpened } = useSelector((s) => s.previews);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const disableFullScreen = (): void => {
    setIsFullScreen(false);
  };

  const enableFullScreen = (): void => {
    setIsFullScreen(true);
  };

  if (!previewInfos) return null;

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawerPaper, root: classes.drawer }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      open={isOpened}
      variant="permanent"
    >
      {documentType ? <HeaderPreview documentType={documentType} /> : null}
      {documentType ? <ContentPreview documentType={documentType} /> : null}
      {isHovered && !fullScreen ? (
        <Fab
          aria-label="full screen"
          className={classes.fullScreenButton}
          color="primary"
          onClick={enableFullScreen}
          size="medium"
        >
          <FullscreenIcon />
        </Fab>
      ) : null}
      <PreviewDialog onClose={disableFullScreen} open={isFullScreen}>
        <DocumentPreview fullScreen />
      </PreviewDialog>
    </Drawer>
  );
};

interface DocumentPreviewProps {
  fullScreen?: boolean;
}
export const DocumentPreview = memo(DocumentPreviewNP);
