import { ElasticFile } from '@outmind/types';
import React from 'react';

import { FileIcon } from '../../../../assets/icons/file';
import { FolderIcon } from '../../../../assets/icons/folder';
import * as util from '../../../../util';
import { useStyles } from './styles';

export const DocumentLogo: React.FC<DocumentLogoProps> = ({
  isFilled,
  inSpotlight,
  size,
  file,
}) => {
  const classes = useStyles({ inSpotlight });

  if (file.category === 'folder') {
    return <FolderIcon className={classes.folderIcon} isFilled={isFilled} size={size} />;
  }
  if (file.category === 'unknown') {
    return <FileIcon className={classes.folderIcon} isFilled={isFilled} size={size} />;
  }
  return util.getFileLogo(file.mimeType, classes.documentIcon);
};

interface DocumentLogoProps {
  file: ElasticFile;
  inSpotlight?: boolean;
  isFilled: boolean;
  size: number;
}
