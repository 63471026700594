/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';

export enum ActionTypes {
  CLOSE_APP_DIALOG = 'CLOSE_APP_DIALOG',
  CLOSE_FILE_NOT_FOUND_DIALOG = 'CLOSE_FILE_NOT_FOUND_DIALOG',
  OPEN_APP_DIALOG = 'OPEN_APP_DIALOG',
  OPEN_FILE_NOT_FOUND_DIALOG = 'OPEN_FILE_NOT_FOUND_DIALOG',
}

export const Actions = {
  closeAppDialog: () => action(ActionTypes.CLOSE_APP_DIALOG),
  closeFileNotFoundDialog: () => action(ActionTypes.CLOSE_FILE_NOT_FOUND_DIALOG),
  openAppDialog: () => action(ActionTypes.OPEN_APP_DIALOG),
  openFileNotFoundDialog: () => action(ActionTypes.OPEN_FILE_NOT_FOUND_DIALOG),
};
