/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      checkboxContainer: {
        cursor: 'pointer',
        pointerEvents: 'none',
        position: 'relative',
        width: '100%',
      },
      filtersContainer: {
        overflowX: 'hidden',
        padding: '10px 30px 0 30px',
      },
      hovered: {
        background: theme.designSystem.colors.active.main,
      },
      hoverOverlay: {
        height: '100%',
        marginLeft: '-32px',
        opacity: 0.05,
        position: 'absolute',
        width: 'calc(100% + 64px)',
      },
      sectionContainer: {
        position: 'relative',
      },
      sectionTitle: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '14px',
        marginTop: '24px',
      },
      tooltipPlacementRight: {
        left: '-32px',
        width: 'fit-content',
      },
    },
    { index: 1 },
  )();
};
