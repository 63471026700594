import { Source, sources } from '@outmind/types';
import React, { useState } from 'react';

import { useTranslations } from '../../hooks';
import { IconButton, Tooltip, WarningRoundedIcon } from '../../material';
import { ConnectorReconnectDialog } from './ConnectorReconnectDialog';
import { useStyles } from './styles';

export const ConnectorStatusWarning: React.FC<ConnectorStatusWarningProps> = ({
  connectorId,
  email,
  source,
}) => {
  const { t } = useTranslations();
  const classes = useStyles();

  const [showConnectorReconnectDialog, setShowConnectorReconnectDialogOpen] = useState(false);

  const oneConnectorReconnectDialogClose = (): void => setShowConnectorReconnectDialogOpen(false);
  const tooltipTitle = `${t('lost_auth', { source: sources[source].name })}. ${t(
    'click_to_reconnect',
  )}`;

  return (
    <>
      <Tooltip arrow placement="right" title={tooltipTitle}>
        <IconButton
          aria-label="Info"
          classes={{ root: classes.iconButton }}
          onClick={() => setShowConnectorReconnectDialogOpen(true)}
        >
          <WarningRoundedIcon />
        </IconButton>
      </Tooltip>
      <ConnectorReconnectDialog
        connectorId={connectorId}
        email={email}
        onClose={oneConnectorReconnectDialogClose}
        open={showConnectorReconnectDialog}
        source={source}
      />
    </>
  );
};

interface ConnectorStatusWarningProps {
  connectorId: string;
  email: string;
  source: Source;
}
