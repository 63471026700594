import i18next, { TOptions } from 'i18next';
import {
  Namespace,
  useTranslation as useI18NextTranslation,
  UseTranslationOptions,
} from 'react-i18next';

import { TranslationKey } from '../../locales/types';

export const useTranslations = (
  ns?: Namespace,
  options?: UseTranslationOptions,
): UseTranslationsOutput => {
  const { i18n, ready, t } = useI18NextTranslation(ns, options);

  const strongT = (key: TranslationKey, tOptions?: TOptions): string => t(key, tOptions);

  return { i18n, ready, t: strongT };
};

interface UseTranslationsOutput {
  i18n: typeof i18next;
  ready: boolean;
  t: (key: TranslationKey, option?: TOptions) => string;
}
