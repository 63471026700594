import { makeStyles } from '../../material';

export const useStyles = makeStyles({
  leftColumnContainer: {
    borderRight: '1px solid #f4f4f4',
    flexDirection: 'column',
    height: 'calc(100vh - 65px)',
    overflowY: 'auto',
  },
  leftColumnContainerInner: {
    minHeight: 'calc(100% - 125px)',
  },
  mainColumnContainer: {
    height: 'calc(100vh - 65px)',
  },
});
