import { ExtendedSources, Source } from '@outmind/types';
import React from 'react';

import { AddClassicSourceButton } from './AddClassicSourceButton';
import { AddLocalConnectorButton } from './AddLocalConnectorButton';
import { AddOAuthSourceButton } from './AddOAuthSourceButton';

export const AddSourceButton: React.FC<AddSourceButtonProps> = ({
  beta,
  disabled,
  setChosenSource,
  setShowAddSourceConfirmationDialog,
  setShowCredentialsDialog,
  source,
}) => {
  if (source === Source.VSA) {
    return (
      <AddClassicSourceButton
        beta={beta}
        disabled={disabled}
        openCredentialsDialog={() => {
          setChosenSource(source);
          setShowCredentialsDialog(true);
        }}
        source={source}
      />
    );
  }

  if (source === Source.LocalDisk || source === Source.NetworkDisk) {
    return <AddLocalConnectorButton beta={beta} disabled={disabled} source={source} />;
  }

  return (
    <AddOAuthSourceButton
      beta={beta}
      disabled={disabled}
      openAddSourceConfirmationDialog={() => {
        setChosenSource(source);
        setShowAddSourceConfirmationDialog(true);
      }}
      source={source}
    />
  );
};

interface AddSourceButtonProps {
  beta: boolean;
  disabled?: boolean;
  setChosenSource: (source: ExtendedSources) => void;
  setShowAddSourceConfirmationDialog: (bool: boolean) => void;
  setShowCredentialsDialog: (bool: boolean) => void;
  source: Source;
}
