import { LocalSources, Source } from '@outmind/types';
import React from 'react';

import { SeparatedPaths } from '../../..';
import { ArrowDropDownIcon, ArrowRightIcon, TreeView } from '../../../material';
import { useStyles } from '../styles';
import { SelectLocalTreeItem } from './SelectLocalTreeItem';

export const SelectLocalTreeView: React.FC<SelectLocalTreeViewProps> = ({
  disabled,
  folders,
  removeFolder,
  separatedPaths,
  source,
}) => {
  const classes = useStyles();
  const baseRootElements = folders.map((folder) => (
    <SelectLocalTreeItem
      disabled={disabled}
      folder={folder}
      outOfScopeLocalDisk={
        source === Source.LocalDisk && !separatedPaths.localDiskPaths.includes(folder)
      }
      outOfScopeNetworkDisk={
        source === Source.NetworkDisk && !separatedPaths.fileServerPaths.includes(folder)
      }
      removeFolder={removeFolder}
    />
  ));

  return (
    <div className={classes.treeView}>
      <TreeView defaultCollapseIcon={<ArrowDropDownIcon />} defaultExpandIcon={<ArrowRightIcon />}>
        {baseRootElements}
      </TreeView>
    </div>
  );
};

interface SelectLocalTreeViewProps {
  disabled?: boolean;
  folders: string[];
  removeFolder: (folder: string) => void;
  separatedPaths: SeparatedPaths;
  source: LocalSources;
}
