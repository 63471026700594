import { SearchAccountsResult } from '@outmind/types';
import clsn from 'classnames';
import React from 'react';
import { CashBanknote as AmountIcon, World as WebsiteIcon } from 'tabler-icons-react';

import { useTranslations } from '../../../hooks';
import { useLinkHandler } from '../../../hooks/useLinkHandler';
import { Avatar, BusinessIcon, Grid, Link, Paper, Tooltip, Typography } from '../../../material';
import { abbreviateNumber } from '../../../util';
import { DocumentHeader } from '../DocumentHeader';
import { makeTooltipProps } from '../util';
import { useStyles } from './styles';

/**
 * Displays a CRM Account
 */
export const Account: React.FC<AccountProps> = ({ result }) => {
  const {
    document: { id, accessLink, annualRevenue, industry, name, owner, source, website },
  } = result;
  const { t } = useTranslations();
  const classes = useStyles();

  const ownerAvatarUrl = owner?.avatarUrl;
  const ownerName = owner?.name;
  const websiteUrl = website ? `//${website.replace(/http(?:s)?:\/\//, '')}` : undefined;
  const tootlipProps = makeTooltipProps(t);

  const annualRevenueFriendly = annualRevenue ? `${abbreviateNumber(annualRevenue)}€` : 'N/A';

  const onClick = useLinkHandler(accessLink);

  return (
    <Paper className={classes.accountContainer} elevation={0} onClick={onClick}>
      <Grid className={classes.accountInnerContainer} container>
        <Grid item sm={12}>
          <DocumentHeader documentId={id} source={source} title={name} />
        </Grid>
        <Grid className={classes.accountMetasInnerContainer} container sm={12}>
          <Grid className={clsn(classes.accountInfo, classes.accountWebsite)} item sm={3}>
            <WebsiteIcon />
            {website ? (
              <Link href={websiteUrl} target="_blank">
                <Typography className={classes.accountWebsite}>{website}</Typography>
              </Link>
            ) : (
              <Typography className={classes.accountInfoText}>N/A</Typography>
            )}
          </Grid>
          <Tooltip {...tootlipProps('crm_account_owner')}>
            <Grid className={classes.accountInfo} item sm={2}>
              <Avatar className={classes.accountLogo} src={ownerAvatarUrl} />
              <Typography className={classes.accountInfoText}>{ownerName}</Typography>
            </Grid>
          </Tooltip>
          <Tooltip {...tootlipProps('crm_account_annual_revenue')}>
            <Grid className={classes.accountInfo} item sm={2}>
              <AmountIcon color="#666" size={21} />
              <Typography className={classes.accountInfoText}>{annualRevenueFriendly}</Typography>
            </Grid>
          </Tooltip>
          {industry ? (
            <Tooltip {...tootlipProps('crm_account_industry')}>
              <Grid className={classes.accountInfo} item sm={2}>
                <BusinessIcon className={classes.accountInfoText} />
                <Typography className={classes.accountInfoText}>{industry}</Typography>
              </Grid>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
};

interface AccountProps {
  result: SearchAccountsResult;
}
