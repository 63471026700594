import { v4 as uuid } from 'uuid';

import { ActionTypes } from '../actions';
import { AppActions } from '../types';
import { initialState } from './defaults';
import { SearchState } from './types';

/**
 * Reduce incoming action for state.search branch in Redux
 */
export const reducer = (state: SearchState = initialState, action: AppActions): SearchState => {
  switch (action.type) {
    case ActionTypes.SET_CHOSEN_SORT_BY:
      return {
        ...state,
        chosenSortBy: action.payload,
        mode: action.payload === 'date-desc' ? 'GET_LAST_ELEMENTS' : 'SEARCH',
        queryId: uuid(),
      };
    case ActionTypes.SET_CHOSEN_INDEX_TAB:
      return { ...state, chosenIndexTab: action.payload };
    case ActionTypes.SET_SEARCH_USER_QUERY:
      return {
        ...state,
        userQ: action.payload,
      };
    case ActionTypes.SET_SEARCH_QUERY:
      return {
        ...state,
        q: action.payload,
      };
    case ActionTypes.ADD_LABEL_FILTER: {
      return {
        ...state,
        mode: 'SEARCH',
        q: '',
        queryId: uuid(),
        userQ: '',
      };
    }
    case ActionTypes.ADD_FOLDER_FILTER:
    case ActionTypes.ADD_PERSON_FILTER:
    case ActionTypes.ADD_DOCUMENT_RELATION_FILTER:
    case ActionTypes.REMOVE_FOLDER_FILTER:
    case ActionTypes.REMOVE_LABEL_FILTER:
    case ActionTypes.REMOVE_PERSON_FILTER:
    case ActionTypes.REMOVE_DOCUMENT_RELATION_FILTER: {
      return {
        ...state,
        mode: state.q.length > 0 ? 'SEARCH' : 'GET_LAST_ELEMENTS',
        q: '',
        queryId: uuid(),
        userQ: '',
      };
    }
    case ActionTypes.REFRESH_SEARCH:
    case ActionTypes.SET_ACCOUNT_FILTER:
    case ActionTypes.SET_CATEGORY_FILTER:
    case ActionTypes.SET_DATE_FILTER_FROM:
    case ActionTypes.SET_DATE_FILTER_TO:
    case ActionTypes.SET_FIELD_FILTER: {
      return {
        ...state,
        mode: state.q.length > 0 ? 'SEARCH' : 'GET_LAST_ELEMENTS',
        queryId: uuid(),
      };
    }
    case ActionTypes.RESET_SEARCH:
      return {
        ...initialState,
        queryId: uuid(),
      };
    default:
      return state;
  }
};
