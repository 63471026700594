import { ExtendedSources } from '@outmind/types';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { ApiQuery, useApiRouteMaker } from '../../useApi';
import { useFlowType } from '../../useFlowType';

/**
 * Retrieves the authentication URL of the OAuth2 flow for a certain source to update
 * a certain connector credentials
 */
export const useGetUpdateAuthUrl = (
  connectorId: string,
  source: ExtendedSources,
): UseQueryResult<string> => {
  const flowType = useFlowType();

  const makeRoute = useApiRouteMaker(ApiQuery.GET_UPDATE_AUTH_URL);

  const getAuthUrlQuery = useQuery<string>(
    [ApiQuery.GET_UPDATE_AUTH_URL, source, connectorId],
    async () => {
      const route = makeRoute({ connectorId, source });

      const result = await axios({
        method: route.method,
        params: {
          flow: flowType,
        },
        url: route.url,
        withCredentials: true,
      });

      return result.data.authUrl;
    },
    {
      staleTime: 3 * 60 * 60 * 1000, // 3 hours
    },
  );

  return getAuthUrlQuery;
};
