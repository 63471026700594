import * as queryString from 'query-string';
import React, { memo, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';

import { useTranslations } from '../../hooks';
import { FullscreenLoader } from '../FullscreenLoader';

const AuthStartViewNP: React.FC = () => {
  const {
    location: { search: urlSearch },
  } = useReactRouter();

  const { t } = useTranslations();

  const [next, setNext] = useState<string>();
  const [shouldGoNext, setShouldGoNext] = useState(false);

  useEffect(() => {
    const { next: parsedNext } = queryString.parse(urlSearch);
    if (parsedNext && typeof parsedNext === 'string') {
      setNext(parsedNext);
      setTimeout(() => setShouldGoNext(true), 2000);
    }
  }, [urlSearch]);

  useEffect(() => {
    if (shouldGoNext && next) document.location.href = next;
  }, [next, shouldGoNext]);

  return (
    <FullscreenLoader
      actionButtonHelperText={t('click_if_no_redirect')}
      actionWhileLoading
      helperText={t('you_will_be_redirected')}
      onButtonClick={() => {
        if (next) document.location.href = next;
      }}
      status="loading"
    />
  );
};

export const AuthStartView = memo(AuthStartViewNP);
