import { SearchableDocuments } from '@outmind/types';
import React, { useEffect, useState } from 'react';

import { useTranslations } from '../../hooks';
import { TranslationKey } from '../../locales/types';
import { CloseIcon, IconButton, Typography } from '../../material';
import { useStyles } from './styles';

/**
 * Displays a banner when the search fails
 */
export const SearchErrorBanner: React.FC<SearchErrorBannerProps> = ({ index, show }) => {
  const classes = useStyles();

  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    setIsHidden(!show);
  }, [index, show]);

  const { t } = useTranslations();

  if (show && !isHidden) {
    return (
      <div className={classes.helperContainer}>
        <Typography className={classes.helperTextContainer}>
          <span className={classes.helperText}>
            {t('index_error', { index: t(`index_name_${index}` as TranslationKey) })}
          </span>
        </Typography>

        <IconButton
          className={classes.hideHelperButton}
          onClick={() => setIsHidden(true)}
          size="small"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
    );
  }

  return null;
};

interface SearchErrorBannerProps {
  index: SearchableDocuments;
  show: boolean;
}
