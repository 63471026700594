import { useTheme } from '../../../hooks';
import { makeStyles } from '../../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      addSourceButton: {
        '&:hover': {
          backgroundColor: '#eee',
        },
        '-ms-transition': 'background-color 150ms linear',
        '-webkit-transition': 'background-color 150ms linear',
        height: '100%',
        transition: 'background-color 150ms linear',
        width: '100%',
      },
      addSourceButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
      },
      addSourceButtonIcon: {
        color: 'white',
        position: 'absolute',
      },
      addSourceButtonPrimary: {
        borderRadius: '100px',
        height: '46px',
        textTransform: 'none',
      },
      addSourceButtonTextContainer: {
        left: '30px',
        position: 'relative',
      },
      addSourceButtonTextLabel: {
        '&::first-letter': {
          textTransform: 'uppercase',
        },
        fontWeight: 600,
        textAlign: 'left',
        textTransform: 'none',
      },
      iconButton: {
        color: 'inherit',
      },
      outlinedButtonRounded: {
        borderRadius: '36px',
      },
      outlinedPrimaryButton: {
        borderColor: theme.designSystem.colors.active.main,
        color: theme.designSystem.colors.active.main,
      },
    },
    { index: 1 },
  )();
};
