/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = (props: LabelColorSelectorProps) =>
  makeStyles(
    {
      colorBubble: {
        background: props.color,
        borderRadius: '100%',
        cursor: 'pointer',
        display: 'inline-flex',
        height: '36px',
        transition: 'all 0.15s ease',
        width: '36px',
      },
      colorBubbleContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '44px',
        justifyContent: 'center',
        width: '44px',
      },
      isSelected: {
        border: '4px solid #2895FB',
      },
    },
    { index: 1 },
  )(props);

interface LabelColorSelectorProps {
  color: string;
}
