export const firstKeyBindings = [
  {
    cmd: 'Control',
    name: 'Control',
    OS: 'all',
  },
  {
    cmd: 'Control+Shift',
    name: 'Control + Shift',
    OS: 'all',
  },
  {
    cmd: 'Control+Option',
    name: 'Control + Option',
    OS: 'mac',
  },
  {
    cmd: 'Cmd',
    name: 'Command',
    OS: 'all',
  },
  {
    cmd: 'Cmd+Shift',
    name: 'Command + Shift',
    OS: 'all',
  },
  {
    cmd: 'Command+Option',
    name: 'Command + Option',
    OS: 'mac',
  },
];

export const secondKeyBindings = [
  'Space',
  '.',
  ',',
  '/',
  ';',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '-',
  '=',
  'Q',
  'W',
  'E',
  'R',
  'T',
  'Y',
  'U',
  'I',
  'O',
  'P',
  'A',
  'S',
  'D',
  'F',
  'G',
  'H',
  'J',
  'K',
  'L',
  'Z',
  'X',
  'C',
  'V',
  'B',
  'N',
  'M',
  '[',
  ']',
];
