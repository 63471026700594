/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      avatarContainer: {
        borderRadius: '50px',
        boxSizing: 'border-box',
        transition: 'background-color 0.5s ease',
      },
      hovered: {
        backgroundColor: `${theme.designSystem.colors.disabled.main}26`,
      },
      itemText: {
        color: theme.designSystem.colors.text.main,
        fontSize: '14px',
        marginLeft: '-28px',
      },
      optionButton: {
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
      seperatorElement: {
        border: '0',
        borderTop: `1px solid ${theme.designSystem.colors.slider.main}`,
        height: '1px',
        maxWidth: '85%',
        padding: '0',
      },
      styledMenuItemRoot: {
        '&:focus': {
          backgroundColor: theme.designSystem.colors.hover.main,
        },
      },
      styleLink: {
        textDecoration: 'none !important',
      },
      styleMenuPaper: {
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.12)',
      },
      updateAvailableMenuItem: {
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
      userFirstName: {
        margin: '0 10px',
      },
      userProfileAvatarContainer: {
        cursor: 'pointer',
        marginLeft: '4px',
      },
      userProfileContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    { index: 1 },
  )();
};
