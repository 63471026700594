import { TooltipProps } from '@material-ui/core';

import { TranslationKey } from '../../locales/types';

export const makeTooltipProps =
  (t: (key: TranslationKey) => string) =>
  (key: TranslationKey): Pick<TooltipProps, 'aria-label' | 'placement' | 'title'> => ({
    'aria-label': t(key),
    placement: 'bottom-start' as const,
    title: t(key),
  });
