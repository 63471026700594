import { CaretRight, PersonCircle } from '@styled-icons/fluentui-system-filled';
import classnames from 'classnames';
import React from 'react';

import { SanitizedHtmlTypography } from '../SanitizedHtmlTypography';
import { useStyles } from './styles';

/**
 * Displays a document participant from the "from", "to" or "inAppOwners" property for example.
 * It can be a creator, a sender or a receiver of the document
 */
export const DocumentParticipant: React.FC<DocumentParticipantProps> = ({ isReceiver, name }) => {
  const classes = useStyles();
  const documentParticipantClasses = classnames(
    classes.resultElementMeta,
    classes.resultElementMaxWidth,
  );
  return (
    <div className={documentParticipantClasses}>
      {isReceiver ? <CaretRight className={classes.messageReceiverArrow} size={10} /> : null}
      <PersonCircle className={classes.resultElementMetaIcon} size={16} />
      <SanitizedHtmlTypography className={classes.resultElementMetaText} html={name} />
    </div>
  );
};

interface DocumentParticipantProps {
  isReceiver?: boolean;
  name: string;
}
