/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = ({ addButtonPosition }: DocumentLabelsStylesProps) =>
  makeStyles(
    {
      labelChipsContainer: {
        columnGap: '12px',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
      },
      tagsContainer: {
        alignItems: 'center',
        columnGap: '12px',
        display: 'grid',
        flexDirection: 'row',
        flexGrow: 1,
        gridTemplateColumns: 'repeat(auto-fit,  minmax(10px, max-content))',
        margin: '0 8px',
        overflow: 'hidden',
        paddingLeft: addButtonPosition === 'start' ? '12px' : undefined,
      },
    },
    { index: 1 },
  )();

interface DocumentLabelsStylesProps {
  addButtonPosition?: 'start' | 'end';
}
