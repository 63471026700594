import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { parse as parseYAML } from 'yaml';

import { useRuntimeEnv } from '../useRuntimeEnv';

/**
 * Retrieves the link to Download Desktop App
 */
const RELEASES_BUCKET_BASE_URL = 'https://app-desktop-releases.s3.eu-west-3.amazonaws.com';
const SUPPORTED_OS_YML_PATH = {
  mac: 'latest-mac.yml',
  windows: 'latest.yml',
};
export const useDownloadDesktopLink = (): UseQueryResult<string> => {
  const { inMacOS, inWindows } = useRuntimeEnv();

  const getDownloadDesktopLink = useQuery<string>('GET_DOWNLOAD_DESKTOP_LINK', async () => {
    const latestYmlPath = getLatestYmlPath();
    const result = await axios({
      method: 'GET',
      url: `${RELEASES_BUCKET_BASE_URL}/${latestYmlPath}`,
    });
    const { data } = result;
    if (data && latestYmlPath) {
      const { files } = parseYAML(data);
      const { url } = files.find((file) => {
        if (inMacOS) return !!file.url.match(/.+\.dmg$/);
        return true;
      });
      return `${RELEASES_BUCKET_BASE_URL}/${url}` as string;
    }
    return '';
  });

  const getLatestYmlPath = (): string | undefined => {
    if (inMacOS) return SUPPORTED_OS_YML_PATH.mac;
    if (inWindows) return SUPPORTED_OS_YML_PATH.windows;
    return undefined;
  };

  return getDownloadDesktopLink;
};
