import React from 'react';

import { useTranslations } from '../../../hooks';
import { CheckIcon, Link, PlayArrowOutlinedIcon, Typography } from '../../../material';
import { useStyles } from './styles';

export const PrivacyReminder: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslations();
  const reminders = [
    {
      content: t('privacy_reminder_content_1'),
      title: `${t('privacy_reminder_title_1')}:`,
    },
    {
      content: t('privacy_reminder_content_2'),
      title: `${t('privacy_reminder_title_2')}:`,
    },
    {
      content: t('privacy_reminder_content_3'),
      title: `${t('privacy_reminder_title_3')}`,
    },
  ];

  const privacyReminders = reminders.map((reminder, i) => (
    <div key={i} className={classes.reminderContainer}>
      <CheckIcon className={classes.checkIcon} />
      <Typography className={classes.reminderText} variant="body1">
        <span className={classes.reminderTitle}>{reminder.title}</span>
        <span>&nbsp;</span>
        <span>{reminder.content}</span>
      </Typography>
    </div>
  ));

  return (
    <div className={classes.privacyReminderContainer}>
      <Typography className={classes.privacyReminderTitle} variant="h6">
        {t('privacy_reminder_title')}
      </Typography>
      <div className={classes.reminderElementsContainer}>{privacyReminders}</div>
      <div className={classes.learnMoreContainer}>
        <PlayArrowOutlinedIcon color="action" />
        <Link
          className={classes.learnMoreLink}
          color="inherit"
          href="http://help.outmind.ai"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('learn_more')}
        </Link>
      </div>
    </div>
  );
};
