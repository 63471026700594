/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../material';

export const useStyles = (props?: StartUpLoaderStylesProps) =>
  makeStyles(
    {
      '@-moz-keyframes fade': { '0%': { opacity: 1 }, '50%': { opacity: 0.25 } },
      '@-moz-keyframes rotate': {
        '0%': { '-moz-transform': 'rotate(0deg)' },
        '100%': { '-moz-transform': 'rotate(360deg)' },
      },
      '@-o-keyframes fade': { '0%': { opacity: 1 }, '50%': { opacity: 0.25 } },
      '@-o-keyframes rotate': {
        '0%': { '-o-transform': 'rotate(0deg)' },
        '100%': { '-o-transform': 'rotate(360deg)' },
      },
      '@-webkit-keyframes fade': { '0%': { opacity: 1 }, '50%': { opacity: 0.25 } },
      '@-webkit-keyframes rotate': {
        '0%': { '-webkit-transform': 'rotate(0deg)' },
        '100%': { '-webkit-transform': 'rotate(360deg)' },
      },
      '@keyframes fade': { '0%': { opacity: 1 }, '50%': { opacity: 0.25 } },
      '@keyframes rotate': {
        '0%': {
          '-moz-transform': 'rotate(0deg)',
          '-ms-transform': 'rotate(0deg)',
          '-webkit-transform': 'rotate(0deg)',
          transform: 'rotate(0deg)',
        },
        '100%': {
          '-moz-transform': 'rotate(360deg)',
          '-ms-transform': 'rotate(360deg)',
          '-webkit-transform': 'rotate(360deg)',
          transform: 'rotate(360deg)',
        },
      },
      loaderCircle: {
        '-moz-animation': '$fade 1.2s infinite ease-in-out',
        '-o-animation': '$fade 1.2s infinite ease-in-out',
        '-webkit-animation': '$fade 1.2s infinite ease-in-out',
        animation: '$fade 1.2s infinite ease-in-out',
        borderRadius: '50%',
        boxShadow: 'inset 0 0 0 1px #b3b3ff54',
        height: '120px',
        left: '50%',
        marginLeft: '-60px',
        marginTop: '-60px',
        position: 'absolute',
        top: '50%',
        width: '120px',
      },
      loaderLine: {
        borderRadius: '50%',
        boxShadow: 'inset 0 0 0 1px #b3b3ff',
        height: '120px',
        width: '120px',
      },
      loaderLineMask: {
        '-moz-animation': '$rotate 1.2s infinite linear',
        '-moz-transform-origin': '60px 60px',
        '-ms-transform-origin': '60px 60px',
        '-o-animation': '$rotate 1.2s infinite linear',
        '-o-transform-origin': '60px 60px',
        '-webkit-animation': '$rotate 1.2s infinite linear',
        '-webkit-mask-image': '-webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0))',
        '-webkit-transform-origin': '60px 60px',
        animation: '$rotate 1.2s infinite linear',
        height: '120px',
        left: '50%',
        marginLeft: '-60px',
        marginTop: '-60px',
        overflow: 'hidden',
        position: 'absolute',
        top: '50%',
        transformOrigin: '60px 60px',
        width: '60px',
      },
      outmindLogoContainer: {
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        width: '40px',
      },
      outmindLogoSvg: {
        fill: '#b3b3ff',
      },
      startupLoaderContainer: {
        backgroundColor: 'white',
        borderRadius: props?.inSpotlight ? '4px' : undefined,
        height: props?.inSpotlight ? '100%' : '100vh',
        position: 'relative',
        width: '100%',
      },
      verticalCenteredBox: {
        '&:after': {
          content: '',
          display: 'inline-block',
          height: '100%',
          marginRight: '-0.25em',
          verticalAlign: 'middle',
        },
        height: '100%',
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
      },
      verticalCenteredBoxContent: {
        '-moz-box-sizing': 'border-box',
        '-webkit-box-sizing': 'border-box',
        boxSizing: 'border-box',
        display: 'inline-block',
        fontSize: 0,
        textAlign: 'left',
        verticalAlign: 'middle',
      },
    },
    { index: 1 },
  )(props);

interface StartUpLoaderStylesProps {
  inSpotlight?: boolean;
}
