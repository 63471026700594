import { LabelInterface, LabelTypes } from '@outmind/types';
import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { ApiQuery, useApiRouteMaker } from '../useApi';
import { Label } from './label';

/**
 * Hook used for creating a new label
 */
export const useCreateLabel = (): UseMutationResult<
  void,
  never,
  Params$CreateLabelPayload,
  Record<string, Label>
> => {
  const queryClient = useQueryClient();

  const makeRoute = useApiRouteMaker(ApiQuery.CREATE_LABEL);

  const createLabelMutation = useMutation<
    void,
    never,
    Params$CreateLabelPayload,
    Record<string, Label>
  >(
    async ({ label }) => {
      const route = makeRoute();

      await axios({
        data: label,
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
    },
    {
      onError: (_, __, allLabels = {}) => {
        // On error revert labels to previous value
        queryClient.setQueryData<Record<string, Label>>(ApiQuery.GET_LABELS, allLabels);
      },
      onMutate: async ({ label }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(ApiQuery.GET_LABELS);
        // Snapshot previous labels
        const allLabels =
          queryClient.getQueryData<Record<string, Label>>(ApiQuery.GET_LABELS) ?? {};
        if (label.type === LabelTypes.Bookmark) return allLabels;
        // Optimistically updates document labels by adding this new label
        queryClient.setQueryData<Record<string, Label>>(ApiQuery.GET_LABELS, (labels = {}) => ({
          ...labels,
          [label.id]: new Label(label),
        }));
        return allLabels;
      },
      onSuccess: () => {
        // Reload search results
        queryClient.invalidateQueries(ApiQuery.GET_LABELS);
      },
    },
  );

  return createLabelMutation;
};

interface Params$CreateLabelPayload {
  label: LabelInterface;
}
