import React from 'react';

import { Label } from '../../../../../hooks';
import { Actions, useDispatch } from '../../../../../store';
import { LabelSuggestion } from './LabelSuggestion';

export const LabelsSuggestion: React.FC<LabelsSuggestionProps> = ({ cursor, reset, labels }) => {
  const dispatch = useDispatch();

  const filterByLabel = (label: Label): void => {
    dispatch(Actions.addLabelFilter(label));
    reset();
  };

  if (!labels.length) return null;

  const labelChips = labels.map((label, index) => (
    <LabelSuggestion
      key={label.id}
      index={index}
      isSelected={cursor === index}
      label={label}
      onClick={filterByLabel}
    />
  ));

  return <div>{labelChips}</div>;
};

interface LabelsSuggestionProps {
  cursor: number;
  labels: Label[];
  reset: () => void;
}
