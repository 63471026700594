import { makeStyles } from '../../material';

const useStyles = makeStyles(
  {
    closeDialogButton: {
      color: 'rgba(0, 0, 0, 0.35);',
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  { index: 1 },
);

export { useStyles };
