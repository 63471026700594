// External modules
import { makeStyles } from '../../material';

export const useStyles = () =>
  makeStyles(
    {
      loginButton: {
        '&:hover': {
          cursor: 'pointer',
          opacity: 0.9,
        },
        alignItems: 'center',
        backgroundColor: '#fff',
        border: '1px solid transparent',
        borderRadius: '5px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 1px 0 rgba(0, 0, 0, .24)',
        color: 'rgba(0, 0, 0, .54)',
        cursor: 'pointer',
        display: 'inline-flex',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '14px',
        fontWeight: 500,
        height: '40px',
        justifyContent: 'start',
        padding: '0px 8px',
        width: '100%',
      },
      loginButtonContainer: {
        position: 'relative',
      },
      loginButtonDisabled: {
        opacity: 0.6,
      },
      logo: {
        background: 'white',
        marginRight: '24px',
      },
      progressContainer: {
        left: 'calc(50% - 12px)',
        position: 'absolute',
        top: '14px',
      },
    },
    { index: 1 },
  )();
