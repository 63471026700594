import { makeStyles } from '../../material';

const resultsContainerMarginTop = 20;

export const useStyles = makeStyles(
  {
    notSignedInButton: {
      borderRadius: '100px',
      height: '46px',
      textTransform: 'none',
    },
    notSignedInContainer: {
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
    },
    notSignedInSubContainer: {
      margin: '16px',
    },
    notSignedInSubTitle: {
      color: '#555',
      fontSize: '1rem',
    },
    notSignedInTitle: {
      color: '#333',
      fontSize: '1.5rem',
    },
    spotlightContainer: {
      backgroundColor: 'transparent',
      height: '100%',
      maxWidth: '100%',
      minWidth: '400px',
      padding: '24px',
      width: '100%',
    },
    spotlightResultsContainer: {
      borderRadius: '8px 8px 0 0',
      boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);',
      height: `calc(100% - ${105 + resultsContainerMarginTop}px)`,
      marginTop: `${resultsContainerMarginTop}px`,
    },
  },
  { index: 1 },
);
