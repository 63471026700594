/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();
  return makeStyles(
    {
      chipLabel: {
        alignItems: 'center',
        display: 'flex',
      },
      chipLabelHelperText: {
        color: theme.designSystem.colors.secondary.main,
        fontSize: '0.875rem',
      },
      chipLabelMainText: {
        color: theme.designSystem.colors.secondary.main,
        fontSize: '0.875rem',
        marginLeft: '8px',
      },
      filterChip: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.designSystem.colors.disabled.main}`,
        paddingLeft: '4px',
        paddingRight: '4px',
      },
      filterChipFocused: {
        backgroundColor: theme.designSystem.colors.background.main,
        border: `1px solid ${theme.designSystem.colors.secondary.main}`,
        paddingLeft: '4px',
        paddingRight: '4px',
      },
    },
    { index: 1 },
  )();
};
