/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DocumentIndicesExtended } from '@outmind/types';
import { action } from 'typesafe-actions';

import { SortByOption } from './types';

export enum ActionTypes {
  REFRESH_SEARCH = 'REFRESH_SEARCH',
  RESET_SEARCH = 'RESET_SEARCH',
  SET_CHOSEN_INDEX_TAB = 'SET_CHOSEN_INDEX_TAB',
  SET_CHOSEN_SORT_BY = 'SET_CHOSEN_SORT_BY',
  SET_SEARCH_QUERY = 'SET_SEARCH_QUERY',
  SET_SEARCH_USER_QUERY = 'SET_SEARCH_USER_QUERY',
}

export const Actions = {
  refreshSearch: () => action(ActionTypes.REFRESH_SEARCH),
  resetSearch: () => action(ActionTypes.RESET_SEARCH),
  setChosenIndexTab: (index: DocumentIndicesExtended) =>
    action(ActionTypes.SET_CHOSEN_INDEX_TAB, index),
  setChosenSortBy: (sortBy: SortByOption) => action(ActionTypes.SET_CHOSEN_SORT_BY, sortBy),
  setSearchQuery: (q: string) => action(ActionTypes.SET_SEARCH_QUERY, q),
  setSearchUserQuery: (q: string) => action(ActionTypes.SET_SEARCH_USER_QUERY, q),
};
