import { useTheme } from '../../../hooks';
import { makeStyles } from '../../../material';

const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      date: { cursor: 'pointer', fontSize: '14px' },
      dateContainer: {
        color: theme.designSystem.colors.secondary.main,
        display: 'flex',
        marginLeft: '24px',
        marginTop: '4px',
      },
      dateLabel: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '14px',
        marginRight: '12px',
        textAlign: 'right',
        width: '36px',
      },
      dropDownIcon: {
        color: theme.designSystem.colors.disabled.main,
        position: 'unset',
        width: '12px',
      },
      formControlElement: {
        width: '100%',
      },
      inputOutlined: {
        padding: 0,
      },
      menu: {
        background: '#FFFFFF',
        border: '1px solid #BFBFBF',
        borderRadius: '5px',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        padding: '0px',
      },
      menuList: {
        '&>li': {
          '&:hover': {
            backgroundColor: '#245ee526 !important',
          },
          padding: '4px 36px 4px 8px',
        },

        paddingBottom: '0px',
        paddingTop: '0px',
      },
      sectionTitle: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '14px',
        marginTop: '24px',
      },
      selectedItem: {
        '&>[id^="select-item-container"]>[id^="sort-item-icon-container"],p': {
          color: `${theme.designSystem.colors.active.main} !important`,
        },
        backgroundColor: 'unset !important',
      },
      selectElement: {
        '&:focus': {
          background: 'unset',
        },
        paddingLeft: '8px !important',
        paddingRight: '4px !important',
        width: 'fit-content',
      },
      selectItem: {
        color: theme.designSystem.colors.secondary.main,
        fontSize: '14px',
      },
      selectItemContainer: {
        alignItems: 'center',
        display: 'flex',
      },
      sortBy: {
        '&.Mui-focused': {
          ' &>.MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        },
        '&:hover': {
          '&>.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #BFBFBF !important',
          },
          '&>.MuiSelect-icon': {
            color: theme.designSystem.colors.secondary.main,
            position: 'absolute',
            top: '10px',
          },
          '&>.MuiSelect-select': { width: '100%' },
          '&>div>[id^="select-item-container"]>[id^="sort-item-icon-container"]': {
            color: `${theme.designSystem.colors.secondary.main} !important`,
          },
          '&>div>[id^="select-item-container"]>p': { maxWidth: 'calc(100% - 45px)' },
          color: 'rgb(0 0 0 / 70%)',
        },
        '&>.MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        color: 'rgb(0 0 0 / 54%)',
        cursor: 'pointer',
        height: '32px',
        marginLeft: '-4px',
        textAlign: 'left',
        width: 'calc(100% + 8px)',
      },
      sortItemIcon: {
        color: 'inherit',
      },
      sortItemIconContainer: {
        alignItems: 'center',
        color: theme.designSystem.colors.disabled.main,
        display: 'flex',
        width: '30px',
      },
      sortItemText: {
        color: theme.designSystem.colors.secondary.main,
        fontSize: '14px',
        marginTop: '-2px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    { index: 1 },
  )();
};

export { useStyles };
