import { ElasticFile, ElasticPerson, SearchSuggestionsResult } from '@outmind/types';
import React, { memo, useState } from 'react';

import { useTimeAgo, useTranslations } from '../../../hooks';
import { ListSubheader } from '../../../material';
import { useStyles } from './styles';
import { DefaultSuggestion, PersonSuggestion, QuerySuggestion } from './SuggestionTypes';

const SearchSuggestionNP: React.FC<SearchSuggestionProps> = ({
  index,
  isSelected,
  inSpotlight,
  onClick,
  suggestion,
  showHistorySubheader,
  showResultSubheader,
}) => {
  const {
    document: { action, document },
    highlights,
  } = suggestion;

  const { t } = useTranslations();

  const makeTimeAgo = useTimeAgo();

  const classes = useStyles();

  const [isSelectedInner, setIsSelectedInner] = useState(false);

  const mouseEnter = (): void => {
    setIsSelectedInner(true);
  };
  const mouseLeave = (): void => {
    setIsSelectedInner(false);
  };

  const baseSuggestionProps = {
    index,
    isSelected,
    isSelectedInner,
    mouseEnter,
    mouseLeave,
    onClick,
  };

  if (action.type === 'query') {
    const mainText = highlights?.autocomplete || action.value;
    return (
      <>
        {showHistorySubheader ? (
          <ListSubheader className={classes.suggestionsSubHeader}>
            {t('history_label')}
          </ListSubheader>
        ) : null}
        <QuerySuggestion {...{ ...baseSuggestionProps, mainText }} />
      </>
    );
  }

  if (action.type === 'filter:byPerson') {
    const person = document as ElasticPerson;
    const mainText = highlights?.autocomplete || suggestion.document.autocomplete;
    const subText = highlights?.autocompleteAlt || suggestion.document.autocompleteAlt || '';
    return (
      <>
        {showResultSubheader ? (
          <ListSubheader className={classes.suggestionsSubHeader}>
            {t('suggestions_label')}
          </ListSubheader>
        ) : null}
        <PersonSuggestion {...{ ...baseSuggestionProps, mainText, person, subText }} />
      </>
    );
  }

  const file = document as ElasticFile;
  const mainText = highlights?.autocomplete || file.title;
  const subText = file.by?.name || file.by?.email;

  const lastModifiedDate = file.lastModifiedDate
    ? t('edited') + makeTimeAgo(file.createdDate)
    : null;
  const createdDate = file.createdDate ? t('created') + makeTimeAgo(file.createdDate) : null;
  const displayDate = lastModifiedDate || createdDate;

  const suggestionProps = {
    ...baseSuggestionProps,
    displayDate,
    file,
    inSpotlight,
    mainText,
    subText,
  };

  if (action.type === 'filter:inFolder') {
    return null;
  }

  return (
    <>
      {showResultSubheader ? (
        <ListSubheader className={classes.suggestionsSubHeader}>
          {t('suggestions_label')}
        </ListSubheader>
      ) : null}
      <DefaultSuggestion {...suggestionProps} />
    </>
  );
};

interface SearchSuggestionProps {
  inSpotlight?: boolean;
  index: number;
  isSelected: boolean;
  onClick: () => void;
  showHistorySubheader?: boolean;
  showResultSubheader?: boolean;
  suggestion: SearchSuggestionsResult;
}

export const SearchSuggestion = memo(SearchSuggestionNP);
