import { SpellingSuggestion } from '@outmind/types';
import axios from 'axios';
import { useIsFetching, useQuery, UseQueryResult } from 'react-query';

import { useSelector } from '../../store';
import { ApiQuery, useApiRouteMaker } from '../useApi';

/**
 * Hook for retrieving the spelling suggestions
 */
export const useSpellingSuggestions = (
  shouldSuggestQuery: boolean,
): UseQueryResult<SpellingSuggestion> => {
  const userQ = useSelector((s) => s.search.userQ);

  const route = useApiRouteMaker(ApiQuery.SUGGEST_QUERY)();

  const spellingSuggestion = useQuery<SpellingSuggestion>(
    [ApiQuery.SUGGEST_QUERY, userQ],
    async () => {
      const result = await axios({
        method: route.method,
        params: { q: userQ },
        url: route.url,
        withCredentials: true,
      });
      return result.data;
    },
    {
      enabled: shouldSuggestQuery,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 1,
    },
  );

  return spellingSuggestion;
};

/**
 * hook that returns the number of the spelling suggestions queries that are loading or fetching in the background
 */
export const useSpellingSuggestionsIsFetching = (): number => {
  const userQ = useSelector((s) => s.search.userQ);
  return useIsFetching([ApiQuery.SUGGEST_QUERY, userQ]);
};
