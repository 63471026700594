import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    userAvatar: {
      height: '40px',
      width: '40px',
    },
    userAvatarBig: {
      height: '60px',
      width: '60px',
    },
  },
  { index: 1 },
);
