import React from 'react';

import { AddIcon, IconButton } from '../../../material';
import { useStyles } from './styles';

export const AddButton: React.FC<AddButtonProps> = ({ onClick }) => {
  const classes = useStyles();

  return (
    <IconButton
      classes={{ root: classes.iconButtonRoot }}
      color="inherit"
      onClick={onClick}
      size="small"
    >
      <AddIcon fontSize="inherit" />
    </IconButton>
  );
};

interface AddButtonProps {
  onClick: () => void;
}
