import React from 'react';

import { AddSourceDialogButton } from '../..';
import outmindGrayLogoSrc from '../../assets/outmind-logo-gray.png';
import { useTranslations } from '../../hooks';
import { Typography } from '../../material';
import { useStyles } from './styles';

export const NoDataBackground: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.backgroundImageContainer}>
        <img alt="SadFolder" className={classes.backgroundImage} src={outmindGrayLogoSrc} />
      </div>
      <Typography className={classes.textPrimary} color="textPrimary">
        {t('no_data_yet')}
      </Typography>
      <Typography className={classes.textSecondary} color="textSecondary">
        {t('no_data_yet_helper')}
      </Typography>
      <AddSourceDialogButton variant="outlined" />
    </div>
  );
};
