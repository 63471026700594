import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    addSeperatorContainer: { display: 'inline-flex', margin: '0 36px 0 36px' },
    dialogInnerContainer: { width: '450px' },
    firstKeySelect: {
      width: '200px',
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '32px',
      paddingBottom: '16px',
    },
    secondKeySelect: {
      width: '120px',
    },
    selectCommandContainer: { display: 'inline-flex' },
    selectContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  { index: 1 },
);
