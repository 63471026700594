import React from 'react';

import { useTranslations } from '../../hooks';
import { Checkbox, CheckBoxOutlinedIcon, FormControlLabel, PeopleAltIcon } from '../../material';
import { useStyles } from './styles';

export const ShareConnectorCheckBox: React.FC<ShareConnectorCheckBoxProps> = ({
  isShared,
  toggleIsShared,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();

  return (
    <div className={classes.shareConnectorContainer}>
      <FormControlLabel
        classes={{ label: classes.formControlLabel }}
        control={
          <Checkbox
            checked={isShared}
            checkedIcon={<CheckBoxOutlinedIcon />}
            classes={{ root: classes.checkBoxroot }}
            color="primary"
            name="checkedIsShared"
            onChange={toggleIsShared}
          />
        }
        label={
          <>
            {t('share_my_source')}
            <span className={classes.shareIconContainer}>
              <PeopleAltIcon className={classes.shareIcon} />
            </span>
          </>
        }
      />
    </div>
  );
};

export interface ShareConnectorCheckBoxProps {
  isShared: boolean;
  toggleIsShared: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
