import { makeStyles } from '../../../material';

export const useStyles = makeStyles(
  {
    container: {
      padding: '24px',
    },
    messageContent: {
      marginBottom: '10px',
      marginLeft: '20px',
    },
    messageHeader: {
      display: 'flex',
    },
    messageHeaderContent: {
      marginBottom: '5px',
      marginLeft: '10px',
    },
    messageThreadDate: {
      paddingLeft: '10px',
    },
    messageThreadHeader: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: '10px',
      paddingTop: '10px',
    },
    messageThreadHeaderContent: {
      display: 'flex',
      paddingLeft: '12px',
    },
    messageThreadLogo: {
      alignItems: 'center',
      display: 'flex',
    },

    singleMessageAvatar: {
      height: '26px',
      width: '26px',
    },
  },
  { index: 1 },
);
