import { makeStyles } from '../../../material';

export const useStyles = makeStyles(
  {
    circleContainer: {
      position: 'absolute',
    },
    circleHolder: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    container: {
      alignItems: 'center',
      display: 'flex',
      position: 'fixed',
      right: '100px',
    },
    outmindLogo: { position: 'absolute', width: '70px' },
    outmindLogoContainer: { alignItems: 'center', display: 'flex', justifyContent: 'center' },
  },
  { index: 1 },
);
