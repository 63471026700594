import { Source } from '@outmind/types';
import React from 'react';

import { SourceLogo } from '../SourceLogo';
import { useStyles } from './styles';

export const CompactSourcesIcons: React.FC<CompactSourcesIconsProps> = ({ size, sources }) => {
  const classes = useStyles({ sourceLogoSize: size ?? 24 });

  return (
    <div className={classes.multiSourceLogoContainer}>
      {sources.map((_source) => (
        <div className={classes.sourceLogoContainer}>
          <SourceLogo className={classes.sourceLogoImage} source={_source} />
        </div>
      ))}
    </div>
  );
};

interface CompactSourcesIconsProps {
  size?: number;
  sources: Source[];
}
