import React from 'react';

import { useTranslations } from '../../../hooks';
import { Typography } from '../../../material';
import { useSelector } from '../../../store';
import { ExpandContent } from '../../ExpandContent';
import { useStyles } from '../styles';
import { CategoryFilter } from './CategoryFilter';

export const CategoryFilters: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  const categories = useSelector((s) => s.filters.categories);

  const categoryFilters = categories.map((filter) => (
    <CategoryFilter key={filter.category} filter={filter} />
  ));

  return categories.length === 0 ? null : (
    <div className={classes.sectionContainer}>
      <Typography className={classes.sectionTitle}>{t('formats')}</Typography>
      <ExpandContent itemsToShowCount={4}>{categoryFilters}</ExpandContent>
    </div>
  );
};
