import React from 'react';

import { useConnectors, useTranslations } from '../../hooks';
import { DialogContentText, List, ListItem, ListItemText } from '../../material';
import { AddSourceDialogButton } from '../AddSource';
import { CustomDialog } from '../CustomDialog';
import { ManageSourceElement } from './ManageSourceElement';
import { useStyles } from './styles';

export const ManageSourcesDialog: React.FC<ManageSourcesDialogProps> = ({ close, open }) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const { data: connectors } = useConnectors();

  const sourceComponents =
    connectors && connectors.length > 0 ? (
      connectors.map((connector) => (
        <ManageSourceElement key={connector.id} connector={connector} />
      ))
    ) : (
      <ListItem>
        <ListItemText
          className={classes.noSourceHelper}
          primary={t('no_source_connected')}
          secondary={t('no_source_connected_helper')}
        />
      </ListItem>
    );

  const dialogTitle = t('source_management');
  const dialogContextText = t('source_management_helper');

  return (
    <CustomDialog onClose={close} open={open} title={dialogTitle}>
      <DialogContentText>{dialogContextText}</DialogContentText>
      <List className={classes.sourceList}>{sourceComponents}</List>
      <div className={classes.addSourceDialogButtonContainer}>
        <AddSourceDialogButton variant="outlined" />
      </div>
    </CustomDialog>
  );
};

interface ManageSourcesDialogProps {
  close: () => void;
  open: boolean;
}
