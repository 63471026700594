/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { colors, makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      addButton: {
        '&:hover': {
          border: `1px solid ${theme.designSystem.colors.aux.main}`,
        },
        border: '1px solid white',
        borderRadius: '2px',
        color: theme.designSystem.colors.aux.main,
        padding: '0px',
      },
      addButtonContainer: {
        display: 'flex',
        justifyContent: 'start',
      },
      addFirstTagButton: {
        '&::first-letter': {
          textTransform: 'uppercase',
        },
        color: theme.designSystem.colors.aux.main,
        fontWeight: 600,
        paddingLeft: '4px',
        textTransform: 'none',
      },
      addFirstTagButtonLabel: {
        justifyContent: 'start',
        paddingLeft: '4px',
      },
      addFirstTagButtonRoot: {
        '&:hover': {
          border: `1px solid ${theme.designSystem.colors.aux.main}`,
        },
        border: '1px solid white',
        borderRadius: '2px',
        color: theme.designSystem.colors.aux.main,
        width: 'calc(100% - 20px)',
      },
      addFirstTagContainer: {
        marginLeft: '20px',
      },
      deleteLabelDialogBorderBox: {
        border: `1px solid ${colors.orange[400]} !important`,
        borderRadius: '10px',
        marginTop: '16px !important',
        padding: '16px !important',
      },
      deleteLabelDialogBoxContainer: {
        padding: '0px !important',
      },
      deleteLabelDialogBoxTitle: {
        color: `${colors.orange[400]} !important`,
      },
      labelHeader: {
        color: 'inherit',
        fontSize: '14px',
      },
      labelSeparator: {
        background: '#EAEAEA',
        height: '1px',
        marginLeft: '-30px',
        width: 'calc(100% + 60px)',
      },
      labelsFilterContainer: {
        margin: '24px 0px 36px 0px',
        position: 'relative',
      },
      labelTreeContainer: {
        marginLeft: '-28px',
        width: 'calc(100% + 56px)',
      },
      titleContainer: {
        alignItems: 'center',
        color: theme.designSystem.colors.aux.main,
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    { index: 0 },
  )();
};
