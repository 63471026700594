import { ElasticFile } from '@outmind/types';
import { Person } from '@styled-icons/fluentui-system-regular';
import React from 'react';

import { ListItem, Typography } from '../../../../material';
import { SanitizedHtmlTypography, SourceLogo } from '../../..';
import { DocumentLogo } from './DocumentLogo';
import { useStyles } from './styles';

export const DefaultSuggestion: React.FC<DefaultSuggestionProps> = ({
  mainText,
  subText,
  file,
  displayDate,
  inSpotlight,
  isSelected,
  isSelectedInner,
  index,
  mouseEnter,
  mouseLeave,
  onClick,
}) => {
  const classes = useStyles();
  const ownerInfo = subText && (
    <>
      <Person className={classes.ownerIcon} size="16" title="person" />
      <Typography className={classes.ownerText}>{subText}</Typography>
    </>
  );
  return (
    <ListItem
      key={index}
      className={isSelected || isSelectedInner ? classes.querySelected : classes.queryItem}
      id={`document-suggestion-${index}`}
      onClick={onClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <DocumentLogo file={file} inSpotlight={inSpotlight} isFilled={isSelectedInner} size={20} />
      <SourceLogo className={classes.documentIcon} source={file.source} />

      <div className={classes.suggestionInfoContainer}>
        <div className={classes.suggestionTextContainer}>
          <SanitizedHtmlTypography className={classes.suggestionTextMain} html={mainText} />
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.timeTextContainer}>{displayDate}</div>
          <div className={classes.ownerTextContainer}>{ownerInfo}</div>
        </div>
      </div>
    </ListItem>
  );
};

interface DefaultSuggestionProps {
  displayDate: string | null;
  file: ElasticFile;
  inSpotlight?: boolean;
  index: number;
  isSelected: boolean;
  isSelectedInner: boolean;
  mainText: string;
  mouseEnter: () => void;
  mouseLeave: () => void;
  onClick: () => void;
  subText?: string;
}
