import { UserInterface } from '@outmind/types';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { ApiQuery, getRouteMaker } from '../../useApi';

/**
 * Retrieves the profile of the logged in user
 */
export const useUserProfile = (): UseQueryResult<UserInterface> => {
  const getUserProfileQuery = useQuery<UserInterface>(
    ApiQuery.GET_MY_PROFILE,
    async () => {
      const route = getRouteMaker(ApiQuery.GET_MY_PROFILE)();

      const result = await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
      return result.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 1,
      retryOnMount: false,
      staleTime: Infinity, // Never stale
    },
  );

  return getUserProfileQuery;
};
