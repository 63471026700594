import React from 'react';

import { useConnectors, useOrganisationSources, useTranslations } from '../../../hooks';
import { Typography } from '../../../material';
import { useSelector } from '../../../store';
import { AddSourceDialogButton } from '../..';
import { ManageSourcesButton } from '../../ManageSources';
import { ConnectorFilter } from './ConnectorFilter';
import { PreconfiguredConnector } from './PreconfiguredConnector';
import { useStyles } from './styles';

export const ConnectorFilters: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  const { data: connectors = [] } = useConnectors();

  const { data: organisationSources = { connectedSources: [], sourcesToBeConnected: [] } } =
    useOrganisationSources();

  const connectorFilters = useSelector((s) => s.filters.connectors);

  const connectorFilterComponents = connectors.map((connector) => {
    const connectorFilter = { ...connector, isActive: !!connectorFilters[connector.id] };
    return <ConnectorFilter key={connector.id} connector={connectorFilter} />;
  });

  const sourcesToBeConnected = organisationSources.sourcesToBeConnected.map((source) => (
    <PreconfiguredConnector key={source} source={source} />
  ));

  return (
    <>
      <div className={classes.accountSectionTitleContainer}>
        <Typography className={classes.accountSectionTitle}>{t('sources')}</Typography>
        <div className={classes.accountSectionManageSourcesButtonContainer}>
          <AddSourceDialogButton variant="icon" />
          <ManageSourcesButton size="small" />
        </div>
      </div>
      {connectorFilterComponents.length ? connectorFilterComponents : null}
      {sourcesToBeConnected}
      {!connectorFilterComponents.length ? (
        <div className={classes.noSourceConnectedContainer}>
          <Typography className={classes.noSourceConnected}>{t('no_source_connected')}</Typography>
          <div className={classes.addSourceButtonContainer}>
            <AddSourceDialogButton variant="text" />
          </div>
        </div>
      ) : null}
    </>
  );
};
