/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      checkBoxroot: {
        color: '#245EE5',
      },
      formControlLabel: {
        color: '#0000008a',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      infoIcon: {
        color: '#757575',
        flex: 'none',
        fontSize: '1.1rem',
        marginLeft: '4px',
        marginTop: '14px',
      },
      popper: {
        marginTop: '-1em',
      },
      syncDiscoveredContainer: {
        display: 'flex',
      },
      syncDiscoveredSubtitle: {
        flex: '0 1 auto',
        marginBottom: '10px',
        marginTop: '20px',
      },
      syncDiscoveredSwitch: {
        color: theme.palette.secondary.main,
        flex: 'none',
        marginBottom: 'auto',
        marginTop: '14px',
      },
      tooltip: {
        fontSize: '1rem',
      },
    },
    { index: 1 },
  )();
};
