import { makeStyles } from '../../material';

const mainContainerStyles = {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
};

export const useStyles = makeStyles(
  {
    actionButton: {
      borderRadius: '100px',
      textTransform: 'none',
    },
    backgroundImage: {
      width: '100%',
    },
    backgroundImageContainer: {
      maxWidth: '165px',
    },
    mainContainer: { ...mainContainerStyles, flexDirection: 'column' },
    messageContainer: {
      background: 'white',
      borderRadius: '10px',
      maxWidth: '300px',
      padding: '10px 20px',
    },
    noPreviewBackgroundImage: {
      display: 'block',
      margin: 'auto',
      width: '80%',
    },
    noPreviewButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '4px',
    },
    noPreviewMainContainer: {
      ...mainContainerStyles,
      backgroundColor: '#F5F5F6',
      flexDirection: 'column',
    },
    noPreviewtext: {
      fontSize: '1rem',
      fontWeight: 600,
      marginBottom: '8px',
      marginTop: '-24px',
      maxWidth: '300px',
      textAlign: 'center',
    },
    textPrimary: {
      fontSize: '1.25rem',
      margin: '16px 0 32px 0',
      maxWidth: '400px',
      textAlign: 'center',
    },
    textSecondary: {
      fontSize: '1rem',
      marginBottom: '32px',
      maxWidth: '400px',
      textAlign: 'center',
    },
  },
  { index: 1 },
);
