import { ApiQuery, Route, SDK } from '@outmind/sdk';
import { useCallback } from 'react';

import { useUserProfile } from '../authn';

const API_URL = process.env['REACT_APP_API_URL'];

if (!API_URL) throw new Error('REACT_APP_API_URL is not defined');

const sdk = new SDK(API_URL);

/**
 * Hook used to retrieve a function that takes the route params as input and returns a Route
 */
export const useApiRouteMaker = (
  query: ApiQuery,
): ((params?: Record<string, string | number>) => Route) => {
  const { data: user } = useUserProfile();

  return useCallback(
    (params?: Record<string, string | number>) =>
      sdk.getRouteMaker(query, user?.domainCode)(params),
    [query, user?.domainCode],
  );
};

export const { getRouteMaker } = sdk;
export { ApiQuery };
