import { useTheme } from '../../../hooks';
import { makeStyles } from '../../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      iconButtonRoot: {
        '&:hover': {
          border: `1px solid ${theme.designSystem.colors.aux.main}`,
          color: theme.designSystem.colors.secondary.main,
        },
        border: '1px solid white',
        borderRadius: '2px',
        color: theme.designSystem.colors.disabled.main,
      },
    },
    { index: 1 },
  )();
};
