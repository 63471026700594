import { ClientConnectorRoot, Source } from '@outmind/types';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { ApiQuery, useApiRouteMaker } from '../../useApi';

/**
 * Retrieves the roots of the defined connector
 */
export const useConnectorRoots = (
  connectorId: string,
  source: Source,
  enabled = true,
): UseQueryResult<ClientConnectorRoot[]> => {
  const makeRoute = useApiRouteMaker(ApiQuery.GET_CONNECTOR_ROOTS);

  const getConnectorRootsQuery = useQuery<ClientConnectorRoot[]>(
    [ApiQuery.GET_CONNECTOR_ROOTS, connectorId],
    async () => {
      const route = makeRoute({ connectorId, source });

      const result = await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });

      return result.data;
    },
    { enabled },
  );

  return getConnectorRootsQuery;
};
