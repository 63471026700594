import React from 'react';

import { useTranslations } from '../../hooks';
import { Typography } from '../../material';
import { useStyles } from './styles';

/**
 * Displays the conversation in which the Message was exchanged with
 * its "to" property
 */
export const DocumentConversation: React.FC<DocumentConversationProps> = ({ name }) => {
  const classes = useStyles();

  const { t } = useTranslations();

  return <Typography className={classes.documentConversation}>{t('in_uf', { name })}</Typography>;
};

interface DocumentConversationProps {
  name: string;
}
