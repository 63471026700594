/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../material';

export const useStyles = () =>
  makeStyles(
    {
      iframe: {
        height: '100%',
        width: '100%',
      },
      iframeContainer: { height: 'calc(100vh - 65px)' },
    },
    { index: 1 },
  )();
