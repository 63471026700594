import { FeatureFlags, FeatureFlagsConfig } from '@outmind/types';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { useUserProfile } from '../authn';
import { ApiQuery, useApiRouteMaker } from '../useApi';

export const initialFeatureFlags = Object.keys(FeatureFlags).reduce(
  (acc, key) => ({ ...acc, [key]: { enabled: false, showAsDisabled: true } }),
  {} as FeatureFlagsConfig,
);

/**
 * Hook used to know which feature flags are activated in this environment
 */
export const useFeatureFlags = (): UseQueryResult<FeatureFlagsConfig> => {
  const { data: user } = useUserProfile();
  const makeRoute = useApiRouteMaker(ApiQuery.GET_FEATURE_FLAGS);

  const getFeatureFlagsQuery = useQuery<FeatureFlagsConfig>(
    [ApiQuery.GET_FEATURE_FLAGS],
    async () => {
      const route = makeRoute({ userId: user!.id });

      const result = await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });

      return { ...initialFeatureFlags, ...result.data };
    },
    {
      enabled: !!user,
      placeholderData: initialFeatureFlags,
    },
  );

  return getFeatureFlagsQuery;
};
