import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

/**
 * Hook used for setting spotlight shortcut
 */
export const useSetSpotlightShortcut = (): UseMutationResult<unknown, unknown, string, unknown> => {
  const queryClient = useQueryClient();

  const setShortcutMutation = useMutation<unknown, unknown, string>(
    async (shortcut) => {
      window.electron?.setSpotlightShortcut(shortcut);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('GET_SPOTLIGHT_SHORTCUT');
      },
    },
  );

  return setShortcutMutation;
};
