/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = (props: LabelTahStyleProps) =>
  makeStyles(
    {
      chipRoot: {
        margin: '4px 20px 4px 20px',
      },
      colorPrimary: {
        backgroundColor: props.color,
        borderColor: props.color,
        color: '#FFFFFF',
        fontWeight: 600,
      },
    },
    { index: 1 },
  )(props);

interface LabelTahStyleProps {
  color: string;
}
