import {
  DocumentType,
  FileCategory,
  SearchFilesResult,
  SearchMailsResult,
  SearchMessagesResult,
  SearchPagesResult,
} from '@outmind/types';
import React from 'react';

import { Card } from '../../../material';
import { useSelector } from '../../../store';
import { NoPreviewFoundBackground } from '../../DocumentsListBackgrounds';
import { useStyles } from '../styles';
import { FilePreview } from './FilePreview';
import { MailPreview } from './MailPreview';
import { MessagePreview } from './MessagePreview';
import { PagePreview } from './PagePreview';

export const ContentPreview: React.FC<ContentPreviewProps> = ({ documentType }) => {
  const classes = useStyles();

  const previewInfos = useSelector((s) => s.previews.infos);

  if (!previewInfos) return <NoPreviewFoundBackground />;

  switch (documentType) {
    case DocumentType.Message: {
      return (
        <div className={classes.previewContainer}>
          <MessagePreview
            accessLink={(previewInfos as SearchMessagesResult).document.accessLink}
            documentId={(previewInfos as SearchMessagesResult).document.id}
          />
        </div>
      );
    }
    case DocumentType.Mail: {
      const { document } = previewInfos as SearchMailsResult;
      if (document.metas?.archive) {
        return <NoPreviewFoundBackground accessLink={document.accessLink} />;
      }
      return (
        <div className={classes.mailPreviewContainer}>
          <Card>
            <MailPreview accessLink={document.accessLink} documentId={document.id} />
          </Card>
        </div>
      );
    }
    case DocumentType.File: {
      const { document } = previewInfos as SearchFilesResult;
      if (document.metas?.archive || document.category === FileCategory.MailArchive) {
        return <NoPreviewFoundBackground accessLink={document.accessLink} />;
      }
      return <FilePreview accessLink={document.accessLink} documentId={document.id} />;
    }
    case DocumentType.Page: {
      const {
        document: {
          accessLink,
          pageIndex,
          slideIndex,
          thumbnail,
          thumbnailUrl,
          parentPresentation,
          parentFile,
        },
      } = previewInfos as unknown as SearchPagesResult;
      return (
        <PagePreview
          accessLink={accessLink}
          pageIndex={pageIndex ?? slideIndex}
          thumbnail={thumbnailUrl ?? thumbnail}
          totalPagesNumber={parentFile?.totalPagesNumber ?? parentPresentation?.totalSlidesNumber}
        />
      );
    }
    default: {
      return <NoPreviewFoundBackground />;
    }
  }
};

interface ContentPreviewProps {
  documentType: DocumentType;
}
