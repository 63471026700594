import { Tag as TagFilled } from '@styled-icons/fluentui-system-filled/Tag';
import { Tag } from '@styled-icons/fluentui-system-regular/Tag';
import classnames from 'classnames';
import React, { useState } from 'react';

import { Label } from '../../../hooks';
import { Tooltip, TreeItem, Typography } from '../../../material';
import { MoreOptionsButton } from './MoreOptionsButton';
import { useStyles } from './styles';

export const LabelItem: React.FC<LabelItemProps> = ({
  activeLabelId,
  handleEdit,
  handleDelete,
  label,
  children,
  createMode,
  onClickHandler,
}) => {
  const classes = useStyles();
  const { name, color, id, fullName } = label;

  const [isHovered, setIsHovered] = useState(false);
  const [isMoreOptionsMenuOpened, setIsMoreOptionsMenuOpened] = useState(false);
  const treeItemLabelClasses = classnames(classes.label);
  const treeItemRootClasses = classnames(classes.root, classes.ignoreSelected);
  const hoverBackgroundClasses = classnames(classes.hoverBackground, {
    [classes.hover]: isHovered && activeLabelId !== label.id,
    [classes.selected]: activeLabelId === label.id,
  });
  const hovericonContainerClasses = classnames(classes.iconContainer, {
    [classes.iconContainerHover]: isHovered || activeLabelId === label.id,
  });

  return (
    <TreeItem
      classes={{
        content: classes.content,
        iconContainer: hovericonContainerClasses,
        label: treeItemLabelClasses,
        root: treeItemRootClasses,
      }}
      label={
        <Tooltip
          aria-label="Tag"
          arrow
          open={isHovered && !isMoreOptionsMenuOpened}
          placement="right"
          title={fullName}
        >
          <div
            className={classes.labelRoot}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className={hoverBackgroundClasses} />
            <div
              aria-hidden="true"
              className={classes.clickHolder}
              onClick={() => onClickHandler(label)}
            >
              <div className={classes.labelPresentationContainer}>
                <div className={classes.labelIconContainer}>
                  {isHovered ? (
                    <TagFilled color={color} size={18} />
                  ) : (
                    <Tag color={color} size={18} />
                  )}
                </div>
                <div className={classes.labelTextContainer}>
                  <Typography className={classes.labelText}>{name}</Typography>
                </div>
              </div>
            </div>
            <div className={classes.moreOptionButtonContainer}>
              <MoreOptionsButton
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                label={label}
                setIsMoreOptionsMenuOpened={setIsMoreOptionsMenuOpened}
                show={isHovered && !createMode}
              />
            </div>
          </div>
        </Tooltip>
      }
      nodeId={id}
    >
      {children}
    </TreeItem>
  );
};

interface LabelItemProps {
  activeLabelId?: string;
  children?: JSX.Element[];
  createMode?: boolean;
  handleDelete?: (label: Label) => void;
  handleEdit?: (label: Label) => void;
  label: Label;
  onClickHandler: (label: Label) => void;
}
