import { Checkmark } from '@styled-icons/fluentui-system-filled/Checkmark';
import { CalendarLtr } from '@styled-icons/fluentui-system-regular/CalendarLtr';
import React from 'react';

import { useStyles } from './styles';

/**
 * DateIcon render the sort by associated icon
 */
export const DateIcon: React.FC<DateIconProps> = ({
  chosenSortBy,
  currentSortBy,
  isSelectOpen,
}) => {
  const classes = useStyles();

  if (chosenSortBy === currentSortBy && isSelectOpen) {
    return <Checkmark className={classes.sortItemIcon} size={16} />;
  }
  if (!isSelectOpen) {
    return <CalendarLtr className={classes.sortItemIcon} size={16} />;
  }
  return null;
};

interface DateIconProps {
  chosenSortBy: string;
  currentSortBy: string;
  isSelectOpen: boolean;
}
