import { Connector, ConnectorCredentialsStatus } from '@outmind/types';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { ApiQuery, useApiRouteMaker } from '../../useApi';

/**
 * Retrieves the connectors of the authenticated user
 */
export const useConnectors = (): UseQueryResult<ConnectorWithCredentialsStatus[]> => {
  const route = useApiRouteMaker(ApiQuery.GET_MY_CONNECTORS)();

  const getConnectorsQuery = useQuery<ConnectorWithCredentialsStatus[]>(
    ApiQuery.GET_MY_CONNECTORS,
    async () => {
      const result = await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
      return result.data;
    },
  );

  return getConnectorsQuery;
};

export interface ConnectorWithCredentialsStatus extends Connector {
  status?: ConnectorCredentialsStatus;
}
