import axios from 'axios';
import * as queryString from 'query-string';
import { focusManager, useQuery, UseQueryResult } from 'react-query';
import useReactRouter from 'use-react-router';

import { ApiQuery, useApiRouteMaker } from '../../useApi';
import { useLoggedIn } from '../useLoggedIn';

/**
 * Hook for sending an uuid to the web app from desktop app to save the sign in token in the DB
 */
export const useDesktopSignIn = (): UseQueryResult<void> => {
  const { location } = useReactRouter();
  const search = queryString.parse(location.search);
  const uuid: string = search['uuid'] as string;

  const makeRoute = useApiRouteMaker(ApiQuery.DESKTOP_SIGNIN);

  const desktopSignInsQuery = useQuery<void>(
    [ApiQuery.DESKTOP_SIGNIN, uuid],
    async () => {
      const route = makeRoute({ uuid });

      await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });
    },
    {
      enabled: !!uuid,
    },
  );

  return desktopSignInsQuery;
};

/**
 * Hook used to retrieve the stored sign in token from the db then set it in the desktop app
 */
export const useSetDesktopSignInToken = (): UseQueryResult<void> => {
  const uuid = window.electron?.getSignInUUID();

  const isLoggedIn = useLoggedIn();

  const makeRoute = useApiRouteMaker(ApiQuery.GET_DESKTOP_SIGNIN_TOKEN);

  const desktopSignInsQuery = useQuery<void>(
    [ApiQuery.DESKTOP_SIGNIN, uuid],
    async () => {
      const route = makeRoute();

      await axios({
        method: route.method,
        params: {
          uuid,
        },
        url: route.url,
        withCredentials: true,
      });
    },
    {
      cacheTime: 0,
      enabled: !!uuid && !isLoggedIn,
      onSettled: () => {
        focusManager.setFocused(undefined);
      },
      onSuccess: () => {
        if (uuid) window.electron?.resetSignInUUID();
        window.electron?.showMainWindow();
      },
      refetchInterval: 3000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
    },
  );

  return desktopSignInsQuery;
};
