import { Connector, SingleRootConnectors, sources } from '@outmind/types';
import React, { memo, useState } from 'react';
import useRouter from 'use-react-router';

import { useFeatureFlags, useStartSync, useTranslations, useUpdateConnector } from '../../../hooks';
import {
  Button,
  CircularProgress,
  DialogContentText,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '../../../material';
import { Actions, useDispatch } from '../../../store';
import { CustomDialog } from '../../CustomDialog';
import { SelectRoots } from '../../RootsSelection/SelectRoots';
import { ShareConnectorCheckBox } from '../../ShareConnectorCheckBox';
import { SourceLogo } from '../../SourceLogo';
import { SyncDiscoveredRootsCheckBox } from '../../SyncDiscoveredRootsCheckBox';
import { useStyles } from '../styles';
import { CancelConfigureSources } from './CancelConfigureSources';

const ConfigureSourcesDialogNP: React.FC<ConfigureSourcesDialogProps> = ({ connectors }) => {
  const classes = useStyles({ shouldDisplayRootsFullHeight: true });

  const { history } = useRouter();

  const { t } = useTranslations();

  const dispatch = useDispatch();

  const { data: flags } = useFeatureFlags();

  const [dialogIsOpen, setDialogIsOpen] = useState(true);
  const [startSyncButtonIsLoading, setStartSyncButtonIsLoading] = useState(false);
  const [roots, setRoots] = useState(false);
  const [syncDiscoveredRoots, setSyncDiscoveredRoots] = useState(true);
  const [rootsToSyncIds, setRootsToSyncIds] = useState<Record<string, boolean>>({});
  const [showCancelConfigureSourceDialog, setShowCancelConfigureSourceDialog] = useState(false);
  const [currentConnectorIndex, setCurrentConnectorIndex] = useState(0);
  const [isShared, setIsShared] = useState(false);

  const currentConnector = connectors[currentConnectorIndex];
  const { id: connectorId, source } = currentConnector;

  const [connectorName, setConnectorName] = useState(sources[source].name);

  const sourceInSingleRootConnector = SingleRootConnectors.includes(source);

  const { mutateAsync: updateConnector } = useUpdateConnector();

  const { mutateAsync: startSync } = useStartSync();

  const onClick = async (): Promise<void> => {
    setStartSyncButtonIsLoading(true);
    await updateConnector({
      connectorId,
      isShared,
      name: connectorName,
      rootsToSyncIds,
      source,
      syncDiscoveredRoots,
    });
    await startSync(
      { connectorId, source },
      {
        onError: () => {
          setStartSyncButtonIsLoading(false);
          dispatch(Actions.notifyStartSyncFailed());
        },
        onSuccess: () => {
          setStartSyncButtonIsLoading(false);
          setIsShared(false);
          if (currentConnectorIndex === connectors.length - 1) {
            setDialogIsOpen(false);
            dispatch(Actions.showPreconfiguredSourcesDialog(true));
            history.push('/');
          } else {
            setConnectorName(sources[connectors[currentConnectorIndex + 1].source].name);
            setCurrentConnectorIndex((index) => index + 1);
          }
        },
      },
    );
  };

  const toggleIsShared = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIsShared(event.target.checked);
  };

  return (
    <CustomDialog
      classes={{ paper: classes.singleDialog }}
      classNames={{ content: classes.dialogContent }}
      onClose={() => setShowCancelConfigureSourceDialog(true)}
      open={dialogIsOpen}
      scroll="body"
      title={t('configure_new_source_plural')}
    >
      <Stepper activeStep={currentConnectorIndex} alternativeLabel className={classes.stepper}>
        {connectors.map((connector) => (
          <Step key={connector.id}>
            <StepLabel classes={{ label: classes.stepperStepLabel }}>
              {sources[connector.source]?.name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <DialogContentText>{t('name_connector')}</DialogContentText>
      <div className={classes.connectorHeader}>
        <div className={classes.connectorLogoContainer}>
          <SourceLogo className={classes.connectorLogo} source={source} unknownSourceTooltip />
        </div>
        <TextField
          className={classes.connectorNameInput}
          onChange={(e) => setConnectorName(e.target.value)}
          placeholder={t('connector_name')}
          value={connectorName}
          variant="outlined"
        />
      </div>
      <DialogContentText className={classes.dialogSubtitle}>
        {t('choose_synced_items')}
      </DialogContentText>
      <div className={classes.selectRootsContainer}>
        <SelectRoots
          connectorId={currentConnector.id}
          setRoots={setRoots}
          setSelectedRootsIds={setRootsToSyncIds}
          source={currentConnector.source}
        />
      </div>
      <div className={classes.checkBoxesContainer}>
        {!sourceInSingleRootConnector && (
          <SyncDiscoveredRootsCheckBox
            setSyncDiscoveredRoots={setSyncDiscoveredRoots}
            sourceName={source}
            syncDiscoveredRoots={syncDiscoveredRoots as boolean}
          />
        )}
        {flags?.withSharedConnectors.enabled ? (
          <ShareConnectorCheckBox isShared={isShared} toggleIsShared={toggleIsShared} />
        ) : null}
      </div>
      <div className={classes.startSyncButtonContainer}>
        <Button
          className={classes.startSyncButton}
          color="primary"
          disabled={!connectorName || roots || startSyncButtonIsLoading}
          onClick={onClick}
          size="large"
          variant="outlined"
        >
          {t('start_synchronization')}
          {startSyncButtonIsLoading ? (
            <CircularProgress className={classes.startSyncButtonLoader} size={20} />
          ) : null}
        </Button>
      </div>
      <CancelConfigureSources
        connectors={connectors}
        onClose={() => setShowCancelConfigureSourceDialog(false)}
        onDelete={() => {
          setDialogIsOpen(false);
          history.push('/');
        }}
        showReallyDeleteDialog={showCancelConfigureSourceDialog}
      />
    </CustomDialog>
  );
};

export const ConfigureSourcesDialog = memo(ConfigureSourcesDialogNP);

interface ConfigureSourcesDialogProps {
  connectors: Pick<Connector, 'id' | 'source'>[];
}
