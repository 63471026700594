import React from 'react';

import { useTranslations } from '../../../../hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '../../../../material';

export const CancelConfigureLocalConnector: React.FC<CancelConfigureLocalConnectorProps> = ({
  onClose,
  onDelete,
  open,
}) => {
  const { t } = useTranslations();

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={() => onClose()}
      open={open}
    >
      <DialogTitle id="alert-dialog-title">{t('cancel_configuration')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('cancel_configuration_helper')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={() => onClose()}>
          {t('continue_configuration')}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onClose();
            onDelete();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface CancelConfigureLocalConnectorProps {
  onClose: () => void;
  onDelete: () => void;
  open: boolean;
}
