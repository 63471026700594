/**
 * Returns the path of the direct parent of a file
 */
export const getParentPath = (
  path: string,
  parentName: string,
  fileName: string,
): string | undefined => {
  const parentNameEscapedCharacters = escapeString(parentName);
  const fileNameWithEscapedCharacters = escapeString(fileName);
  const reg = new RegExp(
    `^(.*)${parentNameEscapedCharacters}(/|\\\\){1,3}${fileNameWithEscapedCharacters}$`,
  );
  const match = path.match(reg);
  if (match) {
    return `${match[1]}${parentName}`;
  }
  return undefined;
};

const escapeString = (str: string): string => str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
