import {
  Actions as DesktopAppDialogActions,
  ActionTypes as DesktopAppDialogActionTypes,
} from './dekstop-app-dialog/actions';
import { Actions as FilterActions, ActionTypes as FilterActionTypes } from './filters/actions';
import {
  Actions as StatusActions,
  ActionTypes as StatusActionTypes,
} from './notifications/actions';
import {
  Actions as PreconfiguredSourcesActions,
  ActionTypes as PreconfiguredSourcesTypes,
} from './preconfigured-sources/actions';
import { Actions as PreviewsActions, ActionTypes as PreviewsActionTypes } from './previews/actions';
import { Actions as SearchActions, ActionTypes as SearchActionTypes } from './search/actions';
import { Actions as SupportActions, ActionTypes as SupportActionTypes } from './support/actions';
import { Actions as UserActions, ActionTypes as UserActionTypes } from './user/actions';

export const ActionTypes = {
  ...FilterActionTypes,
  ...PreviewsActionTypes,
  ...PreconfiguredSourcesTypes,
  ...DesktopAppDialogActionTypes,
  ...SearchActionTypes,
  ...StatusActionTypes,
  ...SupportActionTypes,
  ...UserActionTypes,
};

export const Actions = {
  ...FilterActions,
  ...PreviewsActions,
  ...PreconfiguredSourcesActions,
  ...DesktopAppDialogActions,
  ...SearchActions,
  ...StatusActions,
  ...SupportActions,
  ...UserActions,
};
