import { MoreVertical } from '@styled-icons/fluentui-system-regular/MoreVertical';
import React, { memo, useState } from 'react';

import { Label, useTranslations } from '../../../../hooks';
import {
  DeleteIcon,
  EditIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '../../../../material';
import { LabelDialog } from '../../LabelDialog';
import { useStyles } from './styles';

const MoreOptionsButtonNP: React.FC<MoreOptionsButtonProps> = ({
  handleEdit,
  handleDelete,
  label,
  setIsMoreOptionsMenuOpened,
  show,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslations();

  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsMoreOptionsMenuOpened(true);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setIsMoreOptionsMenuOpened(false);
  };

  const handleActionClick = (
    event: React.MouseEvent<HTMLLIElement>,
    handleFunction?: (label: Label) => void,
  ): void => {
    event.stopPropagation();
    handleFunction?.(label);
    handleClose();
  };

  return (
    <>
      <LabelDialog labelToEdit={label} onClose={() => setOpen(false)} open={open} />
      <div
        className={classes.iconButtonContainer}
        style={{ visibility: show ? 'visible' : 'hidden' }}
      >
        <IconButton
          aria-controls="long-menu"
          aria-haspopup="true"
          aria-label="more"
          classes={{ root: classes.moreOptionButton }}
          onClick={handleClick}
        >
          <MoreVertical size={16} />
        </IconButton>
        {anchorEl ? (
          <Menu
            anchorEl={anchorEl}
            id="simple-menu"
            keepMounted
            onClose={handleClose}
            open={Boolean(anchorEl)}
          >
            <MenuItem onClick={(e) => handleActionClick(e, handleEdit)}>
              <ListItemIcon classes={{ root: classes.itemIcon }}>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={t('edit_tag')} />
            </MenuItem>
            <MenuItem onClick={(e) => handleActionClick(e, handleDelete)}>
              <ListItemIcon classes={{ root: classes.itemIcon }}>
                <DeleteIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={t('delete')} primaryTypographyProps={{ color: 'secondary' }} />
            </MenuItem>
          </Menu>
        ) : null}
      </div>
    </>
  );
};

export const MoreOptionsButton = memo(MoreOptionsButtonNP);

interface MoreOptionsButtonProps {
  handleDelete?: (label: Label) => void;
  handleEdit?: (label: Label) => void;
  label: Label;
  setIsMoreOptionsMenuOpened: (isMoreOptionsMenuOpened: boolean) => void;
  show: boolean;
}
