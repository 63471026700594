import React from 'react';

import { Card, CardContent, CircularProgress, Typography } from '../../material';
import { Background, OutmindLogo } from '..';
import { ActionButton } from './ActionButton';
import { useStyles } from './styles';

export const FullscreenLoader: React.FC<FullscreenLoaderProps> = ({
  actionButtonHelperText,
  helperText,
  helperTextSub,
  actionWhileLoading,
  onButtonClick,
  status,
}) => {
  const classes = useStyles();

  const circularProgress = (
    <>
      <CircularProgress
        className={classes.bottom}
        size={50}
        thickness={4}
        value={100}
        variant="determinate"
      />
      <CircularProgress
        classes={{
          circle: classes.circle,
        }}
        className={classes.top}
        disableShrink
        size={50}
        thickness={4}
        variant="indeterminate"
      />
    </>
  );

  return (
    <Background>
      <div className={classes.centered}>
        <Card className={classes.card} elevation={0}>
          <CardContent className={classes.cardContent}>
            <div className={classes.elementContainer}>
              <OutmindLogo large />
            </div>
            <div className={classes.elementContainer}>
              <Typography className={classes.helperText} variant="h5">
                {helperText}
              </Typography>
              <div className={classes.helperSubTextContainer}>
                {helperTextSub ? (
                  <Typography className={classes.helperSubText} variant="body1">
                    {helperTextSub}
                  </Typography>
                ) : null}
                {actionWhileLoading && status !== 'success' ? (
                  <ActionButton action={onButtonClick} helperText={actionButtonHelperText} />
                ) : null}
              </div>
            </div>
            <div className={classes.elementContainer}>
              <div className={classes.statusContainer}>
                {status === 'success' ? (
                  <ActionButton action={onButtonClick} helperText={actionButtonHelperText} />
                ) : (
                  !actionWhileLoading && circularProgress
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </Background>
  );
};

interface FullscreenLoaderProps {
  actionButtonHelperText: string;
  actionWhileLoading?: boolean;
  helperText: string;
  helperTextSub?: string;
  onButtonClick: () => void;
  status: 'loading' | 'success';
}
