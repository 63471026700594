import React from 'react';

/**
 * Outmind logo as an SVG React component
 * @param props The `SVGProps` passed to the `<svg>` element
 */
export const OutmindLogoSvg: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    id="Calque_1"
    version="1.1"
    viewBox="0 0 220 231"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
    {...props}
  >
    <g>
      <path
        d="M139.9,0c8,2.1,16.2,3.6,23.9,7c36.3,15.7,59,54.4,55.9,95.5c-3.2,42.6-34.6,78.4-75.1,85.8c-49,8.9-95.8-25.3-104.9-76.5
        c-8.9-50.4,23.2-100,71.2-110.1c2.5-0.5,5-1.2,7.5-1.8C125.6,0,132.8,0,139.9,0z M176,94.9c0-27.3-20.9-49.4-46.7-49.6
        c-26-0.2-47.7,22.7-47.6,49.9c0.2,27,21.5,49,47.5,49C155.3,144.2,176,122.4,176,94.9z"
      />
      <path
        d="M0,202.8c2.6-8.4,9.2-13.6,14.4-19.9c3.7-4.5,7.9-8.7,12-12.9c8.4-8.4,21.8-8.4,29.9-0.1c8.7,8.9,9.3,22.3,1.2,31.6
        c-6.6,7.5-13.3,14.9-20.1,22.1c-11.5,12.1-28.9,8.7-35.8-6.8c-0.5-1.1-1-2.1-1.5-3.2C0,210.1,0,206.5,0,202.8z"
      />
    </g>
  </svg>
);
