import { RefObject, useLayoutEffect, useRef, useState } from 'react';

/**
 * Hooks used to measure DOM nodes
 */
export const useDimensions = <T extends HTMLElement = HTMLDivElement>(): [
  RefObject<T>,
  DOMRect | null,
] => {
  const [dimensions, setDimensions] = useState<DOMRect | null>(null);

  const ref = useRef<T>(null);
  const node = ref.current;

  useLayoutEffect(() => {
    if (node) {
      const measure = (): number =>
        window.requestAnimationFrame(() => setDimensions(node.getBoundingClientRect()));

      measure();

      window.addEventListener('resize', measure);

      return () => {
        window.removeEventListener('resize', measure);
      };
    }

    return undefined;
  }, [node]);

  return [ref, dimensions];
};
