import { makeStyles } from '../../material';

const listItemBaseStyle = { color: '#707070', fontSize: '1.1rem' };

export const useStyles = makeStyles(
  {
    bookmarkEmptyIcon: {
      color: '#B4B4B4',
    },
    bookmarkIcon: {
      color: '#F9CB6A',
    },
    bookmarkIconButton: {
      marginLeft: '6px',
      padding: '2px',
    },
    favoriteIcon: {
      marginLeft: '-4px',
    },
    favoriteText: {
      ...listItemBaseStyle,
      marginLeft: '-2px',
    },
    listItemIcon: {
      minWidth: 'unset',
      paddingRight: '8px',
      position: 'relative',
    },
    menuItem: {
      padding: '2px 16px',
    },
  },
  { index: 1 },
);
