import React from 'react';

import lockImage from '../../../../assets/lock.png';
import { useTranslations } from '../../../../hooks';
import { Typography } from '../../../../material';
import { useStyles } from './styles';

export const PrivacyReminder: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslations();
  const reminders = [
    {
      title: t('privacy_reminder_title_1'),
    },
    {
      title: t('privacy_reminder_title_2'),
    },
    {
      title: t('privacy_reminder_title_3'),
    },
  ];

  const privacyReminders = reminders.map((reminder) => (
    <li key={reminder.title} className={classes.reminderContainer}>
      <Typography className={classes.reminderText} variant="body1">
        <span className={classes.reminderTitle}>{reminder.title}</span>
      </Typography>
    </li>
  ));

  return (
    <div className={classes.privacyReminderContainer}>
      <img alt={t('privacy_reminder_title_1')} src={lockImage} />
      <Typography className={classes.privacyReminderTitle} variant="h5">
        {t('privacy_reminder_title')}
      </Typography>
      <div className={classes.reminderElementsContainer}>
        <ul className={classes.listContainer}>{privacyReminders}</ul>
      </div>
    </div>
  );
};
