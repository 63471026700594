import React from 'react';

import { InsertDriveFileOutlinedIcon } from '../../../material';
import { useStyles } from './styles';

/**
 * Displays the Page Index component
 */
export const PageIndex: React.FC<PageIndexProps> = ({ pageIndex, totalPagesNumber }) => {
  const classes = useStyles();
  return (
    <div className={classes.pageIndexContainer}>
      <InsertDriveFileOutlinedIcon className={classes.fileLogo} />
      <span className={classes.pageIndexText}>{`${pageIndex}/${totalPagesNumber}`}</span>
    </div>
  );
};

interface PageIndexProps {
  pageIndex: number;
  totalPagesNumber: number;
}
