import React from 'react';
import Media from 'react-media';

import outmindLogo from '../../assets/logo-256.png';
import { useDesktopSignIn, useTranslations, useUserProfile } from '../../hooks';
import { Link } from '../../material';
import { WelcomeView } from '..';
import { StartUpLoader } from '../StartUpLoader';
import { useStyles } from './styles';

export const DesktopSignInView: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslations();

  const { isSuccess: isLoggedIn } = useUserProfile();

  useDesktopSignIn();

  if (!isLoggedIn) return <StartUpLoader />;

  return (
    <WelcomeView withFooter>
      <Media query="(max-width: 959px)">
        {(matches) => (
          <>
            <div className={classes.imageLogoContainer}>
              <img alt="login-successful" src={outmindLogo} />
            </div>
            <div className={!matches ? classes.signinTitle : classes.signinTitleMobile}>
              {t('login_successful')}
            </div>
            <div className={!matches ? classes.signinHelper : classes.signinHelperMobile}>
              {isLoggedIn ? (
                <div>
                  <div>{t('you_can_close_this_window')}</div>
                  <div style={{ fontSize: '14px', marginTop: '8px' }}>
                    {` ${t('if_app_did_not_open')}, `}
                    <Link color="primary" href="outmind:///signin">
                      {`${t('click_here')}`}
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </Media>
    </WelcomeView>
  );
};
