import { makeStyles } from '../../../material';

const useStyles = makeStyles(
  {
    addSourceDialogContent: {
      '&:first-child': {
        paddingTop: 0,
      },
      padding: '0',
    },
    addSourceDialogPaper: {
      padding: 0,
    },
    closeDialogButton: {
      color: 'rgba(0, 0, 0, 0.35);',
      position: 'absolute',
      right: 5,
      top: 5,
    },
    maxWidth: {
      maxWidth: '1032px',
    },
  },
  { index: 1 },
);

export { useStyles };
