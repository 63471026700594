import classnames from 'classnames';
import React, { useState } from 'react';

import { useTranslations } from '../../../hooks';
import { TranslationKey } from '../../../locales/types';
import { Tooltip } from '../../../material';
import { Actions, useDispatch } from '../../../store';
import { FieldFilter as FieldFilterInterface } from '../../../store/filters/types';
import { CheckBox } from '../../MaterialComponents/CheckBox';
import { useStyles } from '../styles';

export const FieldFilter: React.FC<FieldFilterProps> = ({ filter }) => {
  const { field, isActive } = filter;

  const classes = useStyles();

  const { t } = useTranslations();
  const [isHovered, setIsHovered] = useState(false);

  const hoverOverlayClasses = classnames(classes.hoverOverlay, {
    [classes.hovered]: isHovered,
  });

  const dispatch = useDispatch();

  const setFieldFilter = (bool: boolean): void => {
    dispatch(Actions.setFieldFilter(field, bool));
  };

  return (
    <Tooltip
      arrow
      classes={{ tooltipPlacementRight: classes.tooltipPlacementRight }}
      placement="right"
      title={`${t('search_only_in')} ${t(`field_${field}` as TranslationKey)}`}
    >
      <div
        className={classes.checkboxContainer}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={hoverOverlayClasses} />
        <CheckBox
          checked={isActive}
          color="primary"
          label={t(`field_${field}` as TranslationKey)}
          onChange={(_, bool) => setFieldFilter(bool)}
          value={field}
        />
      </div>
    </Tooltip>
  );
};

interface FieldFilterProps {
  filter: FieldFilterInterface;
}
