import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    appBarContainer: {
      alignItems: 'center',
      backgroundColor: '#ffffff',
      borderRadius: 0,
      display: 'flex',
      height: '65px',
      justifyContent: 'center',
      width: '100%',
      zIndex: 100,
    },
    appBarInnerContainer: {
      display: 'flex',
      paddingTop: '8px',
    },
    logoContainer: {
      alignItems: 'center',
      borderRight: '1px solid #EFEFEF',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      paddingTop: '8px',
    },
    searchBarContainer: {
      flex: '1',
    },
    userProfileContainer: {
      marginLeft: '16px',
    },
  },
  { index: 1 },
);
