import React from 'react';

import { useStyles } from '../../styles';
import { FullTree } from '../FullTree';
import { TreeDots } from './TreeDots';

export const Tree: React.FC<TreeProps> = ({ headArr, tailArr }) => {
  const classes = useStyles();

  return (
    <div className={classes.treeStructure}>
      <FullTree pathArr={headArr} withTail={!!tailArr} />
      <TreeDots tailArr={tailArr} />
      {tailArr ? <FullTree pathArr={tailArr} /> : null}
    </div>
  );
};

interface TreeProps {
  headArr: string[];
  tailArr?: string[];
}
