import { makeStyles } from '../../../material';

export const useStyles = makeStyles(
  {
    actionContainer: {
      display: 'inline-flex',
      justifyContent: 'center',
      marginTop: '20px',
    },
    actionSkeleton: { display: 'flex', marginRight: '4px' },
    card: { padding: '16px' },
    skeleton: { display: 'flex', justifySelf: 'center' },
    skeletonContainer: {
      display: 'grid',
      width: '100%',
    },
    skeletonText: {
      transform: 'unset',
    },
  },
  { index: 1 },
);
