import { makeStyles } from '../../../material';

export const useStyles = makeStyles(
  {
    fileSkeleton: {
      height: '100%',
    },
    fileSkeletonText: {
      transform: 'unset',
    },
  },
  { index: 1 },
);
