import React from 'react';

import { Box, Skeleton } from '../../../material';
import { useStyles } from './styles';

export const FileContentSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Box height="100%">
      <Skeleton classes={{ text: classes.fileSkeletonText }} className={classes.fileSkeleton} />
    </Box>
  );
};
