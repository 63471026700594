import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      addSourceDialogButtonContainer: {
        padding: '10px 0 20px 0',
      },
      iconButtonRoot: {
        '&:hover': {
          border: `1px solid ${theme.designSystem.colors.aux.main}`,
          color: theme.designSystem.colors.secondary.main,
        },
        border: '1px solid white',
        borderRadius: '2px',
      },
      noSourceHelper: {
        color: '#333',
        fontStyle: 'italic',
      },
      sourceIcon: {
        alignSelf: 'center',
        marginLeft: '10px',
        maxHeight: '100%',
        width: '35px',
      },
      sourceList: {
        border: '1px solid #ccc',
        borderRadius: '5px',
        maxHeight: '450px',
        overflow: 'auto',
      },
      sourceText: {
        margin: '6px 30px 6px 20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '300px',
      },
      syncProgressContainer: {
        left: '-25px',
        marginLeft: '50px',
        position: 'relative',
        top: '-10px',
      },
    },
    { index: 1 },
  )();
};
