import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    '@global': {
      '#suggestions .highlighted': {
        background: 'unset',
        color: 'inherit',
        fontWeight: 600,
        padding: 'unset',
      },
      '.highlighted': {
        background: '#fff7e3',
        borderRadius: '5px',
        color: 'inherit',
      },
      body: {
        fontFamily: 'Open Sans, sans-serif',
        margin: 0,
      },
    },
  },
  { index: 1 },
);
