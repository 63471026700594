import React from 'react';

import GoogleLogo from '../../assets/auth/glogo.svg';
import { ButtonBase, CircularProgress } from '../../material';
import { useStyles } from './styles';

export const GoogleButton: React.FC<GoogleButtonProps> = ({
  buttonText,
  isLoading,
  onClick,
  variant,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.loginButtonContainer}>
      <ButtonBase
        classes={{
          disabled: classes.loginButtonDisabled,
        }}
        className={classes.loginButton}
        disabled={isLoading}
        onClick={onClick}
        type="button"
      >
        <img alt="" className={classes.logo} height="18" src={GoogleLogo} width="18" />
        <span>{buttonText}</span>
      </ButtonBase>
      {isLoading ? (
        <div className={classes.progressContainer}>
          <CircularProgress size={variant === 'large' ? '24px' : '20px'} />
        </div>
      ) : null}
    </div>
  );
};

interface GoogleButtonProps {
  buttonText: string;
  isLoading?: boolean;
  onClick: () => void;
  variant?: 'medium' | 'large';
}
