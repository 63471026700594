import { ExtendedSources, sources } from '@outmind/types';
import React from 'react';

import { Tooltip } from '../../../../material';
import { CheckBox } from '../../../MaterialComponents/CheckBox';
import { SourceLogo } from '../../../SourceLogo';
import { useStyles } from '../ConnectorFilter/styles';
import { AddSourceWarning } from './AddSourceWarning';

const sourceNames = {
  ...sources,
  gsuite: { name: 'Suite Google' },
  o365: { name: 'Microsoft 365' },
};

export const PreconfiguredConnector: React.FC<PreconfiguredConnectorProps> = ({ source }) => {
  const classes = useStyles();

  const accountSourceLogo = (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <SourceLogo className={classes.logo} source={source} />
      </div>
      <div className={classes.filterLabel}>{sourceNames[source].name}</div>
    </div>
  );

  return (
    <div className={classes.filterContainer}>
      <div
        style={{
          width: '80%',
        }}
      >
        <Tooltip
          classes={{ popper: classes.tooltipPopper }}
          placement="bottom"
          title={sourceNames[source].name}
        >
          <CheckBox disabled label={accountSourceLogo} value={source} />
        </Tooltip>
      </div>
      <div style={{ display: 'flex' }}>
        <div className={classes.connectorStatusContainer}>
          <AddSourceWarning source={source} />
        </div>
      </div>
    </div>
  );
};

interface PreconfiguredConnectorProps {
  source: ExtendedSources;
}
