/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      image: {
        height: 'auto',
        position: 'absolute',
        top: 0,
        width: '100%',
      },
      imageContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        overflow: 'auto',
        position: 'relative',
        width: '100%',
      },
    },
    { index: 1 },
  )();
