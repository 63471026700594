import React, { useState } from 'react';

import { useUserProfile } from '../../hooks';
import { Grid } from '../../material';
import { AppBar } from '../NavBar';
import { StartUpLoader } from '../StartUpLoader';
import { useStyles } from './styles';

export const AdminConsole: React.FC = () => {
  const classes = useStyles();
  const { data: me } = useUserProfile();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar />
      </Grid>
      <Grid className={classes.iframeContainer} xs={12}>
        {isLoading ? <StartUpLoader /> : null}
        {me?.adminConsoleUrl ? (
          <iframe
            className={classes.iframe}
            frameBorder="0"
            onLoad={() => setIsLoading(false)}
            src={me.adminConsoleUrl}
            title="admin-console"
          />
        ) : (
          <StartUpLoader />
        )}
      </Grid>
    </Grid>
  );
};
