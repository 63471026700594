import React from 'react';

import { SanitizedHtmlTypography } from '..';
import { useStyles } from './styles';

/**
 * Displays the content of the result element by mixing the "content" property of the document
 * with possible highlights
 */
export const DocumentContent: React.FC<DocumentContentProps> = ({ content, matched, outlined }) => {
  const classes = useStyles();

  let className = classes.content;
  if (outlined) {
    className = matched ? classes.matchedOutlinedContent : classes.outlinedContent;
  }

  return content?.length ? (
    <SanitizedHtmlTypography className={className} html={content} variant="body2" />
  ) : null;
};

interface DocumentContentProps {
  content?: string;
  matched?: boolean;
  outlined?: boolean;
}
