import { FlowType, Source } from '@outmind/types';
import * as queryString from 'query-string';
import useReactRouter from 'use-react-router';

import { decodeStringFromBase64 } from '../../../util';

/**
 * Parses the state contained in the "/auth-callback" URL to provide the `AuthCallbackState`
 */
export const useAuthCallbackState = (): AuthCallbackState => {
  const { location } = useReactRouter();

  // Parse the code from the search query string in the location
  const search = queryString.parse(location.search);
  const hash = queryString.parse(location.hash);
  const state: AuthState = JSON.parse(decodeStringFromBase64(search['state'] as string));

  const cbState: AuthCallbackState = {
    ...state,
    flow: state.flowType,
    shouldUpdateCredentials: !!state.shouldUpdate,
  };

  const queryCode = search['code'] ?? hash['token'] ?? hash['/token'];
  if (typeof queryCode === 'string') {
    cbState.code = queryCode;
  }

  return cbState;
};

interface AuthState {
  connectorId?: string;
  flowType?: FlowType;
  shouldUpdate: boolean;
  source: Source;
}

interface AuthCallbackState extends AuthState {
  code?: string;
  flow?: FlowType;
  shouldUpdateCredentials: boolean;
}
