import React, { memo, useMemo } from 'react';

import boxLogo from '../../../assets/connectors/box.png';
import driveLogo from '../../../assets/connectors/drive.png';
import dropboxLogo from '../../../assets/connectors/dropbox.png';
import gmailLogo from '../../../assets/connectors/gmail.png';
import localDiskLogo from '../../../assets/connectors/localdisk.png';
import networkDiskLogo from '../../../assets/connectors/networkdisk.png';
import notionLogo from '../../../assets/connectors/notion.png';
import o365Logo from '../../../assets/connectors/o365.png';
import onedriveLogo from '../../../assets/connectors/onedrive.png';
import outlookLogo from '../../../assets/connectors/outlook.png';
import salesforceLogo from '../../../assets/connectors/salesforce.png';
import sharepointLogo from '../../../assets/connectors/sharepoint.png';
import slackLogo from '../../../assets/connectors/slack.png';
import teamsLogo from '../../../assets/connectors/teams.png';
import trelloLogo from '../../../assets/connectors/trello.png';
import outmindLogo from '../../../assets/logo-256.png';
import { SpinningSource } from './SpinningSource';
import { useStyles } from './styles';

const CIRCLE_SIZES = {
  innerCircle: { lg: 900, md: 800, sm: 700 },
  outerCircle: { lg: 1300, md: 1200, sm: 1000 },
  smallerCircle: { lg: 500, md: 450, sm: 400 },
};
/**
 * Renders the background circles and the moving sources
 */
const SourcesBackroundNM: React.FC<SourcesBackgroundProps> = ({ size }) => {
  const classes = useStyles();

  const outerCircleProps = useMemo(
    () => ({
      animationDuration: 30,
      height: CIRCLE_SIZES.outerCircle[size],
      width: CIRCLE_SIZES.outerCircle[size],
    }),
    [size],
  );

  const innerCircleProps = useMemo(
    () => ({
      animationDuration: 26,
      height: CIRCLE_SIZES.innerCircle[size],
      inverted: true,
      width: CIRCLE_SIZES.innerCircle[size],
    }),
    [size],
  );

  const smallerCircleProps = useMemo(
    () => ({
      animationDuration: 18,
      height: CIRCLE_SIZES.smallerCircle[size],
      width: CIRCLE_SIZES.smallerCircle[size],
    }),
    [size],
  );

  return (
    <div className={classes.container}>
      <div className={classes.circleHolder}>
        <SpinningSource
          logo={driveLogo}
          startRotation={0}
          withBorder
          {...{ ...outerCircleProps }}
        />
        <SpinningSource logo={gmailLogo} startRotation={60} {...{ ...outerCircleProps }} />
        <SpinningSource logo={outlookLogo} startRotation={120} {...{ ...outerCircleProps }} />
        <SpinningSource logo={trelloLogo} startRotation={180} {...{ ...outerCircleProps }} />
        <SpinningSource logo={driveLogo} startRotation={240} {...{ ...outerCircleProps }} />
        <SpinningSource logo={gmailLogo} startRotation={300} {...{ ...outerCircleProps }} />
      </div>

      <div className={classes.circleHolder}>
        <SpinningSource
          logo={onedriveLogo}
          startRotation={0}
          withBorder
          {...{ ...innerCircleProps }}
        />
        <SpinningSource logo={sharepointLogo} startRotation={60} {...{ ...innerCircleProps }} />
        <SpinningSource logo={slackLogo} startRotation={120} {...{ ...innerCircleProps }} />
        <SpinningSource logo={boxLogo} startRotation={180} {...{ ...innerCircleProps }} />
        <SpinningSource logo={onedriveLogo} startRotation={240} {...{ ...innerCircleProps }} />
        <SpinningSource logo={localDiskLogo} startRotation={300} {...{ ...innerCircleProps }} />
      </div>

      <div className={classes.circleHolder}>
        <SpinningSource
          logo={dropboxLogo}
          startRotation={0}
          withBorder
          {...{ ...smallerCircleProps }}
        />
        <SpinningSource logo={notionLogo} startRotation={60} {...{ ...smallerCircleProps }} />
        <SpinningSource logo={salesforceLogo} startRotation={120} {...{ ...smallerCircleProps }} />
        <SpinningSource logo={teamsLogo} startRotation={180} {...{ ...smallerCircleProps }} />
        <SpinningSource logo={o365Logo} startRotation={240} {...{ ...smallerCircleProps }} />
        <SpinningSource logo={networkDiskLogo} startRotation={300} {...{ ...smallerCircleProps }} />
      </div>
      <div className={classes.outmindLogoContainer}>
        <img alt="outmind-logo" className={classes.outmindLogo} src={outmindLogo} />
      </div>
    </div>
  );
};

interface SourcesBackgroundProps {
  size: 'sm' | 'md' | 'lg';
}

export const SourcesBackround = memo(SourcesBackroundNM);
