/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      addSourceButtonsContainer: {
        paddingTop: '12px',
      },
      addSourceDialogContainer: {
        padding: '32px',
      },
      addSourceLaterButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '12px 0',
      },
      addSourceSecurityHelper: {
        alignItems: 'center',
        color: theme.palette.primary.main,
        display: 'flex',
        fontSize: '0.7rem',
        marginTop: '12px',
      },
      addSourceSecurityHelperText: {
        marginLeft: '8px',
      },
      dialogSubTitle: {
        paddingTop: '4px',
      },
      dialogTitle: {
        color: '#222222',
        fontWeight: 600,
      },
      elementsContainer: {
        padding: '36px 32px 32px 32px',
      },
      grayArea: {
        background: '#F8F9FC',
      },
    },
    { index: 1 },
  )();
};
