import React from 'react';

import { useTranslations } from '../../../../hooks';
import { Button, CircularProgress } from '../../../../material';
import { useStyles } from '../styles';

export const ConfirmButton: React.FC<ConfirmButtonProps> = ({ editMode, isLoading, submit }) => {
  const classes = useStyles();
  const { t } = useTranslations();

  return (
    <div className={classes.confirmButtonContainer}>
      <Button
        color="primary"
        disabled={isLoading}
        onClick={() => submit()}
        size="large"
        variant="outlined"
      >
        {editMode ? t('edit_tag') : t('create_tag')}
        {isLoading ? <CircularProgress className={classes.buttonLoader} size={20} /> : null}
      </Button>
    </div>
  );
};

interface ConfirmButtonProps {
  editMode: boolean;
  isLoading: boolean;
  submit: () => void;
}
