/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';

export enum ActionTypes {
  CLOSE_SUPPORT = 'CLOSE_SUPPORT',
  OPEN_SUPPORT = 'OPEN_SUPPORT',
}

export const Actions = {
  closeSupport: () => action(ActionTypes.CLOSE_SUPPORT),
  openSupport: () => action(ActionTypes.OPEN_SUPPORT),
};
