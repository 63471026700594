import React, { memo, useEffect, useState } from 'react';
import { Tag } from 'tabler-icons-react';

import { Label, useTranslations } from '../../../hooks';
import { ButtonBase, CloseIcon, IconButton, Menu, Tooltip, Typography } from '../../../material';
import { LabelChipList } from '../LabelChipList';
import { useStyles } from './styles';

const ShowMoreLabelsMenuNP: React.FC<ShowMoreLabelsMenuProps> = ({
  contextMenuButton,
  labels,
  onClickLabel,
  onRemoveLabel,
  menuPosition,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>): void => {
    preventPropagation(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!contextMenuButton) setAnchorEl(null);
  }, [labels, contextMenuButton]);

  const preventPropagation = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const showLabelButton =
    labels.length > 0 ? (
      <div className={classes.iconButtonContainer}>
        <Tooltip title={t('see_more')}>
          <ButtonBase className={classes.hiddenLabelCount} onClick={(e) => handleClick(e)}>
            <span className={classes.hiddenLabelText}>{`${labels.length}+`}</span>
            <Tag color="#2b90e9" size={20} strokeWidth={2} />
          </ButtonBase>
        </Tooltip>
      </div>
    ) : null;

  const menuTitle = (): string => {
    if (!labels.length) return t('no_tag_found');
    if (contextMenuButton) return t('document_tags');
    return t('other_tags_associated');
  };

  return (
    <div style={{ display: 'flex' }}>
      {contextMenuButton ? (
        <div aria-hidden="true" onClick={(e) => handleClick(e)} style={{ width: '100%' }}>
          {contextMenuButton}
        </div>
      ) : (
        showLabelButton
      )}
      <Menu
        anchorEl={anchorEl}
        anchorPosition={
          menuPosition ? { left: menuPosition.mouseX, top: menuPosition.mouseY } : undefined
        }
        anchorReference={menuPosition ? 'anchorPosition' : 'anchorEl'}
        id="labels-menu"
        keepMounted
        onClick={(e) => {
          preventPropagation(e);
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <IconButton
          aria-label="Close"
          className={classes.closeMenuButton}
          color="inherit"
          onClick={(e) => {
            preventPropagation(e);
            handleClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.container}>
          <Typography className={classes.showMoreMenuHeader}>{menuTitle()}</Typography>
          <div className={classes.labelTagsContainer}>
            <LabelChipList
              displayWrap
              labels={labels}
              onClickLabel={onClickLabel}
              onDeleteLabel={onRemoveLabel}
            />
          </div>
        </div>
      </Menu>
    </div>
  );
};

interface ShowMoreLabelsMenuProps {
  contextMenuButton?: JSX.Element;
  labels: Label[];
  menuPosition?: { mouseX: number; mouseY: number };
  onClickLabel?: (label: Label) => void;
  onRemoveLabel?: (label: Label) => void;
}

export const ShowMoreLabelsMenu = memo(ShowMoreLabelsMenuNP);
