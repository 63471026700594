import React from 'react';

import { ArrowRightIcon } from '../../../material';
import { SanitizedHtmlTypography } from '../../SanitizedHtmlTypography';
import { useStyles } from './styles';

/**
 * Called when the user's window size is large enough to print the full path of the file.
 * @returns the arborescence to render
 */
export const FullTree: React.FC<FullTreeProps> = ({ pathArr, withTail }) => {
  const classes = useStyles();

  return (
    <div className={classes.treeStructure}>
      {pathArr.map((element, i) => {
        if (!i) {
          return (
            <SanitizedHtmlTypography className={classes.resultElementMetaText} html={element} />
          );
        }
        return (
          <div className={classes.treeStructure}>
            <ArrowRightIcon className={classes.arrow} />
            <SanitizedHtmlTypography className={classes.resultElementMetaText} html={element} />
          </div>
        );
      })}
      {withTail ? (
        <div className={classes.treeStructure}>
          <ArrowRightIcon className={classes.arrow} />
        </div>
      ) : null}
    </div>
  );
};

interface FullTreeProps {
  pathArr: string[];
  withTail?: boolean;
}
