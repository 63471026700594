import { useTheme } from '../../../hooks';
import { makeStyles } from '../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      checkbox: {
        '&:active': {
          background: 'unset',
        },
        '&:hover': {
          background: 'unset',
        },
        marginRight: '4px',
        overflow: 'overlay',
        padding: '4px 0px 4px 0.45em',
      },
      checkBoxIcon: {
        paddingLeft: '4px',
        paddingTop: '8px',
      },
      container: {
        alignItems: 'center',
        display: 'flex',
      },
      formControlLabel: {
        color: theme.designSystem.colors.secondary.main,
        fontSize: '14px',
        maxWidth: '100%',
        overflow: 'hidden',
        paddingLeft: '0',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      formControlLabelContainer: {
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
      },
      formControlLabelRoot: {
        pointerEvents: 'auto',
        height: '32px',
        marginRight: 0,
        width: '100%',
      },
    },
    { index: 1 },
  )();
};
