import classnames from 'classnames';
import React from 'react';

import { Typography } from '../../material';
import { useStyles } from './styles';

/**
 * Renders the subject of the mail thread this attachment has been exchanged in,
 * from its "attachmentContext.subject" property
 */
export const DocumentSubject: React.FC<DocumentSubjectProps> = ({ subject }) => {
  const classes = useStyles();
  const subjectText = `: "${subject}"`;
  const documentSubjectClasses = classnames(
    classes.resultElementMeta,
    classes.resultElementMaxWidth,
  );
  return (
    <div className={documentSubjectClasses}>
      <Typography className={classes.resultElementMetaText} variant="subtitle2">
        {subjectText}
      </Typography>
    </div>
  );
};

interface DocumentSubjectProps {
  subject: string;
}
