import React from 'react';

export const TieIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    height="30"
    viewBox="-162 -17 532 532.00001"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m147.914062 93.753906 39.0625-75.464844c2.003907-3.867187 1.84375-8.5-.414062-12.214843-2.265625-3.722657-6.304688-5.9921878-10.660156-5.9921878h-163.417969c-4.351563 0-8.390625 2.2695308-10.65625 5.9921878-2.257813 3.714843-2.421875 8.347656-.414063 12.214843l39.0625 75.46875-49.542968 280.203126c-2.1875 12.347656 1.128906 25.035156 9.0781248 34.738281l60.9062502 74.371093c8.167969 9.976563 20.382812 15.761719 33.277343 15.761719 12.890626 0 25.101563-5.785156 33.277344-15.761719l60.90625-74.371093c7.949219-9.703125 11.265625-22.390625 9.078125-34.738281zm7.503907-68.734375-28.097657 54.863281h-66.246093l-28.09375-54.863281zm13.671875 367.878907-60.90625 74.378906c-3.433594 4.1875-8.566406 6.617187-13.984375 6.617187-5.414063 0-10.550781-2.429687-13.976563-6.617187l-60.914062-74.378906c-3.332032-3.964844-4.730469-9.207032-3.808594-14.304688l48.449219-273.773438h60.492187l48.457032 273.773438c.921874 5.09375-.476563 10.339844-3.8125 14.304688zm0 0" />
  </svg>
);
