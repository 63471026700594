import React from 'react';
import Media from 'react-media';

import { Card, CardContent, Grid } from '../../material';
import { Footer } from '../Footer';
import { SourcesBackround } from './SourcesBackground';
import { useStyles } from './styles';

/**
 * Renders the WelcomeView displaying the background and Outmind logo
 * @param props Props used to render the WelcomeView
 */
export const WelcomeView: React.FC<WelcomeViewProps> = ({ children, withFooter }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.backgroundContainer} container>
      <Grid item md={6} xs={12}>
        <Card className={classes.mainWrapper} elevation={0}>
          <CardContent className={classes.cardContent}>
            {children}
            {withFooter ? (
              <div className={classes.footerContainer}>
                <Footer />
              </div>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
      <Grid className={classes.backgroundGridContainer} item md={6} xs={12}>
        <div className={classes.bannerContainer}>
          <Media
            queries={{
              extraSmall: '(max-width: 960px)',
              large: '(min-width: 1400px)',
              medium: '(min-width: 1280px) and (max-width: 1399px)',
              small: '(max-width: 1279px)',
            }}
          >
            {(matches) =>
              !matches.extraSmall ? (
                // eslint-disable-next-line no-nested-ternary
                <SourcesBackround size={matches.large ? 'lg' : matches.small ? 'sm' : 'md'} />
              ) : null
            }
          </Media>
        </div>
      </Grid>
    </Grid>
  );
};

interface WelcomeViewProps {
  withFooter?: boolean;
}
