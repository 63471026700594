import React from 'react';

import { PageIndex } from '../..';
import { NoPreviewFoundBackground } from '../../DocumentsListBackgrounds';
import { ImagePreview } from '../../ImagePreview';
import { useStyles } from '../styles';

export const PagePreview: React.FC<PagePreviewProps> = ({
  accessLink,
  pageIndex,
  thumbnail,
  totalPagesNumber,
}) => {
  const classes = useStyles();

  if (!thumbnail) return <NoPreviewFoundBackground accessLink={accessLink} />;

  return (
    <div className={classes.previewInnerContainer}>
      <ImagePreview imageUrl={thumbnail} />
      {pageIndex && totalPagesNumber ? (
        <div className={classes.pageIndex}>
          <PageIndex pageIndex={pageIndex} totalPagesNumber={totalPagesNumber} />
        </div>
      ) : null}
    </div>
  );
};

interface PagePreviewProps {
  accessLink?: string;
  pageIndex?: number;
  thumbnail?: string;
  totalPagesNumber?: number;
}
