/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { colors, makeStyles } from '../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      actionButton: {
        width: '100%',
      },
      bottom: {
        color: colors.grey[200],
      },
      card: {
        border: '1px solid #eee',
        borderRadius: '24px',
      },
      cardContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '350px',
        justifyContent: 'center',
        padding: '32px',
        width: '350px',
      },
      centered: {
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        width: '100vw',
      },
      circle: {
        strokeLinecap: 'round',
      },
      elementContainer: {
        margin: '24px 0',
      },
      helperClickText: {
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
        color: colors.indigo[600],
        fontWeight: 'bold',
        textAlign: 'center',
      },
      helperSubText: {
        color: colors.grey[700],
        fontWeight: 'bold',
        textAlign: 'center',
      },
      helperSubTextContainer: {
        marginTop: '12px',
      },
      helperText: {
        color: '#666',
        textAlign: 'center',
      },
      statusContainer: {
        display: 'flex',
        justifyContent: 'center',
      },
      statusSuccess: {
        color: colors.green[400],
        height: '54px',
        width: '54px',
      },
      top: {
        animationDuration: '550ms',
        color: theme.palette.primary.main,
        position: 'absolute',
      },
    },
    { index: 1 },
  )();
};
