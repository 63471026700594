import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const CheckBoxIcon: React.FC<SvgIconProps & { disabled?: boolean }> = ({
  disabled,
  ...props
}) => (
  <SvgIcon {...props}>
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <rect
        height="15"
        rx="1.5"
        stroke={disabled ? '#E6E6E6' : '#BFBFBF'}
        width="15"
        x="0.5"
        y="0.5"
      />
    </svg>
  </SvgIcon>
);
