import React from 'react';

import {
  CloseIcon,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from '../../material';
import { useStyles } from './styles';

export const CustomDialog: React.FC<CustomDialogProps> = (props) => {
  const { children, classNames, onClose, open, title, ...rest } = props;
  const classes = useStyles();

  return (
    <Dialog {...rest} className={classNames?.root} onClose={onClose} open={open} title={title}>
      <DialogTitle className={classNames?.title}>{title}</DialogTitle>
      <DialogContent className={classNames?.content}>
        {onClose ? (
          <IconButton
            aria-label="Close"
            className={classes.closeDialogButton}
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        {children}
      </DialogContent>
    </Dialog>
  );
};

interface CustomDialogProps extends DialogProps {
  classNames?: { content?: string; root?: string; title?: string };
  onClose?: () => void;
  open: boolean;
  title?: string;
}
