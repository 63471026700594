import React, { useState } from 'react';

import { useTranslations } from '../../hooks';
import { IconButton, IconButtonProps, SettingsOutlinedIcon, Tooltip } from '../../material';
import { ManageSourcesDialog } from './ManageSourcesDialog';
import { useStyles } from './styles';

export const ManageSourcesButton: React.FC<ManageSourcesButtonProps> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { t } = useTranslations();

  return (
    <div>
      <Tooltip aria-label="Add" arrow placement="right" title={t('manage_sources')}>
        <IconButton
          {...props}
          aria-label="Add"
          classes={{ root: classes.iconButtonRoot }}
          color="inherit"
          onClick={() => setOpen(true)}
        >
          <SettingsOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <ManageSourcesDialog close={() => setOpen(false)} open={open} />
    </div>
  );
};

type ManageSourcesButtonProps = IconButtonProps;
