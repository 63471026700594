import React from 'react';

import MicrosoftLogo from '../../assets/auth/mslogo.svg';
import { ButtonBase, CircularProgress } from '../../material';
import { useStyles } from './styles';

export const MicrosoftButton: React.FC<MicrosoftButtonProps> = ({
  buttonText,
  isLoading,
  onClick,
  variant,
}) => {
  const classes = useStyles({ variant });

  return (
    <div className={classes.loginButtonContainer}>
      <ButtonBase
        classes={{
          disabled: classes.loginButtonDisabled,
        }}
        className={classes.loginButton}
        disabled={isLoading}
        onClick={onClick}
        type="button"
      >
        <img alt="" className={classes.logo} height="18" src={MicrosoftLogo} width="18" />
        <span className={classes.buttonContent}>{buttonText}</span>
      </ButtonBase>
      {isLoading ? (
        <div className={classes.progressContainer}>
          <CircularProgress size={variant === 'large' ? '24px' : '20px'} />
        </div>
      ) : null}
    </div>
  );
};

interface MicrosoftButtonProps {
  buttonText: string;
  isLoading?: boolean;
  onClick: () => void;
  variant?: 'medium' | 'large';
}
