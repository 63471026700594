import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { ApiQuery, useApiRouteMaker } from '../useApi';

/**
 * Retrieves the profile of the logged in user
 */
export const useSlackRedirectUrl = ({
  channelId,
  messageId,
}: Params$UseSlackRedirectUrl): UseQueryResult<string> => {
  const makeRoute = useApiRouteMaker(ApiQuery.GET_SLACK_REDIRECT_URL);

  const getSlackDirectUrlQuery = useQuery<string>(ApiQuery.GET_SLACK_REDIRECT_URL, async () => {
    const route = makeRoute({ channelId, messageId });

    const result = await axios({
      method: route.method,
      url: route.url,
      withCredentials: true,
    });
    return result.data.redirectUrl;
  });

  return getSlackDirectUrlQuery;
};

interface Params$UseSlackRedirectUrl {
  channelId: string;
  messageId: string;
}
