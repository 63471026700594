/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../material';

const baseTitleStyle = {
  color: '#333333',
  fontSize: '0.8rem',
  fontWeight: 600,
  marginLeft: '8px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const useStyles = () =>
  makeStyles(
    {
      closeIcon: { marginLeft: '4px', padding: '2px' },
      connectorLogo: {
        height: '100%',
        padding: '2px',
        width: '18px',
      },
      connectorLogoContainer: {
        alignItems: 'center',
        display: 'flex',
      },
      drawer: {
        height: '100%',
        width: '100%',
      },
      drawerPaper: {
        boxSizing: 'border-box',
        position: 'relative',
        width: '100%',
        zIndex: 1,
      },
      fileHeaderContent: {
        alignItems: 'center',
        flex: '1 1 auto',
        justifyContent: 'space-betwwen',
        maxWidth: 'calc(100% - 24px)',
      },
      filePages: {
        paddingBottom: '20px',
      },
      filePreviewContainer: {
        backgroundColor: '#f5f5f6',
        flexGrow: 1,
        overflow: 'auto',
        padding: '20px',
      },
      fullScreenButton: {
        bottom: '10px',
        position: 'fixed',
        right: '10px',
      },
      headerContent: {
        paddingBottom: '12px',
        paddingLeft: '28px',
        paddingTop: '16px',
      },
      headerContentContainer: {
        alignItems: 'center',
        display: 'flex',
      },
      headerInfoContainer: {
        display: 'flex',
      },
      mailPreviewContainer: {
        backgroundColor: '#f5f5f6',
        flexGrow: 1,
        overflow: 'auto',
        padding: '20px',
      },
      messageRepliesContainer: {
        margin: '2px 0 2px 42px',
      },
      openInSourceButtonContainer: {
        display: 'flex',
      },
      openInSourceButtonText: {
        '&::first-letter': {
          textTransform: 'uppercase',
        },
        fontSize: '0.8rem',
        fontWeight: 600,
        textTransform: 'none',
      },
      pageIndex: {
        bottom: '24px',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, 0)',
      },
      pageInfoContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '5px',
        maxWidth: '100%',
      },
      pagePreviewContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        width: '100%',
      },
      pageImagePreview: {
        height: 'auto',
        width: '100%',
      },
      previewContainer: {
        flexGrow: 1,
        overflow: 'auto',
      },
      previewFooter: {
        alignItems: 'center',
        borderTop: '1px solid #eee',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '12px',
      },
      previewHeader: {
        alignItems: 'center',
        borderBottom: '1px solid #eee',
        display: 'flex',
        padding: '8px 12px',
        position: 'relative',
      },
      previewIframe: { border: 'none', height: '100%', width: '100%' },
      previewInnerContainer: {
        flex: 1,
        height: '100%',
        overflow: 'hidden',
        padding: '24px',
        position: 'relative',
      },
      resultElementFileType: {
        height: 'auto',
        marginRight: '10px',
        width: '18px',
      },
      title: {
        ...baseTitleStyle,
        color: '#1565c0',
        whiteSpace: 'nowrap',
        width: '100%',
      },
    },
    { index: 1 },
  )();
