import React from 'react';

import { useOrganisationSources, useTranslations, useUserProfile } from '../../../hooks';
import { CloseIcon, Dialog, DialogContent, Grid, IconButton, Typography } from '../../../material';
import { PreconfiguredSource } from './PreconfiguredSource';
import { PrivacyReminder } from './PrivacyReminder';
import { useStyles } from './styles';

/**
 * Renders a dialog that shows Reminders of organisations sources also of privacy when adding a new source in Outmind
 * Also a button that redirect to be authenticated in the source
 */
export const PreconfiguredSourcesDialog: React.FC<PreconfiguredSourcesDialogProps> = ({
  onClose,
  open,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const { data: user } = useUserProfile();

  const { data: organisationSources = { connectedSources: [], sourcesToBeConnected: [] } } =
    useOrganisationSources();

  return (
    <Dialog
      aria-describedby="sources-dialog-description"
      aria-labelledby="sources-dialog-title"
      classes={{ paperWidthMd: classes.maxWidth }}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <DialogContent style={{ padding: '0px' }}>
        <IconButton
          aria-label="Close"
          className={classes.closeDialogButton}
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Grid container>
          <Grid className={classes.firstColumnContainer} item md={7}>
            <div>
              <Typography className={classes.welcomeText} variant="h5">
                {user?.givenName
                  ? t('welcome_user', { userGivenName: user.givenName })
                  : t('welcome_to_outmind')}
              </Typography>
              <div>
                <Typography className={classes.description} variant="subtitle1">
                  {t('connect_sources_invite')}
                </Typography>
              </div>
            </div>
            <div>
              {organisationSources.sourcesToBeConnected.map((source) => (
                <div className={classes.preconfiguredSourceContainer}>
                  <PreconfiguredSource key={source} source={source} />
                </div>
              ))}
              {organisationSources.connectedSources.map((source) => (
                <div className={classes.preconfiguredSourceContainer}>
                  <PreconfiguredSource key={`disabled-${source}`} disabled source={source} />
                </div>
              ))}
            </div>
          </Grid>
          <Grid className={classes.secondColumnContainer} item md={5}>
            <div className={classes.elementsContainer}>
              <PrivacyReminder />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

interface PreconfiguredSourcesDialogProps {
  onClose: () => void;
  open: boolean;
}
