/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      fileNotFoundDescription: {
        color: theme.designSystem.colors.secondary.main,
        marginTop: '0',
      },
    },
    { index: 1 },
  )();
};
