import React from 'react';

import { Box, Card, Grid, Skeleton } from '../../../material';
import { useStyles } from './styles';

export const CollaboratorSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid item md={3}>
      <Card className={classes.card} style={{ display: 'flex', margin: '16px' }}>
        <Box style={{ width: '100%' }}>
          <div className={classes.skeletonContainer}>
            <Skeleton
              classes={{ text: classes.skeletonText }}
              className={classes.skeleton}
              height={80}
              variant="circle"
              width={80}
            />
            <Skeleton className={classes.skeleton} width="50%" />
            <Skeleton className={classes.skeleton} width="75%" />
            <div className={classes.actionContainer}>
              <Skeleton className={classes.actionSkeleton} height={60} width="45%" />
              <Skeleton className={classes.actionSkeleton} height={60} width="45%" />
            </div>
          </div>
        </Box>
      </Card>
    </Grid>
  );
};
