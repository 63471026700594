import { DocumentIndicesExtended } from '@outmind/types';
import {
  Board as BoardFilled,
  BookContacts as BookContactsFilled,
  Briefcase as BriefcaseFilled,
  BuildingMultiple as BuildingMultipleFilled,
  Chat as ChatFilled,
  Document as DocumentFilled,
  Lightbulb as LightbulbFilled,
  Mail as MailFilled,
  OfficeApps as OfficeAppsFilled,
  Person as PersonFilled,
  SquareMultiple as SquareMultipleFilled,
} from '@styled-icons/fluentui-system-filled';
import {
  Board,
  BookContacts,
  Briefcase,
  BuildingMultiple,
  Chat,
  Document,
  Lightbulb,
  Mail,
  OfficeApps,
  Person,
  SquareMultiple,
} from '@styled-icons/fluentui-system-regular';
import React from 'react';

import { useStyles } from './styles';

export const IndexIcon: React.FC<IndexIconProps> = ({ index, type }) => {
  const classes = useStyles();
  const tabIconsProps = { size: 20 };
  const tabIcons = {
    '*': {
      default: <OfficeApps className={classes.indexTabIcon} {...tabIconsProps} />,
      filled: <OfficeAppsFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      home: <OfficeApps className={classes.titleIcon} {...tabIconsProps} />,
    },
    accounts: {
      default: <BuildingMultiple className={classes.indexTabIcon} {...tabIconsProps} />,
      filled: <BuildingMultipleFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      home: <BuildingMultiple className={classes.titleIcon} {...tabIconsProps} />,
    },
    cards: {
      default: <Board className={classes.indexTabIcon} {...tabIconsProps} />,
      filled: <BoardFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      home: <Board className={classes.titleIcon} {...tabIconsProps} />,
    },
    contacts: {
      default: <BookContacts className={classes.indexTabIcon} {...tabIconsProps} />,
      filled: <BookContactsFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      home: <BookContacts className={classes.titleIcon} {...tabIconsProps} />,
    },
    files: {
      default: <Document className={classes.indexTabIcon} {...tabIconsProps} />,
      filled: <DocumentFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      home: <Document className={classes.titleIcon} {...tabIconsProps} />,
    },
    mails: {
      default: <Mail className={classes.indexTabIcon} {...tabIconsProps} />,
      filled: <MailFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      home: <Mail className={classes.titleIcon} {...tabIconsProps} />,
    },
    messages: {
      default: <Chat className={classes.indexTabIcon} {...tabIconsProps} />,
      filled: <ChatFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      home: <Chat className={classes.titleIcon} {...tabIconsProps} />,
    },
    missions: {
      default: <Briefcase className={classes.indexTabIcon} {...tabIconsProps} />,
      filled: <BriefcaseFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      home: <Briefcase className={classes.titleIcon} {...tabIconsProps} />,
    },
    opportunities: {
      default: <Lightbulb className={classes.indexTabIcon} {...tabIconsProps} />,
      filled: <LightbulbFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      home: <Lightbulb className={classes.titleIcon} {...tabIconsProps} />,
    },
    pages: {
      default: <SquareMultiple className={classes.indexTabIcon} {...tabIconsProps} />,
      filled: <SquareMultipleFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      home: <SquareMultiple className={classes.titleIcon} {...tabIconsProps} />,
    },
    people: {
      default: <Person className={classes.indexTabIcon} {...tabIconsProps} />,
      filled: <PersonFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      home: <Person className={classes.titleIcon} {...tabIconsProps} />,
    },
    /**
     * @deprecated
     */
    slides: {
      default: <SquareMultiple className={classes.indexTabIcon} {...tabIconsProps} />,
      filled: <SquareMultipleFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      home: <SquareMultiple className={classes.titleIcon} {...tabIconsProps} />,
    },
  };

  return tabIcons[index][type];
};

interface IndexIconProps {
  index: DocumentIndicesExtended;
  type: 'home' | 'filled' | 'default';
}
