/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../../hooks';
import { makeStyles } from '../../../material';

export const useStyles = (props?: SearchSuggestionsStyleProps) => {
  const theme = useTheme();

  return makeStyles(
    {
      noSuggestion: {
        color: theme.designSystem.colors.secondary.main,
        fontSize: '0.75rem',
        fontWeight: 500,
        height: 45,
        paddingLeft: 20,
      },
      separator: {
        backgroundColor: theme.designSystem.colors.separator.main,
        height: '1px',
        margin: '4px 0',
        width: '100%',
      },
      suggestionsContainer: {
        alignItems: 'center',
        borderRadius: props?.inSpotlight ? '8px' : '10px',
        borderTop: '1px solid transparent',
        borderTopColor: theme.designSystem.colors.separator.main,
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        cursor: 'pointer',
        overflow: 'auto',
        position: 'absolute',
        width: '100%',
        zIndex: 4,
      },
      suggestionsSubHeader: {
        lineHeight: '32px',
        paddingLeft: '24px',
      },
    },
    { index: 1 },
  )(props);
};

interface SearchSuggestionsStyleProps {
  inSpotlight?: boolean;
  isSharp?: boolean;
}
