import { Source, sources } from '@outmind/types';
import clsn from 'classnames';
import React from 'react';

import { ButtonBase, Card, CardContent, Grid, Typography } from '../../../material';
import { SourceLogo } from '../../SourceLogo';
import { useStyles } from './styles';

export const AddClassicSourceButton: React.FC<AddClassicSourceProps> = ({
  beta,
  disabled,
  openCredentialsDialog,
  source,
}) => {
  const classes = useStyles();

  return (
    <Grid item sm={3}>
      <Card className={classes.sourceCard}>
        <ButtonBase
          classes={{ disabled: classes.sourceButtonDisabled }}
          className={classes.sourceButton}
          disabled={disabled}
          onClick={openCredentialsDialog}
        >
          <CardContent
            classes={{ root: classes.cardContentRoot }}
            className={clsn({
              [classes.sourceButtonBeta]: beta,
            })}
          >
            <div className={classes.sourceLogoContainer}>
              <SourceLogo className={classes.sourceLogo} source={source} />
            </div>
            <Typography className={classes.sourceName} variant="subtitle2">
              {sources[source].name}
            </Typography>
          </CardContent>
        </ButtonBase>
      </Card>
    </Grid>
  );
};

interface AddClassicSourceProps {
  beta: boolean;
  disabled?: boolean;
  openCredentialsDialog: () => void;
  source: Source;
}
