import './whyDidYouRender';
import './init-monitoring';

import { App, AppProvider } from '@outmind/ui-elements';
import { render } from 'react-dom';

render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById('root') as HTMLElement,
);
