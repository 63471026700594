import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import localeFr from 'date-fns/locale/fr';
import React from 'react';
import { DateRange } from 'react-date-range';

import { useTranslations } from '../../../hooks';
import { Button, Dialog, DialogActions } from '../../../material';

export const DateRangeDialog: React.FC<DateRangeDialogProps> = ({
  chooseDateDialogOpen,
  rangeDate,
  setRangeDate,
  confirm,
}) => {
  const { i18n } = useTranslations();

  return (
    <Dialog open={chooseDateDialogOpen}>
      <DateRange
        direction="horizontal"
        editableDateInputs
        locale={i18n.language === 'fr' ? localeFr : undefined}
        months={2}
        moveRangeOnFirstSelection={false}
        onChange={(item) => setRangeDate([item.selection])}
        ranges={rangeDate}
      />
      <DialogActions>
        <Button autoFocus color="primary" onClick={confirm}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface DateRangeDialogProps {
  chooseDateDialogOpen: boolean;
  confirm: () => void;
  rangeDate: { endDate: Date | null; key: string; startDate: Date | null }[];
  setRangeDate: (dates: { endDate: Date | null; key: string; startDate: Date | null }[]) => void;
}
