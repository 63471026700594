import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    background: {
      height: '100vh',
      overflow: 'hidden',
      position: 'absolute',
      width: '100vw',
      zIndex: 0,
    },
    outmindLogo: {
      fill: 'white',
      maxHeight: '140vh',
      opacity: 0.5,
      position: 'absolute',
      right: '-30vw',
      top: '-35vh',
      zIndex: -500,
    },
    wallpaper: {
      backgroundColor: '#dbdcff',
      backgroundImage: 'linear-gradient(to bottom, white -50%,  transparent 250%)',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '100%',
      left: '0',
      position: 'absolute',
      top: '0',
      width: '100%',
      zIndex: -1000,
    },
  },
  { index: 1 },
);
