import { useTheme } from '../../../hooks';
import { makeStyles } from '../../../material';

export const useStyles = () => {
  const theme = useTheme();

  return makeStyles(
    {
      accountSectionManageSourcesButtonContainer: {
        alignItems: 'center',
        color: theme.designSystem.colors.disabled.main,
        display: 'flex',
      },
      accountSectionTitle: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '14px',
      },
      accountSectionTitleContainer: {
        alignItems: 'center',
        color: theme.designSystem.colors.disabled.main,
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '8px',
      },
      addSourceButtonContainer: {
        marginLeft: '-8px',
      },
      iconButton: {
        color: '#FAD271',
        padding: '2px 4px 4px 4px',
      },
      noSourceConnected: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '0.825rem',
        fontStyle: 'italic',
      },
      noSourceConnectedContainer: {
        color: theme.designSystem.colors.secondary.main,
      },
      reconnectButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '16px',
      },
    },
    { index: 1 },
  )();
};
