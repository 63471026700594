import { Blob } from '@outmind/helpers';
import { MimeType } from '@outmind/types';
import React from 'react';

import { getFileLogo } from '../../util';
import { useStyles } from './styles';

/**
 * Displays the thumbnail of the ElasticDoc from its "thumbnail" property
 */
export const DocumentThumbnail: React.FC<DocumentThumbnailProps> = ({
  isSimilarFile,
  thumbnail,
  mimeType,
}) => {
  const classes = useStyles({ isSimilarFile });

  const thumbnailBuffer =
    thumbnail && !thumbnail.includes('thumbnail') ? Blob.b64toBlob(thumbnail) : undefined;

  const thumbnailUrl = thumbnailBuffer ? URL.createObjectURL(thumbnailBuffer) : thumbnail;

  if (thumbnailUrl) {
    return (
      <div className={classes.thumbnailContainer}>
        <img alt="" className={classes.thumbnailImg} src={thumbnailUrl} />
      </div>
    );
  }

  return (
    <div className={classes.emptyThumbnail}>
      {mimeType ? getFileLogo(mimeType, classes.thumbnailLogo) : null}
    </div>
  );
};

interface DocumentThumbnailProps {
  isSimilarFile?: boolean;
  mimeType?: MimeType;
  thumbnail: string | undefined;
}
