import { makeStyles } from '../../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      bookmarkIcon: {
        height: '0.85em',
        marginLeft: '4px',
        width: '0.85em',
      },
      noFavoriteText: {
        display: 'flex',
      },
      noFavoriteTextContainer: {
        color: '#707070',
        fontSize: '14px',
        padding: '16px 32px',
      },
      titleIcon: {
        marginBottom: '4px',
        marginRight: '8px',
      },
    },
    { index: 1 },
  )();
