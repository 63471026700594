import React, { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import {
  getRandomLabelColor as getRandomColor,
  Label,
  useAddLabel,
  useCreateLabel,
  useDocumentLabels,
  useRemoveLabel,
  useRuntimeEnv,
  useTranslations,
} from '../../hooks';
import {
  AddIcon,
  GoToIcon,
  ListItemIcon,
  ListItemText,
  LocalOfferOutlinedIcon,
  Menu,
  MenuItem,
  PictureInPictureAltIcon,
  SearchIcon,
  VisibilityOutlinedIcon,
} from '../../material';
import { Actions, useDispatch } from '../../store';
import { Bookmark } from '../Bookmark';
import { AddLabelButton } from '../DocumentLabels/AddLabelButton';
import { ShowMoreLabelsMenu } from '../DocumentLabels/ShowMoreMenu';
import { useStyles } from './styles';

export const ContextMenu: React.FC<ContextMenuProps> = ({
  bookmarked,
  documentId,
  mouseX,
  mouseY,
  onClose,
  onFolderSearch,
  onOpen,
  onOpenInFileExplorer,
  onPreview,
}) => {
  const classes = useStyles();
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const { inMacOS } = useRuntimeEnv();

  const { data: documentLabels = [] } = useDocumentLabels(documentId);

  const { mutateAsync: addLabel } = useAddLabel();

  const { mutateAsync: createLabel } = useCreateLabel();

  const { mutateAsync: removeLabel } = useRemoveLabel();

  const onClickLabel = useCallback(
    (label: Label): void => {
      dispatch(Actions.addLabelFilter(label));
    },
    [dispatch],
  );

  return (
    <Menu
      anchorPosition={mouseY && mouseX ? { left: mouseX, top: mouseY } : undefined}
      anchorReference="anchorPosition"
      classes={{ paper: classes.styleMenuPaper }}
      keepMounted
      onClose={onClose}
      open={mouseY !== null}
    >
      <Bookmark bookmarked={bookmarked} documentId={documentId} variant="menuItem" />
      {onFolderSearch ? (
        <MenuItem
          classes={{ root: classes.menuItem }}
          className="search-in-folder-menu-item"
          onClick={() => {
            onClose();
            onFolderSearch();
          }}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <SearchIcon classes={{ root: classes.itemIcon }} />
          </ListItemIcon>
          <ListItemText classes={{ root: classes.itemText }} primary={t('search_in_folder')} />
        </MenuItem>
      ) : null}
      {onPreview ? (
        <MenuItem
          classes={{ root: classes.menuItem }}
          className="open-preview-menu-item"
          onClick={() => {
            onClose();
            onPreview();
          }}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <VisibilityOutlinedIcon classes={{ root: classes.itemIcon }} />
          </ListItemIcon>
          <ListItemText classes={{ root: classes.itemText }} primary={t('preview')} />
        </MenuItem>
      ) : null}

      <MenuItem
        classes={{ root: classes.menuItem }}
        className="open-in-source-menu-item"
        onClick={() => {
          onClose();
          onOpen();
        }}
      >
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          <GoToIcon classes={{ root: classes.itemIcon }} />
        </ListItemIcon>
        <ListItemText classes={{ root: classes.itemText }} primary={t('open_in_source')} />
      </MenuItem>

      {onOpenInFileExplorer ? (
        <MenuItem
          classes={{ root: classes.menuItem }}
          className="open-in-finder-menu-item"
          onClick={() => {
            onClose();
            onOpenInFileExplorer();
          }}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <PictureInPictureAltIcon classes={{ root: classes.itemIcon }} />
          </ListItemIcon>
          <ListItemText
            classes={{ root: classes.itemText }}
            primary={t(inMacOS ? 'show_in_finder' : 'open_with_file_explorer')}
          />
        </MenuItem>
      ) : null}

      <div className={classes.separator} />

      <AddLabelButton
        addLabel={(label) => {
          addLabel({ documentId, labelId: label.id });
          onClose();
        }}
        contextMenuButton={
          <MenuItem classes={{ root: classes.menuItem }}>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <AddIcon classes={{ root: classes.addIcon }} />
              <LocalOfferOutlinedIcon classes={{ root: classes.itemIcon }} />
            </ListItemIcon>
            <ListItemText classes={{ root: classes.itemText }} primary={t('add_tag')} />
          </MenuItem>
        }
        createNewLabel={async (labelName) => {
          const labelId = uuid();
          addLabel({ documentId, labelId });
          onClose();
          await createLabel({
            label: {
              color: getRandomColor(),
              id: labelId,
              name: labelName,
            },
          });
          dispatch(Actions.notifyLabelCreated());
        }}
        documentLabels={documentLabels}
        menuPosition={mouseX && mouseY ? { mouseX, mouseY } : undefined}
      />

      <ShowMoreLabelsMenu
        contextMenuButton={
          <MenuItem classes={{ root: classes.menuItem }}>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <LocalOfferOutlinedIcon classes={{ root: classes.itemIcon }} />
            </ListItemIcon>
            <ListItemText classes={{ root: classes.itemText }} primary={t('show_tags')} />
          </MenuItem>
        }
        labels={documentLabels}
        menuPosition={mouseX && mouseY ? { mouseX, mouseY } : undefined}
        onClickLabel={onClickLabel}
        onRemoveLabel={(label) => removeLabel({ documentId, labelId: label.id })}
      />
    </Menu>
  );
};

interface ContextMenuProps {
  bookmarked?: boolean;
  documentId: string;
  mouseX: number | null;
  mouseY: number | null;
  onClose: () => void;
  onFolderSearch?: () => void;
  onOpen: () => void;
  onOpenInFileExplorer?: () => void;
  onPreview?: () => void;
}
