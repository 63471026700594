import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import { Dispatch } from 'redux';

import { AppActions, AppState } from './types';

/**
 * Re-exporting react-redux `useSelector` hook with Outmind dedicated typing
 */
export const useSelector: TypedUseSelectorHook<AppState> = useReduxSelector;

/**
 * Re-exporting react-redux `useDispatch` hook with Outmind dedicated typing
 */
export const useDispatch: () => Dispatch<AppActions> = useReduxDispatch;
