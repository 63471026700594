import { makeStyles } from '../../../material';

export const useStyles = makeStyles(
  {
    arrow: {
      width: '0.7em',
    },
    dots: {
      display: 'flex',
    },
    resultElementMetaText: {
      fontSize: '0.75rem',
      marginLeft: 3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    treeStructure: {
      alignItems: 'center',
      display: 'flex',
      overflow: 'hidden',
    },
  },
  { index: 1 },
);
