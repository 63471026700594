/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      containedButton: {
        '&:hover': { boxShadow: 'none' },
        borderRadius: '30px',
        boxShadow: 'none',
        height: '32px',
        textTransform: 'capitalize',
      },
      containedButtonDisabled: {
        backgroundColor: '#1DB954',
      },
      preconfiguredSourceContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0px 8px 0px',
        width: '100%',
      },
      preconfiguredSourceNameContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      },
      sourceIconContainer: {
        marginLeft: '12px',
      },
      sourceName: {
        color: '#545E6A',
        fontSize: '14px',
        fontWeight: 600,
      },
    },
    { index: 1 },
  )();
