import { CaretDown } from '@styled-icons/fluentui-system-filled';
import React, { ChangeEvent, useState } from 'react';

import { useTranslations } from '../../../hooks';
import { TranslationKey } from '../../../locales/types';
import { FormControl, MenuItem, Select, Typography } from '../../../material';
import { Actions, useDispatch } from '../../../store';
import { DateIcon } from './DateIcon';
import { DateRangeDialog } from './DateRangeDialog';
import { useStyles } from './styles';

export const DateFilter: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslations();

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [chooseDateDialogOpen, setChooseDateDialogOpen] = useState(false);
  const [dateValue, setDateValue] = useState('anytime');
  const [rangeDate, setRangeDate] = useState([
    {
      endDate: new Date(),
      key: 'selection',
      startDate: new Date(),
    },
  ]);

  const now = new Date();

  const sortByValues = [
    {
      translationKey: t('anytime'),
      value: 'anytime',
    },
    {
      translationKey: t('last_days', { days: 7 }),
      value: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7).toISOString(),
    },
    {
      translationKey: t('last_days', { days: 30 }),
      value: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30).toISOString(),
    },
    {
      translationKey: t('last_days', { days: 90 }),
      value: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 90).toISOString(),
    },
    {
      translationKey: t('custom'),
      value: 'custom',
    },
  ];

  const handleOnChange = (e: ChangeEvent<{ name?: string; value: unknown }>): void => {
    const date = e.target?.value as string;
    setDateValue(date);
    if (date) {
      if (date === 'anytime') {
        dispatch(Actions.setDateFilterFrom(null));
        dispatch(Actions.setDateFilterTo(null));
        return;
      }
      if (date === 'custom') {
        setChooseDateDialogOpen(true);
        return;
      }
      dispatch(Actions.setDateFilterFrom(new Date(date)));
    }
  };

  const confirmDateSelection = (): void => {
    dispatch(Actions.setDateFilterFrom(rangeDate[0]?.startDate));
    dispatch(Actions.setDateFilterTo(rangeDate[0]?.endDate));
    setChooseDateDialogOpen(false);
  };

  return (
    <>
      <FormControl className={classes.formControlElement}>
        <Typography className={classes.sectionTitle}>Date</Typography>
        <Select
          classes={{
            icon: classes.dropDownIcon,
            outlined: classes.inputOutlined,
            select: classes.selectElement,
            selectMenu: classes.selectItem,
          }}
          className={classes.sortBy}
          IconComponent={CaretDown}
          MenuProps={{ classes: { list: classes.menuList, paper: classes.menu } }}
          onChange={handleOnChange}
          onClose={() => setIsSelectOpen(false)}
          onOpen={() => setIsSelectOpen(true)}
          value={dateValue}
          variant="outlined"
        >
          {sortByValues.map((sortByItem, idx) => (
            <MenuItem
              key={sortByItem.value}
              classes={{ selected: classes.selectedItem }}
              className={classes.selectItem}
              value={sortByItem.value}
            >
              <div className={classes.selectItemContainer} id={`select-item-container-${idx}`}>
                <div
                  className={classes.sortItemIconContainer}
                  id={`sort-item-icon-container-${idx}`}
                >
                  <DateIcon
                    chosenSortBy={dateValue}
                    currentSortBy={sortByItem.value}
                    isSelectOpen={isSelectOpen}
                  />
                </div>
                <Typography className={classes.sortItemText}>
                  {t(sortByItem.translationKey as TranslationKey)}
                </Typography>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {dateValue === 'custom' ? (
        <div>
          {rangeDate[0]?.startDate ? (
            <div className={classes.dateContainer}>
              <Typography className={classes.dateLabel}>{t('start')}</Typography>
              <Typography className={classes.date} onClick={() => setChooseDateDialogOpen(true)}>
                {rangeDate[0].startDate.toLocaleDateString('fr')}
              </Typography>
            </div>
          ) : null}
          {rangeDate[0]?.endDate ? (
            <div className={classes.dateContainer}>
              <Typography className={classes.dateLabel}>{t('end')}</Typography>
              <Typography className={classes.date} onClick={() => setChooseDateDialogOpen(true)}>
                {rangeDate[0].endDate.toLocaleDateString('fr')}
              </Typography>
            </div>
          ) : null}
        </div>
      ) : null}
      <DateRangeDialog
        chooseDateDialogOpen={chooseDateDialogOpen}
        confirm={confirmDateSelection}
        rangeDate={rangeDate}
        setRangeDate={(
          dates: {
            endDate: Date;
            key: string;
            startDate: Date;
          }[],
        ) => setRangeDate(dates)}
      />
    </>
  );
};
