import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { LabelTypes } from '@outmind/types';
import React, { useEffect, useState } from 'react';

import {
  getBookmarkLabel,
  useAddLabel,
  useCreateLabel,
  useRemoveLabel,
  useTranslations,
  useUserProfile,
} from '../../hooks';
import { IconButton, StarOutlineRoundedIcon, StarRoundedIcon } from '../../material';
import { useStyles } from './styles';

export const Bookmark: React.FC<BookmarkProps> = ({ documentId, bookmarked, variant }) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(bookmarked);

  const { data: user } = useUserProfile();

  const { mutateAsync: addLabel } = useAddLabel();

  const { mutateAsync: removeLabel } = useRemoveLabel();

  const { mutateAsync: createLabel } = useCreateLabel();

  const { t } = useTranslations();

  useEffect(() => {
    setIsActive(bookmarked);
  }, [bookmarked]);

  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    if (!isActive) bookmarkFile();
    else removeBookmark();
  };

  const bookmarkFile = async (): Promise<void> => {
    setIsActive(true);
    const { color, id, name, type } = getBookmarkLabel(user?.id);
    addLabel({ documentId, labelId: id, type: LabelTypes.Bookmark });
    await createLabel({
      label: {
        color,
        id,
        name,
        type,
      },
    });
  };

  const removeBookmark = async (): Promise<void> => {
    setIsActive(false);
    const labelId = `${user?.id}-bookmark`;
    removeLabel({ documentId, labelId });
  };

  if (variant === 'button') {
    return (
      <IconButton
        aria-label="bookmark"
        className={classes.bookmarkIconButton}
        onClick={handleClick}
      >
        {isActive ? <StarRoundedIcon className={classes.bookmarkIcon} /> : null}
      </IconButton>
    );
  }
  if (variant === 'menuItem') {
    return (
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleClick}>
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          <StarOutlineRoundedIcon classes={{ root: classes.favoriteIcon }} />
        </ListItemIcon>
        {isActive ? (
          <ListItemText classes={{ root: classes.favoriteText }} primary={t('remove_bookmark')} />
        ) : (
          <ListItemText classes={{ root: classes.favoriteText }} primary={t('add_bookmark')} />
        )}
      </MenuItem>
    );
  }
  return null;
};

interface BookmarkProps {
  bookmarked?: boolean;
  documentId: string;
  variant: 'button' | 'menuItem';
}
