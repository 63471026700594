import { PREVIEW_AS_IMAGE, PREVIEW_WITH_MARKDOWN_READER } from '@outmind/types';
import React, { useEffect, useState } from 'react';

import { useFilePreview } from '../../../hooks';
import { FileContentSkeleton } from '../../DocumentSkeleton/FileSkeleton';
import { NoPreviewFoundBackground } from '../../DocumentsListBackgrounds/NoPreviewFoundBackground';
import { ImagePreview } from '../../ImagePreview';
import { useStyles } from '../styles';
import { MarkdownPreview } from './MarkdownPreview';

export const FilePreview: React.FC<FilePreviewProps> = ({ accessLink, documentId }) => {
  const classes = useStyles();

  const [previewError, setPreviewError] = useState(false);

  useEffect(() => setPreviewError(false), [documentId]);

  const {
    data: preview,
    isError: loadPreviewError,
    isLoading: loadingPreviewUrl,
  } = useFilePreview(documentId);

  if (loadingPreviewUrl) return <FileContentSkeleton />;

  if (loadPreviewError || previewError || !preview) {
    return <NoPreviewFoundBackground accessLink={accessLink} />;
  }

  if (PREVIEW_AS_IMAGE.includes(preview.mimeType)) {
    return (
      <div className={classes.previewInnerContainer}>
        <ImagePreview imageUrl={preview.url} onImageError={() => setPreviewError(true)} />
      </div>
    );
  }

  if (PREVIEW_WITH_MARKDOWN_READER.includes(preview.mimeType)) {
    return <MarkdownPreview contentUrl={preview.url} />;
  }

  return (
    <iframe
      allow-same-origin="true"
      allowFullScreen
      className={classes.previewIframe}
      onError={() => setPreviewError(true)}
      src={preview.url}
      title="File Preview"
    />
  );
};

interface FilePreviewProps {
  accessLink?: string;
  documentId: string;
}
