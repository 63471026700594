import { combineReducers, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension/developmentOnly';

import { reducer as desktopAppDialogReducer } from './dekstop-app-dialog';
import { reducer as filtersReducer } from './filters';
import { reducer as notificationsReducer } from './notifications';
import { reducer as preconfiguredSourcesReducer } from './preconfigured-sources';
import { reducer as previewsReducer } from './previews';
import { reducer as searchReducer } from './search';
import { reducer as supportReducer } from './support';
import { AppState } from './types';
import { reducer as userReducer } from './user';

const reducer = combineReducers<AppState>({
  desktopAppDialog: desktopAppDialogReducer,
  filters: filtersReducer,
  notifications: notificationsReducer,
  preconfiguredSources: preconfiguredSourcesReducer,
  previews: previewsReducer,
  search: searchReducer,
  support: supportReducer,
  user: userReducer,
});

export const store = createStore(
  reducer,
  devToolsEnhancer({
    trace: true,
  }),
);

export { Actions } from './actions';
export { useDispatch, useSelector } from './utils';
