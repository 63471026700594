/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      container: {
        padding: '12px 12px 0px 12px',
      },
      faqLink: { color: '#245EE5', fontWeight: 600 },
      helperIcon: { '& > img': { width: '60px' }, padding: '12px 24px 12px 0px' },
      helperText: {},
      helperTextContainer: { alignItems: 'center', display: 'flex' },
      iconButton: {
        color: '#FAD271',
        padding: '4px',
      },
      reconnectButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '16px',
      },
    },
    { index: 1 },
  )();
