import { ConnectorSyncStatus } from '@outmind/types';
import React, { useState } from 'react';

import { useConnectorsSyncStates, useTranslations } from '../../hooks';
import { CloseIcon, IconButton, Typography } from '../../material';
import { Actions, useDispatch } from '../../store';
import { useStyles } from './styles';

/**
 * Displays an helper when some sources are still being synchronized
 */
export const SourcesStillSyncingHelper: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [isHidden, setIsHidden] = useState(false);

  const { data: connectorStates = {} } = useConnectorsSyncStates();

  const oneIsSyncing = Object.values(connectorStates).reduce(
    (bool, syncState) =>
      bool ||
      syncState.status === ConnectorSyncStatus.InProgress ||
      syncState.status === ConnectorSyncStatus.Starting,
    false,
  );

  const { t } = useTranslations();

  if (oneIsSyncing && !isHidden) {
    return (
      <div className={classes.helperContainer}>
        <Typography className={classes.helperTextContainer}>
          <span className={classes.helperText}>{t('one_source_syncing')}</span>
          <span
            className={classes.refreshButton}
            onClick={() => dispatch(Actions.refreshSearch())}
            onKeyPress={() => dispatch(Actions.refreshSearch())}
            role="button"
            tabIndex={0}
          >
            {t('reload')}
          </span>
        </Typography>

        <IconButton
          className={classes.hideHelperButton}
          onClick={() => setIsHidden(true)}
          size="small"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
    );
  }

  return null;
};
