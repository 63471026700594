import { useEffect, useRef } from 'react';

/**
 * Hooks used to kmow the previous value of the provided input
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
