/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      buttonLoader: { margin: 'auto', position: 'absolute' },
      colorBubblesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '250px',
      },
      confirmButton: {
        padding: '16px 92px 16px 92px',
      },
      confirmButtonBorder: {
        border: '1px solid #245EE5',
      },
      confirmButtonContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
        marginTop: '10px',
      },
      confirmButtonLabel: {
        fontWeight: 600,
      },
      formContainer: {
        width: '450px',
      },
      formControl: {
        width: '100%',
      },
      formLabel: {
        marginBottom: '10px',
      },
      labelColorSelectorContainer: {
        marginTop: '20px',
      },
      labelNameInput: {
        border: '#ccc',
      },
      labelNameInputRoot: {
        '&&': {
          '&& .MuiFormHelperText-root.Mui-error': {
            bottom: '-20px',
            paddingLeft: 0,
            position: 'absolute',
          },
          '&& .MuiOutlinedInput-notchedOutline': { borderColor: '#ccc' },
        },
      },
    },
    { index: 1 },
  )();
