/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      iconButtonContainer: {
        marginLeft: '4px',
      },
      itemIcon: {
        minWidth: '36px',
      },
      moreOptionButton: {
        '&:hover': { background: '#d0defb' },
        borderRadius: '5px',
        padding: '2px',
      },
    },
    { index: 1 },
  )();
