const sourceLogosFolder = require.context('../../assets/connectors/', false, /\.png/);
const sourceLogosUrl = {};

sourceLogosFolder.keys().forEach((fileName) => {
  const type = /\.\/([a-z0-9]+)\.png/.exec(fileName)?.[1];

  if (!type) throw new Error(`Incorrect file "${fileName}" provided as connector logo asset`);

  sourceLogosUrl[type] = sourceLogosFolder(fileName).default;
});

export { sourceLogosUrl };
