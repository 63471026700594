import { makeStyles } from '../../material';

export const useStyles = makeStyles(
  {
    inResponsive: {
      display: 'none',
    },
  },
  { index: 1 },
);
