import { makeStyles } from '../../material';

const useStyles = makeStyles(
  {
    helperContainer: {
      alignItems: 'center',
      backgroundColor: '#eeeeee',
      color: '#888888',
      display: 'flex',
      flexGrow: 1,
      height: 50,
      justifyContent: 'center',
      marginBottom: '8px',
      position: 'relative',
    },
    helperText: {
      marginRight: '4px',
    },
    helperTextContainer: {
      padding: '12px',
    },
    hideHelperButton: {
      position: 'absolute',
      right: '20px',
    },
  },
  { index: 1 },
);

export { useStyles };
