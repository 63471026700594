import { DocumentIndiceNames, SearchableDocuments } from '@outmind/types';
import { Star } from '@styled-icons/fluentui-system-regular/Star';
import React, { memo } from 'react';

import {
  Label,
  useSearch,
  useSpellingSuggestionsIsFetching,
  useTranslations,
} from '../../../../hooks';
import { Grid } from '../../../../material';
import { Actions, useDispatch } from '../../../../store';
import { DocumentSkeleton } from '../../../DocumentSkeleton';
import { LabeledBox } from '../../../LabeledBox';
import { ResultsList } from '../../../ResultsList';
import { useStyles } from './styles';

const HomeTabBookmarkSectionNP: React.FC<HomeTabBookmarkSectionProps> = ({ index, bookmark }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { results, isLoading } = useSearch(index, { bookmarkId: bookmark.id });

  const isSpellingSuggestionsFetching = useSpellingSuggestionsIsFetching();

  const { t } = useTranslations();

  const slicedSectionResults = results.slice(0, DISPLAYED_BOOKMARKS_NUMBER);

  const filterByLabel = (): void => {
    dispatch(Actions.addLabelFilter(bookmark));
    dispatch(Actions.setChosenIndexTab(DocumentIndiceNames.Files));
  };

  if (isLoading || isSpellingSuggestionsFetching) {
    return (
      <LabeledBox
        chevron
        icon={<Star className={classes.titleIcon} size="20" />}
        seeAll={filterByLabel}
        title={t('my_favorite_files')}
      >
        <Grid>
          {[...Array(3)].map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <DocumentSkeleton key={i} bookmark index={index} />
          ))}
        </Grid>
      </LabeledBox>
    );
  }

  if (slicedSectionResults.length) {
    return (
      <LabeledBox
        chevron
        icon={<Star className={classes.titleIcon} size="20" />}
        seeAll={filterByLabel}
        title={t('my_favorite_files')}
      >
        <ResultsList bookmarkView={!!bookmark} results={slicedSectionResults} tab="favorite" />
      </LabeledBox>
    );
  }
  return null;
};

interface HomeTabBookmarkSectionProps {
  bookmark: Label;
  index: SearchableDocuments;
}

const DISPLAYED_BOOKMARKS_NUMBER = 10;

export const HomeTabBookmarkSection = memo(HomeTabBookmarkSectionNP);
