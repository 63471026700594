import { makeStyles } from '../../material';

const listItemBaseStyle = { color: '#707070', fontSize: '1.1rem' };

const useStyles = makeStyles(
  {
    addIcon: {
      fontSize: '12px',
      position: 'absolute',
      right: '4px',
      top: '-6px',
    },
    itemIcon: listItemBaseStyle,
    itemText: listItemBaseStyle,
    listItemIcon: {
      minWidth: 'unset',
      paddingRight: '8px',
      position: 'relative',
    },
    menuItem: {
      padding: '2px 16px',
    },
    separator: {
      background: '#E9E9E9',
      height: '1px',
      margin: '8px 0',
      width: '100%',
    },
    styleMenuPaper: {
      minWidth: '200px',
    },
  },
  { index: 1 },
);

export { useStyles };
