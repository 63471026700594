import { SearchCardsResult } from '@outmind/types';
import React, { useState } from 'react';

import { useLinkHandler } from '../../hooks';
import { Paper } from '../../material';
import { ContextMenu } from '../ContextMenu';
import { DisplayOnComputerOnly } from '../DisplayOnComputerOnly';
import { DocumentBoard } from './DocumentBoard';
import { DocumentContent } from './DocumentContent';
import { DocumentDate } from './DocumentDate';
import { DocumentHeader } from './DocumentHeader';
import { useStyles } from './styles';

/**
 * Displays an ElasticCard document
 */
export const Card: React.FC<CardProps> = ({ result }) => {
  const { document, highlights } = result;

  const classes = useStyles();

  const [isSelected, setIsSelected] = useState(false);

  const [mouseCoordinates, setMouseCoordinates] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({
    mouseX: null,
    mouseY: null,
  });

  const goToCard = useLinkHandler(document.accessLink);

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (!mouseCoordinates.mouseX && !mouseCoordinates.mouseY) {
      setMouseCoordinates({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setIsSelected(true);
    }
  };

  const handleMenuClose = (): void => {
    setIsSelected(false);
    setMouseCoordinates({
      mouseX: null,
      mouseY: null,
    });
  };

  const onclickHandler = (): (() => void) | undefined => {
    if (mouseCoordinates.mouseX && mouseCoordinates.mouseY) return;
    return goToCard;
  };

  const displayedBoardName = highlights?.boardName || document.boardName;
  const displayedContent = highlights?.content || document.summary;
  const displayedTitle = highlights?.title || document.title;

  const isCreatedDate = document.date === document.createdDate;
  const isLastModifiedDate = document.date === document.lastModifiedDate;

  return (
    <Paper
      className={isSelected ? classes.documentContainerClicked : classes.documentContainer}
      elevation={0}
      onClick={onclickHandler()}
      onContextMenu={handleRightClick}
    >
      <DocumentHeader
        documentId={document.id}
        labels={document.labels}
        source={document.source}
        title={displayedTitle}
      />
      <div className={classes.resultElementMetas}>
        <DocumentBoard boardName={displayedBoardName} />
      </div>
      <DocumentContent content={displayedContent} />
      <DisplayOnComputerOnly>
        <DocumentDate
          date={document.date}
          isCreatedDate={isCreatedDate}
          isLastModifiedDate={isLastModifiedDate}
        />
      </DisplayOnComputerOnly>
      {mouseCoordinates.mouseX && mouseCoordinates.mouseY ? (
        <ContextMenu
          documentId={document.id}
          mouseX={mouseCoordinates.mouseX}
          mouseY={mouseCoordinates.mouseY}
          onClose={handleMenuClose}
          onOpen={goToCard}
        />
      ) : null}
    </Paper>
  );
};

interface CardProps {
  result: SearchCardsResult;
}
