import { LabelInterface } from '@outmind/types';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { ApiQuery, useApiRouteMaker } from '../useApi';
import { Label } from './label';

/**
 * Retrieves all the Labels
 */
export const useAllLabels = (): UseQueryResult<Record<string, Label>> => {
  const makeRoute = useApiRouteMaker(ApiQuery.GET_LABELS);

  const getLabelsQuery = useQuery<Record<string, Label>>(
    ApiQuery.GET_LABELS,
    async () => {
      const route = makeRoute();

      const result = await axios({
        method: route.method,
        url: route.url,
        withCredentials: true,
      });

      return result.data.reduce(
        (labels, label: LabelInterface) => ({
          ...labels,
          [label.id]: new Label(label, result.data),
        }),
        {},
      );
    },
    {
      staleTime: 0,
    },
  );

  return getLabelsQuery;
};

/**
 * Retrieves a set of Labels
 */
export const useLabels = (labelIds: string[]): UseQueryResult<Label[]> => {
  const { data: allLabels = {}, ...rest } = useAllLabels();

  const labels = labelIds.map((labelId) => allLabels[labelId]).filter((label) => !!label);

  return { data: labels, ...rest } as UseQueryResult<Label[]>;
};
