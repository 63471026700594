/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

export const useStyles = (props?: SearchBarStylingProps) => {
  const theme = useTheme();

  const defaultSearchBar = {
    alignItems: 'center',
    backgroundColor: props?.inSpotlight ? 'white' : theme.designSystem.colors.background.main,
    borderRadius: props?.inSpotlight ? '8px' : '15px',
    color: theme.designSystem.colors.secondary.main,
    display: 'flex',
  };

  return makeStyles(
    {
      chipContainer: {
        marginLeft: '8px',
      },
      iconButton: {
        padding: '4px',
      },
      searchBar: {
        color: theme.designSystem.colors.secondary.main,
        flex: 1,
        fontSize: props?.hasBigHeight ? 20 : undefined,
      },
      searchBarActive: {
        backgroundColor: '#E0E0E0',
      },
      searchBarAdornment: {
        margin: '0 14px',
      },
      searchBarContainer: {
        alignItems: 'center',
        display: 'block',
        marginLeft: props?.inSpotlight ? undefined : '20px',
        maxWidth: props?.fullWidth ? undefined : '600px',
        position: 'relative',
      },
      searchBarEndAdornment: { marginRight: '12px' },
      searchBarPaper: {
        ...defaultSearchBar,
        height: props?.hasBigHeight ? '65px' : '50px',
        paddingRight: '8px',
        transition: 'background-color 0.5s ease',
      },
      searchBarFocus: {
        backgroundColor: 'white',
        borderBottomLeftRadius: props?.withSuggestions ? '0px' : undefined,
        borderBottomRightRadius: props?.withSuggestions ? '0px' : undefined,
        borderRadius: props?.isSharp ? undefined : '10px',
      },
    },
    { index: 1 },
  )(props);
};

export interface SearchBarStylingProps {
  fullWidth?: boolean;
  hasBigHeight?: boolean;
  inSpotlight?: boolean;
  isSharp?: boolean;
  withSuggestions?: boolean;
}
