/**
 * Returns the abbreviated string related to the input number
 *
 * ```typescript
 * // Input
 * const value = 25000;
 * // Output
 * const abbrValue = abbreviateNumber(value) = "25K";
 * ```
 */
export function abbreviateNumber(value: number): string {
  const newValue = value;
  if (value >= 1000) {
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixNum = Math.floor(`${value}`.length / 3);
    let shortValue = 0;
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(precision),
      );
      const dotLessShortValue = shortValue.toString().replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 3) break;
    }
    if (shortValue % 1 !== 0) return `${shortValue.toFixed(1)}${suffixes[suffixNum]}`;
    return `${shortValue}${suffixes[suffixNum]}`;
  }
  return newValue.toString();
}
