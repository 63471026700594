import { UseQueryResult } from 'react-query';

import { Label } from './label';
import { useAllLabels } from './useLabels';

export const useLabelsTree = (): UseQueryResult<Label[]> => {
  const { data: labels = {}, ...rest } = useAllLabels();

  const labelsTree: Label[] = Object.values(labels).reduce((_labelsTree: Label[], label: Label) => {
    if (!label.parentId) return [..._labelsTree, label];

    const parentLabel = labels[label.parentId];
    if (parentLabel) parentLabel.addChild(label);

    return _labelsTree;
  }, [] as Label[]);

  return { data: labelsTree, ...rest } as UseQueryResult<Label[]>;
};
