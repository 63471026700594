/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = () =>
  makeStyles(
    {
      colorPrimary: {
        borderColor: '#2b90e9',
        color: '#2b90e9',
        fontWeight: 600,
      },
      createCallToAction: {
        color: '#333',
        fontWeight: 600,
        marginRight: '12px',
      },
      createContainer: {
        alignItems: 'center',
        display: 'flex',
        padding: '4px 12px',
      },
      createTag: {
        margin: '4px 12px 4px 0px',
      },
      inputContainer: {
        borderBottom: '1px solid #EAEAEA',
        padding: '0 12px 8px 12px',
      },
      inputRoot: { width: '100%' },
      labelsFilterContainer: {
        padding: '12px 12px 0px 12px',
      },
      menuContainer: {
        width: '450px',
      },
      noLabelFoundTip: { color: '#333', fontSize: '0.8rem' },
      selectOrCreateTip: {
        color: '#333',
        fontSize: '0.8rem',
        fontWeight: 600,
        marginBottom: '4px',
      },
    },
    { index: 1 },
  )();
