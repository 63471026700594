import { useUserProfile } from '../useUserProfile';

/**
 * Retrieves the logged in state of the user
 */
export const useLoggedIn = (): boolean => {
  const { data: user, isLoading, isError } = useUserProfile();

  return !isError && (isLoading || !!user);
};
