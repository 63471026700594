import React from 'react';

import { MailContentSkeleton } from './MailContentSkeleton';
import { MailHeaderSkeleton } from './MailHeaderSkeleton';
import { useStyles } from './styles';

export const MailSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <MailHeaderSkeleton />
      <MailContentSkeleton />
    </div>
  );
};
