/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../material';

export const useStyles = () =>
  makeStyles(
    {
      checkIcon: {
        color: '#4cd137',
      },
      learnMoreContainer: {
        alignItems: 'center',
        display: 'flex',
        marginTop: '40px',
      },
      learnMoreLink: { fontWeight: 600, marginLeft: '20px' },
      privacyReminderContainer: {
        color: '#666',
      },
      privacyReminderTitle: {
        fontWeight: 600,
        whiteSpace: 'nowrap',
      },
      reminderContainer: {
        display: 'flex',
        marginBottom: '30px',
      },
      reminderElementsContainer: {
        maxWidth: '280px',
        paddingTop: '40px',
      },
      reminderText: {
        fontSize: '15px',
        marginLeft: '20px',
      },
      reminderTitle: {
        fontWeight: 600,
      },
    },
    { index: 1 },
  )();
