import { Source } from '@outmind/types';
import React from 'react';

import SadFolderNoBgImgSrc from '../../assets/sad-folder-no-bg.png';
import { useTranslations } from '../../hooks';
import { Typography } from '../../material';
import { useSelector } from '../../store';
import { OpenInSourceButton } from '../DocumentPreview/HeaderPreview/OpenInSourceButton';
import { useStyles } from './styles';

export const NoPreviewFoundBackground: React.FC<NoPreviewFoundBackgroundProps> = ({
  accessLink,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const previewInfos = useSelector((s) => s.previews.infos);

  return (
    <div className={classes.noPreviewMainContainer}>
      <div className={classes.messageContainer}>
        <img alt="" className={classes.noPreviewBackgroundImage} src={SadFolderNoBgImgSrc} />
        <Typography className={classes.noPreviewtext} color="textPrimary">
          {t('no_preview_found')}
        </Typography>
        <div className={classes.noPreviewButtonContainer}>
          <OpenInSourceButton
            accessLink={accessLink}
            isLocalFile={
              previewInfos?.document.source === Source.LocalDisk ||
              previewInfos?.document.source === Source.NetworkDisk
            }
          />
        </div>
      </div>
    </div>
  );
};

interface NoPreviewFoundBackgroundProps {
  accessLink?: string;
}
