import { makeStyles } from '../../material';

const signinHelperBasic = {
  color: '#757575',
  fontSize: '1.125rem',
  marginBottom: 20,
  marginTop: 30,
  textAlign: 'center' as const,
};
const signupHelperBasic = {
  color: '#757575',
  fontSize: '1rem',
  marginTop: 30,
  textAlign: 'center' as const,
};
const signinTitleBasic = {
  color: '#757575',
  fontSize: '2rem',
  textAlign: 'center' as const,
};

export const useStyles = makeStyles(
  {
    desktopSignInButton: {
      '&:hover': { background: '#245EE5', color: 'white' },
      border: '1px solid #245EE5',
      borderRadius: '25px',
      color: '#245EE5',
      textTransform: 'initial',
    },
    imageLogoContainer: {
      '&>img': { width: '120px' },
      marginBottom: '45px',
      marginTop: '32px',
    },
    signinHelper: signinHelperBasic,
    signinHelperMobile: {
      ...signinHelperBasic,
      fontSize: '1rem',
    },
    signinTitle: signinTitleBasic,
    signinTitleMobile: {
      ...signinTitleBasic,
      fontSize: '1.5rem',
    },
    signupHelper: signupHelperBasic,
    signupHelperMobile: {
      ...signupHelperBasic,
      fontSize: '0.875rem',
    },
    spotlightView: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    spotlightViewContainer: {
      alignItems: 'center',
      backgroundColor: 'white',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
    },
  },
  { index: 1 },
);
