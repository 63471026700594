import { SearchableDocuments, SearchResult } from '@outmind/types';
import React, { useMemo } from 'react';

import { Document } from '../Document';

export const ResultsList: React.FC<ResultsListProp> = ({ results, tab, ...props }) => {
  const displayedContent = useMemo(
    () => (
      <>
        {results.map((result, idx) => (
          <Document
            key={result.document.id}
            index={`${tab}-${idx + 1}`}
            result={result}
            {...props}
          />
        ))}
      </>
    ),
    [JSON.stringify(results)],
  );
  return displayedContent;
};

interface ResultsListProp {
  bookmarkView?: boolean;
  results: SearchResult[];
  tab: SearchableDocuments | 'home' | 'favorite';
}
