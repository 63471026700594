import {
  DocumentIndiceNames,
  SearchFilesResult,
  SearchPeopleResult,
  SearchPrestationsResult,
} from '@outmind/types';
import React, { memo, useState } from 'react';

import { TieIcon } from '../../../assets/icons/tie';
import { useTranslations } from '../../../hooks';
import {
  Button,
  ButtonBase,
  Divider,
  FilesIcon,
  MissionsIcon,
  Tooltip,
  Typography,
} from '../../../material';
import { Actions, useDispatch, useSelector } from '../../../store';
import { CustomDialog, Document } from '../..';
import { ContactPersonButtons } from '../../ContactPersonButtons';
import { useStyles } from './styles';

const DocMatchedModalNP: React.FC<DocMatchedModalProps> = ({ result, variant }) => {
  const {
    creatorOfCount,
    document: { email, givenName },
    responsibleOfCount,
    workedOnCount,
  } = result;

  const query = useSelector((s) => s.search.q);

  const dispatch = useDispatch();

  const classes = useStyles();

  const { t } = useTranslations();

  const [docMatchedDialogIsOpen, setDocMatchedDialogIsOpen] = useState(false);

  const displayName = givenName || email;

  const getDocMatchedData = (): {
    docMatchedCount: number;
    docMatchedDialogTitle: string;
    documentsMatched: SearchFilesResult[] | SearchPrestationsResult[];
    tooltipIcon: JSX.Element;
    tooltipText: string;
  } => {
    switch (variant) {
      case 'creatorOf':
        return {
          docMatchedCount: creatorOfCount,
          docMatchedDialogTitle: t('persons_documents_on_this_subject', { displayName }),
          documentsMatched: result.creatorOfMatched,
          tooltipIcon: <FilesIcon className={classes.docMatchCountIcon} />,
          tooltipText: t('person_creator_of', { count: creatorOfCount, displayName }),
        };
      case 'responsibleOf':
        return {
          docMatchedCount: responsibleOfCount,
          docMatchedDialogTitle: t('missions_sold_on_this_subject', { displayName }),
          documentsMatched: result.responsibleOfMatched,
          tooltipIcon: (
            <TieIcon className={classes.docMatchCountIcon} fill="#444" height="17" width="17" />
          ),
          tooltipText: t('person_responsible_of', { count: responsibleOfCount, displayName }),
        };
      case 'workedOn':
        return {
          docMatchedCount: workedOnCount,
          docMatchedDialogTitle: t('missions_staffed_on_this_subject', { displayName }),
          documentsMatched: result.workedOnMatched,
          tooltipIcon: <MissionsIcon className={classes.docMatchCountIcon} />,
          tooltipText: t('person_worked_on', { count: workedOnCount, displayName }),
        };
      default:
        throw new Error(
          `Variant value: "${variant}" is unknown expected: creatorOf | workedOn | responsibleOf `,
        );
    }
  };

  const docMatchedData = getDocMatchedData();
  const { docMatchedCount, docMatchedDialogTitle, documentsMatched, tooltipIcon, tooltipText } =
    docMatchedData;

  const documentsMatchedElements = documentsMatched.map((document, idx) => (
    <Document fromSearchPerson index={`doc-matched-${idx + 1}`} result={document} />
  ));

  const documentsNotDisplayedCount = docMatchedCount - documentsMatched.length;
  const documentsNotDisplayedHelperText = t('at_least_n_other_documents', {
    count: documentsNotDisplayedCount,
  });
  const documentsNotDisplayedHelper =
    documentsNotDisplayedCount > 0 ? (
      <div className={classes.documentsNotDisplayedHelper}>
        <Typography className={classes.documentsNotDisplayedHelperText}>
          {documentsNotDisplayedHelperText}
        </Typography>
        <Button
          color="primary"
          onClick={() => {
            dispatch(Actions.addPersonFilter(result.document));
            dispatch(Actions.setSearchUserQuery(query));
            dispatch(Actions.setChosenIndexTab(DocumentIndiceNames.Files));
          }}
          variant="outlined"
        >
          {t('see_all_shared_items')}
        </Button>
      </div>
    ) : null;

  const docMatchedTooltip = docMatchedCount ? (
    <>
      <Tooltip className={classes.docMatchCountTooltip} title={tooltipText}>
        <ButtonBase
          className={classes.docMatchCount}
          onClick={() => setDocMatchedDialogIsOpen(true)}
        >
          <span className={classes.docMatchCountText}>
            {docMatchedCount}
            <span>+</span>
          </span>
          {tooltipIcon}
        </ButtonBase>
      </Tooltip>
      <CustomDialog
        classes={{ paper: classes.documentsMatchedDialog }}
        classNames={{ content: classes.documentsMatchedDialogContent }}
        onClose={() => setDocMatchedDialogIsOpen(false)}
        open={docMatchedDialogIsOpen}
        title={docMatchedDialogTitle}
      >
        <div className={classes.documentsMatchedContainer}>
          {documentsMatchedElements}
          {documentsNotDisplayedHelper}
        </div>
        <div className={classes.docMatchedModalDivider}>
          <Divider />
        </div>
        <div className={classes.contactPersonHelperContainer}>
          <Typography className={classes.contactPersonHelperText}>
            {t('contact_person', { displayName })}
          </Typography>
          <div className={classes.contactPersonButtonsContainer2}>
            <ContactPersonButtons
              alwaysEmail
              alwaysPhone
              email={result.document.email}
              phoneNumber={result.document.mobilePhone}
            />
          </div>
        </div>
      </CustomDialog>
    </>
  ) : null;

  return docMatchedTooltip;
};

interface DocMatchedModalProps {
  result: SearchPeopleResult;
  variant: 'creatorOf' | 'workedOn' | 'responsibleOf';
}

export const DocMatchedModal = memo(DocMatchedModalNP);
