import { Connector, Source } from '@outmind/types';
import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { Actions, useDispatch } from '../../../store';
import { ApiQuery, useApiRouteMaker } from '../../useApi';

/**
 * Hook used for creating a new connector based on the provided payload
 */
export const useDeleteConnectors = (): UseMutationResult<
  void,
  never,
  Params$DeleteConnectorPayload[]
> => {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const makeRoute = useApiRouteMaker(ApiQuery.DELETE_SOURCE);

  const deleteConnectorMutation = useMutation<void, never, Params$DeleteConnectorPayload[]>(
    async (connectors): Promise<void> => {
      const deleteConnectorPromises = connectors.map(async ({ id, source }) => {
        const route = makeRoute({
          connectorId: id,
          source,
        });

        dispatch(Actions.notifyConnectorDeletionStarted());

        await axios({
          method: route.method,
          url: route.url,
          withCredentials: true,
        });

        dispatch(Actions.notifyConnectorDeletionSuccess(source));
      });

      await Promise.all(deleteConnectorPromises);
    },
    {
      onMutate: async (deletedConnectors) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(ApiQuery.GET_MY_CONNECTORS);
        // Snapshot the previous value
        const connectors = queryClient.getQueryData<Connector[]>(ApiQuery.GET_MY_CONNECTORS) ?? [];
        // Optimistically update to the new value
        const deleteConnectorIds = deletedConnectors.map(({ id }) => id);
        queryClient.setQueryData<Connector[]>(ApiQuery.GET_MY_CONNECTORS, () =>
          connectors.filter(({ id }) => !deleteConnectorIds.includes(id)),
        );
      },
      onSettled: () => {
        // Always refetch after error or success
        queryClient.invalidateQueries(ApiQuery.GET_MY_CONNECTORS);
      },
    },
  );

  return deleteConnectorMutation;
};

interface Params$DeleteConnectorPayload {
  id: string;
  source: Source;
}
