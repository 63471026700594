import {
  ArrowSort,
  ArrowSortDown,
  ArrowSortUp,
  CaretDown,
} from '@styled-icons/fluentui-system-filled';
import React, { useState } from 'react';

import { useTranslations } from '../../../hooks';
import { TranslationKey } from '../../../locales/types';
import { FormControl, MenuItem, Select, SelectProps, Typography } from '../../../material';
import { Actions, useDispatch, useSelector } from '../../../store';
import { SortByOption } from '../../../store/search/types';
import { SortByIcon } from './SortByIcon';
import { useStyles } from './styles';
/**
 * SortBySelect lets the user provide a sorting option to his query
 */
export const SortBySelect: React.FC<SelectProps> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const chosenSortBy = useSelector((s) => s.search.chosenSortBy);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const { t } = useTranslations();

  const setChosenSortBy = (sortBy: SortByOption): void => {
    dispatch(Actions.setChosenSortBy(sortBy));
  };

  const sortByValues = [
    {
      icon: <ArrowSort className={classes.sortItemIcon} size={16} />,
      translationKey: 'relevance',
      value: 'relevance',
    },
    {
      icon: <ArrowSortUp className={classes.sortItemIcon} size={16} />,
      translationKey: 'date_newest_oldest',
      value: 'date-desc',
    },
    {
      icon: <ArrowSortDown className={classes.sortItemIcon} size={16} />,
      translationKey: 'date_oldest_newest',
      value: 'date-asc',
    },
  ];

  return (
    <FormControl className={classes.formControlElement}>
      <Typography className={classes.sectionTitle}>{t('sort_by')}</Typography>
      <Select
        {...props}
        classes={{
          icon: classes.dropDownIcon,
          select: classes.selectElement,
          selectMenu: classes.selectItem,
        }}
        className={classes.sortBy}
        IconComponent={CaretDown}
        MenuProps={{ classes: { list: classes.menuList, paper: classes.menu } }}
        onChange={(e) => setChosenSortBy(e.target.value as SortByOption)}
        onClose={() => setIsSelectOpen(false)}
        onOpen={() => setIsSelectOpen(true)}
        value={chosenSortBy}
        variant="outlined"
      >
        {sortByValues.map((sortByItem, idx) => (
          <MenuItem
            key={sortByItem.value}
            classes={{ selected: classes.selectedItem }}
            className={classes.selectItem}
            value={sortByItem.value}
          >
            <div className={classes.selectItemContainer} id={`select-item-container-${idx}`}>
              <div className={classes.sortItemIconContainer} id={`sort-item-icon-container-${idx}`}>
                <SortByIcon
                  chosenSortBy={chosenSortBy}
                  currentSortBy={sortByItem.value}
                  icon={sortByItem.icon}
                  isSelectOpen={isSelectOpen}
                />
              </div>
              <Typography className={classes.sortItemText}>
                {t(sortByItem.translationKey as TranslationKey)}
              </Typography>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
