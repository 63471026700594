/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../../material';

export const useStyles = () =>
  makeStyles(
    {
      allTabContainer: {
        display: 'block',
        marginTop: '18px',
        position: 'relative',
        width: '100%',
      },
      titleIcon: {
        marginBottom: '4px',
        marginRight: '8px',
      },
    },
    { index: 1 },
  )();
