import React, { useState } from 'react';

import { FolderIcon, Tooltip } from '../../../material';
import { useStyles } from '../styles';
import { Tree } from './Tree';

/**
 * Displays the arborescence of the ElasticDoc from its "parents" property
 */
export const DocumentArborescence: React.FC<ArborescenceElementProps> = ({
  containerWidth,
  parents,
}) => {
  if (!parents.length) return null;

  const [isHovered, setIsHovered] = useState(false);

  const classes = useStyles();

  return (
    <Tooltip
      open={isHovered}
      placement="bottom"
      title={parents.join(' / ').replace(/<span class="highlighted">(.+?)<\/span>/g, '$1')}
    >
      <div
        className={classes.resultElementMeta}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ maxWidth: '100%' }}
      >
        <FolderIcon className={classes.resultElementMetaIcon} />
        <Tree containerWidth={containerWidth} pathArr={parents} />
      </div>
    </Tooltip>
  );
};

interface ArborescenceElementProps {
  containerWidth?: number;
  parents: string[];
}
