import React from 'react';

import { Box, Card, Grid, Skeleton } from '../../../material';

export const PageSkeleton: React.FC = () => (
  <Grid item md={4}>
    <Card style={{ display: 'flex', margin: '16px' }}>
      <Box width={340}>
        <Skeleton height={220} variant="rect" width={600} />
        <Box mx={1.5} my={2} pb={1.5} pt={0.5}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </Box>
    </Card>
  </Grid>
);
