import React from 'react';

import { useLinkHandler, useTranslations } from '../../../hooks';
import { Button, GoToIcon } from '../../../material';
import { useStyles } from '../styles';

export const OpenInSourceButton: React.FC<OpenInSourceButtonProps> = ({
  accessLink,
  isLocalFile,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const openInSource = useLinkHandler(accessLink, { isLocalFile, targetBlank: true });

  const openFileInSource = (e: React.MouseEvent): void => {
    e.stopPropagation();
    openInSource();
  };

  return (
    <div className={classes.openInSourceButtonContainer}>
      <Button
        classes={{ label: classes.openInSourceButtonText }}
        color="primary"
        disabled={!accessLink}
        endIcon={<GoToIcon />}
        onClick={openFileInSource}
        variant="text"
      >
        {t('open')}
      </Button>
    </div>
  );
};

interface OpenInSourceButtonProps {
  accessLink?: string;
  isLocalFile?: boolean;
}
