import { sourcesProvideIndex } from '@outmind/helpers';
import { DocumentIndicesExtended } from '@outmind/types';
import classnames from 'classnames';
import React from 'react';

import { indexOrder } from '../../config';
import { useAllSearch, useConnectors, useDimensions, useTranslations } from '../../hooks';
import { TranslationKey } from '../../locales/types';
import { Tab, Tabs } from '../../material';
import { Actions, useDispatch, useSelector } from '../../store';
import { abbreviateNumber } from '../../util';
import { IndexIcon } from '../IndexIcon';
import { useStyles } from './styles';

/**
 * Minimal size empirically determined for one `Tab` to be fully displayed
 */
const MIN_SIZE_PER_TAB = 155;

export const IndexTabs: React.FC<IndexTabsProps> = ({ resetScroll }) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const [tabsRef, tabsDimensions] = useDimensions<HTMLButtonElement>();

  const { chosenIndexTab } = useSelector((s) => s.search);

  const allSearchResponses = useAllSearch();

  const { data: connectors = [] } = useConnectors();

  const sources = connectors.map((connector) => connector.source);

  const dispatch = useDispatch();

  const onChangeTypeTab = (
    _: React.ChangeEvent<unknown>,
    typeTab: DocumentIndicesExtended,
  ): void => {
    resetScroll();
    dispatch(Actions.setChosenIndexTab(typeTab));
  };

  const displayedIndices = indexOrder.filter((indexTab) => {
    if (indexTab === '*' || sourcesProvideIndex(sources, indexTab)) return true;
    return false;
  });

  if (displayedIndices.length === 1) return null;

  const withAllLabels = (tabsDimensions?.width || 0) / displayedIndices.length > MIN_SIZE_PER_TAB;

  const getResultsLabel = (indexTab: DocumentIndicesExtended): string => {
    if (indexTab === '*') return ' ';

    const { isLoading, total, totalAccuracy } = allSearchResponses[indexTab];

    if (isLoading) return '...';

    if (totalAccuracy === 'eq') return `${total}`;

    return `${abbreviateNumber(total)}+`;
  };

  const tabsClasses = {
    '*': classes.indexTabAllIcon,
    accounts: classes.indexTabAccountIcon,
    cards: classes.indexTabCardIcon,
    contacts: classes.indexTabContactIcon,
    files: classes.indexTabFileIcon,
    mails: classes.indexTabMailIcon,
    messages: classes.indexTabMessageIcon,
    missions: classes.indexTabMissionIcon,
    opportunities: classes.indexTabOpportunityIcon,
    pages: classes.indexTabPageIcon,
    people: classes.indexTabPersonIcon,
  };

  const indexTabs = displayedIndices.map((indexTab) => {
    const indiceFR = t(`index_name_${indexTab}` as TranslationKey);

    const searchLabel = indexTab === chosenIndexTab || withAllLabels ? indiceFR : undefined;

    const label = (
      <>
        <span>{searchLabel || ''}</span>
        <span className={searchLabel ? classes.searchTotalLabel : undefined}>
          {getResultsLabel(indexTab)}
        </span>
      </>
    );

    return (
      <Tab
        key={indexTab}
        classes={{
          labelIcon: classes.indexTabRoot,
          root: classes.indexTabLabelContainer,
          selected: tabsClasses[indexTab],
          textColorInherit: classes.textColorInherit,
          wrapper: classes.indexTabWrapper,
        }}
        disableRipple
        icon={
          chosenIndexTab === indexTab ? (
            <IndexIcon index={indexTab} type="filled" />
          ) : (
            <IndexIcon index={indexTab} type="default" />
          )
        }
        label={label}
        value={indexTab}
      />
    );
  });

  return (
    <Tabs
      ref={tabsRef}
      centered
      className={classes.tabs}
      indicatorColor="primary"
      onChange={onChangeTypeTab}
      TabIndicatorProps={{
        className: classnames(tabsClasses[chosenIndexTab], classes.tabIndicator),
        id: 'ActiveTabIndicator',
      }}
      textColor="inherit"
      value={chosenIndexTab}
      variant="fullWidth"
    >
      {indexTabs}
    </Tabs>
  );
};

interface IndexTabsProps {
  resetScroll: () => void;
}
