import { ElasticPerson } from '@outmind/types';
import { Person as PersonFilled } from '@styled-icons/fluentui-system-filled/Person';
import { Person as PersonRegular } from '@styled-icons/fluentui-system-regular/Person';
import React from 'react';

import { ListItem } from '../../../../material';
import { PersonAvatar, SanitizedHtmlTypography } from '../../..';
import { useStyles } from './styles';

export const PersonSuggestion: React.FC<PersonSuggestionProps> = ({
  mainText,
  subText,
  person,
  isSelected,
  isSelectedInner,
  index,
  mouseEnter,
  mouseLeave,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <ListItem
      key={index}
      className={isSelected || isSelectedInner ? classes.querySelected : classes.queryItem}
      id={`person-suggestion-${index}`}
      onClick={onClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      {isSelected || isSelectedInner ? (
        <PersonFilled className={classes.actionIcon} size="20" title="person" />
      ) : (
        <PersonRegular className={classes.actionIcon} size="20" title="person" />
      )}
      <PersonAvatar className={classes.personAvatar} person={person} variant="rounded" />
      <div className={classes.suggestionTextContainer}>
        <SanitizedHtmlTypography className={classes.suggestionTextMain} html={mainText} />
        <SanitizedHtmlTypography className={classes.suggestionTextSub} html={subText} />
      </div>
    </ListItem>
  );
};

interface PersonSuggestionProps {
  index: number;
  isSelected: boolean;
  isSelectedInner: boolean;
  mainText: string;
  mouseEnter: () => void;
  mouseLeave: () => void;
  onClick: () => void;
  person: ElasticPerson;
  subText: string;
}
