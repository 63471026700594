import { History } from 'history';
import React, { useCallback } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import useRouter from 'use-react-router';

import { useLoggedIn, useUserProfile } from '../../hooks';

const { REACT_APP_NO_AUTH } = process.env;

/**
 * Used to route components that need authentification to be displayed
 * If user is not logged in, he will be redirected to loginRoute
 */
export const GuardedRoute: React.FC<GuardedRouteProps> = ({
  component: Component,
  path,
  scope = 'user',
  ...rest
}) => {
  const [canRender, redirectTo] = useGuard(scope);

  const renderPrivateRoute = useCallback(
    (props) => (canRender ? <Component {...props} /> : <Redirect to={redirectTo!} />),
    [canRender, redirectTo, path],
  );

  const renderNoAuth = useCallback((props) => <Component {...props} />, []);

  if (REACT_APP_NO_AUTH === 'true') {
    // In demo environment, auth is disabled and isSignedIn is always true
    // We always render what should otherwise be private
    return <Route {...rest} path={path} render={renderNoAuth} />;
  }

  return <Route {...rest} path={path} render={renderPrivateRoute} />;
};

const useGuard = (scope: string): [true] | [false, string] => {
  const { data: user, isSuccess } = useUserProfile();
  const isLoggedIn = useLoggedIn();

  const { location } = useRouter();

  const { pathname, search } = location;
  const next = pathname + search;
  const encodedNext = encodeURIComponent(next);

  if (!isLoggedIn) return [false, `/signin?next=${encodedNext}`];

  if (scope === 'user' && !user?.isAuthorized && isSuccess) return [false, '/register'];
  if (scope === 'guest' && user?.isAuthorized && isSuccess) return [false, '/'];

  return [true];
};

interface GuardedRouteProps extends RouteProps {
  component: React.ComponentClass | React.FC;
  history?: History;
  scope?: 'guest' | 'user';
}
