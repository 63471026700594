import React from 'react';

import { useTimeAgo, useTranslations } from '../../hooks';
import { Typography } from '../../material';
import { useStyles } from './styles';

/**
 * Renders the date of the ElasticDoc from its "date" property
 */
export const DocumentDate: React.FC<DocumentDateProps> = ({
  date,
  isCreatedDate,
  isLastModifiedDate,
  isSentDate,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const makeTimeAgo = useTimeAgo();

  let helperText = '';
  if (isLastModifiedDate) helperText = t('last_edit');
  if (isCreatedDate) helperText = t('created');
  if (isSentDate) helperText = t('sent');

  const timeAgo = makeTimeAgo(date);

  return (
    <div className={classes.resultElementMeta}>
      <Typography className={classes.date} variant="subtitle2">
        <span>{helperText}</span>
        <span>{timeAgo}</span>
      </Typography>
    </div>
  );
};

interface DocumentDateProps {
  date: number;
  isCreatedDate?: boolean;
  isLastModifiedDate?: boolean;
  isSentDate?: boolean;
}
