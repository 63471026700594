import React from 'react';

import { MessagesHeaderSkeleton } from './MessageHeaderSkeleton';
import { MessageSkeleton } from './MessageSkeleton';
import { useStyles } from './styles';

export const MessagesSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <MessagesHeaderSkeleton />
      <MessageSkeleton />
      <MessageSkeleton />
    </div>
  );
};
