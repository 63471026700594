import classnames from 'classnames';
import React, { useState } from 'react';

import { useTranslations } from '../../../../hooks';
import { TranslationKey } from '../../../../locales/types';
import { Tooltip } from '../../../../material';
import { Actions, useDispatch } from '../../../../store';
import { CategoryFilter as CategoryFilterInterface } from '../../../../store/filters/types';
import { CheckBox } from '../../../MaterialComponents/CheckBox';
import { useStyles } from '../../styles';

export const CategoryFilter: React.FC<CategoryFilterProps> = ({ filter }) => {
  const classes = useStyles();
  const { category, isActive } = filter;

  const { t } = useTranslations();

  const dispatch = useDispatch();

  const [isHovered, setIsHovered] = useState(false);

  const setCategoryFilter = (bool: boolean): void => {
    dispatch(Actions.setCategoryFilter(category, bool));
  };

  const hoverOverlayClasses = classnames(classes.hoverOverlay, {
    [classes.hovered]: isHovered,
  });

  return (
    <Tooltip
      arrow
      classes={{ tooltipPlacementRight: classes.tooltipPlacementRight }}
      placement="right"
      title={t(`type_${category}_extended` as TranslationKey)}
    >
      <div
        className={classes.checkboxContainer}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={hoverOverlayClasses} />
        <CheckBox
          checked={isActive}
          color="primary"
          label={t(`type_${category}` as TranslationKey)}
          onChange={(_, bool) => setCategoryFilter(bool)}
          value={category}
        />
      </div>
    </Tooltip>
  );
};

interface CategoryFilterProps {
  filter: CategoryFilterInterface;
}
