import { useMemo } from 'react';

import { useMediaQuery } from '../../material';
import { useSelector } from '../../store';

/**
 * Hook that returns the number of results to show for displaying one row of cards
 */
export const useDisplayedResultsNumber = (index: string): number => {
  const isPreviewOpened = useSelector((s) => s.previews.isOpened);
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  const isExtraLargeScreen = useMediaQuery('(min-width:1920px)');
  const displayedResultsNumber = useMemo(() => {
    if (isPreviewOpened && ['people', 'pages'].includes(index)) return 2;
    switch (index) {
      case 'pages': {
        if (isExtraLargeScreen || isLargeScreen) return 3;
        return 2;
      }
      case 'contacts':
      case 'people': {
        if (isExtraLargeScreen) return 4;
        if (isLargeScreen) return 3;
        return 2;
      }
      default:
        return 4;
    }
  }, [index, isPreviewOpened, isLargeScreen, isExtraLargeScreen]);
  return displayedResultsNumber;
};
