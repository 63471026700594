import React, { useState } from 'react';

import { useTranslations } from '../../../hooks';
import { AddIcon, Button } from '../../../material';
import { AddSourceDialog } from '../AddSourceDialog';
import { useStyles } from './styles';

export const AddSourceDialogButtonText: React.FC = () => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const { t } = useTranslations();

  return (
    <>
      <Button
        classes={{ label: classes.addSourceButtonTextLabel }}
        color="inherit"
        onClick={() => setOpen(true)}
        startIcon={<AddIcon />}
        variant="text"
      >
        {t('add_source')}
      </Button>
      <AddSourceDialog close={() => setOpen(false)} open={open} />
    </>
  );
};
