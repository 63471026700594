/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeStyles } from '../../material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStyles = ({ sourceLogoSize }: CompactSourcesIconsStylesProps) =>
  makeStyles(
    {
      multiSourceLogoContainer: {
        '&>div': {
          marginRight: '-8px',
        },
        display: 'flex',
      },
      sourceLogoContainer: {
        alignItems: 'center',
        background: 'white',
        border: '1px solid #f1f2f6',
        borderRadius: '100%',
        display: 'flex',
        height: `${sourceLogoSize + 12}px`,
        justifyContent: 'center',
        width: `${sourceLogoSize + 12}px`,
      },
      sourceLogoImage: {
        height: `${sourceLogoSize}px`,
        width: `${sourceLogoSize}px`,
      },
    },
    { index: 1 },
  )();

interface CompactSourcesIconsStylesProps {
  sourceLogoSize: number;
}
