import { makeStyles } from '../../../material';

export const useStyles = makeStyles(
  {
    closeDialogButton: {
      color: 'rgba(0, 0, 0, 0.35);',
      position: 'absolute',
      right: 5,
      top: 5,
    },
    maxWidth: {
      maxWidth: '1600px',
    },
    previewDialogContent: {
      '&:first-child': {
        paddingTop: 0,
      },
      padding: '0',
    },
    previewDialogPaper: {
      height: '100%',
      padding: 0,
      width: '100%',
    },
  },
  { index: 1 },
);
