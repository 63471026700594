import { getFileIcon, MimeType } from '@outmind/types';

const fileTypeLogosFolder = require.context('../assets/filetypes/', false, /\.png$/);
const fileTypeLogos = {};
fileTypeLogosFolder.keys().forEach((fileName) => {
  const type = /^\.\/([a-z]+)\.png$/.exec(fileName)![1];
  fileTypeLogos[type] = fileTypeLogosFolder(fileName).default;
});

/**
 * Get the file logo associated to a MimeType
 */
export const getFileLogo = (type: MimeType, className?: string): JSX.Element | null => {
  const fileIconId = getFileIcon(type);
  const fileIcon = fileIconId in fileTypeLogos ? fileTypeLogos[fileIconId] : undefined;
  const resultElementFileType = fileIcon ? (
    <img alt="" className={className} src={fileIcon} />
  ) : null;
  return resultElementFileType;
};
