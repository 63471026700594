/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';

export enum ActionTypes {
  MICROSOFT_TEAMS_DETECTED = 'MICROSOFT_TEAMS_DETECTED',
}

export const Actions = {
  microsoftTeamsDetect: () => action(ActionTypes.MICROSOFT_TEAMS_DETECTED),
};
