import { CaretDown, CaretRight } from '@styled-icons/fluentui-system-filled';
import React from 'react';

import { Label } from '../../../hooks';
import { TreeView } from '../../../material';
import { LabelItem } from '../LabelItem';

export const LabelTree: React.FC<LabelTreeProps> = ({
  handleEdit,
  handleDelete,
  labels,
  onClickHandler,
  createMode,
  activeLabelId,
}) => {
  const renderTree = (labelArray: Label[]): JSX.Element[] =>
    labelArray.map((label) => (
      <LabelItem
        key={label.id}
        activeLabelId={activeLabelId}
        createMode={createMode}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        label={label}
        onClickHandler={onClickHandler}
      >
        {label.children ? renderTree(label.children) : undefined}
      </LabelItem>
    ));

  return (
    <TreeView
      defaultCollapseIcon={<CaretDown size={14} />}
      defaultExpandIcon={<CaretRight size={14} />}
    >
      {renderTree(labels)}
    </TreeView>
  );
};

interface LabelTreeProps {
  activeLabelId?: string;
  createMode?: boolean;
  handleDelete?: (label: Label) => void;
  handleEdit?: (label: Label) => void;
  labels: Label[];
  onClickHandler: (label: Label) => void;
}
