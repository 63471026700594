import {
  DocumentType,
  MimeType,
  SearchFilesResult,
  SearchMailsResult,
  SearchMessagesResult,
  SearchPagesResult,
  Source,
} from '@outmind/types';
import React from 'react';

import { useTranslations } from '../../../hooks';
import { useSelector } from '../../../store';
import { DocumentHeaderPreview } from './DocumentHeaderPreview';

export const HeaderPreview: React.FC<HeaderPreviewProps> = ({ documentType }) => {
  const previewInfos = useSelector((s) => s.previews.infos);
  const { t } = useTranslations();

  const getHeaderPreviewData = (): {
    accessLink?: string;
    mimeType?: MimeType;
    source: Source;
    title: string;
  } => {
    switch (documentType) {
      case DocumentType.Message: {
        const {
          document: { accessLink, source, to },
        } = previewInfos as SearchMessagesResult;
        const name = to ?? '';
        return {
          accessLink,
          source,
          title: t('in_uf', { name }),
        };
      }
      case DocumentType.Mail: {
        const {
          document: { accessLink, source, title },
        } = previewInfos as SearchMailsResult;
        return {
          accessLink,
          source,
          title,
        };
      }
      case DocumentType.File: {
        const {
          document: { accessLink, title, source, mimeType },
        } = previewInfos as unknown as SearchFilesResult;
        return {
          accessLink,
          mimeType,
          source,
          title,
        };
      }
      case DocumentType.Page: {
        const {
          document: { accessLink, title, source, mimeType },
        } = previewInfos as unknown as SearchPagesResult;
        return {
          accessLink,
          mimeType,
          source,
          title,
        };
      }
      default:
        throw new Error('No preview is found');
    }
  };

  try {
    const { accessLink, mimeType, source, title } = getHeaderPreviewData();
    return (
      <DocumentHeaderPreview
        accessLink={accessLink}
        mimeType={mimeType}
        source={source}
        title={title}
      />
    );
  } catch (err) {
    return <div>Preview is not available</div>;
  }
};

interface HeaderPreviewProps {
  documentType: DocumentType;
}
