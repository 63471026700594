import { RefObject, useLayoutEffect, useState } from 'react';

import { useSelector } from '../../store';

export const useResize = (
  elementRef: RefObject<HTMLElement> | null,
  onResize = true,
  defaultWidth = 1000,
  defaultHeight = 150,
): useResizeReturnType => {
  const getDimensions = (): useResizeReturnType => ({
    height: elementRef?.current?.offsetHeight || defaultHeight,
    width: elementRef?.current?.offsetWidth || defaultWidth,
  });

  const [dimensions, setDimensions] = useState(getDimensions());
  const { isOpened } = useSelector((s) => s.previews);

  const handleResize = (): void => {
    setTimeout(() => {
      setDimensions(getDimensions());
    }, 1);
  };
  useLayoutEffect(() => {
    if (elementRef?.current) handleResize();

    if (onResize) window.addEventListener('resize', handleResize);

    return () => {
      if (onResize) window.removeEventListener('resize', handleResize);
    };
  }, [elementRef, isOpened]);

  return dimensions;
};

interface useResizeReturnType {
  height: number;
  width: number;
}
