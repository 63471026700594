import { SearchOpportunitiesResult } from '@outmind/types';
import moment from 'moment';
import React from 'react';
import {
  CalendarOff as CloseDateIcon,
  CashBanknote as AmountIcon,
  StairsUp as StepIcon,
} from 'tabler-icons-react';

import { useLinkHandler, useTranslations } from '../../../hooks';
import { Avatar, Grid, Paper, Tooltip, Typography } from '../../../material';
import { abbreviateNumber } from '../../../util';
import { DocumentHeader } from '../DocumentHeader';
import { makeTooltipProps } from '../util';
import { useStyles } from './styles';

/**
 * Displays a CRM Account
 */
export const Opportunity: React.FC<OpportunityProps> = ({ result }) => {
  const {
    document: {
      id,
      accessLink,
      account,
      amount,
      closeDate,
      name,
      owner,
      source,
      status: oportunityStatus,
    },
  } = result;

  const classes = useStyles();
  const { t } = useTranslations();

  const ownerAvatarUrl = owner?.avatarUrl;
  const ownerName = owner?.name;

  const closeDateFormatted = moment(closeDate).format('DD/MM/YYYY');

  const tootlipProps = makeTooltipProps(t);

  const amountFriendly = amount ? `${abbreviateNumber(amount)}€` : 'N/A';

  const goToOpportunity = useLinkHandler(accessLink);

  return (
    <Paper className={classes.opportunityContainer} elevation={0} onClick={goToOpportunity}>
      <Grid className={classes.opportunityInnerContainer} container>
        <Grid item sm={12}>
          <DocumentHeader documentId={id} source={source} title={name} />
        </Grid>
        <Grid className={classes.opportunityMetasContainer} container sm={12}>
          <Tooltip {...tootlipProps('crm_opportunity_account')}>
            <Grid className={classes.opportunityInfo} item sm={3}>
              <Avatar className={classes.accountLogo} src={account?.logoUrl} />
              <Typography className={classes.opportunityInfoText}>
                {account?.name ?? 'N/A'}
              </Typography>
            </Grid>
          </Tooltip>
          <Tooltip {...tootlipProps('crm_opportunity_owner')}>
            <Grid className={classes.opportunityInfo} item sm={2}>
              <Avatar className={classes.accountLogo} src={ownerAvatarUrl} />
              <Typography className={classes.opportunityInfoText}>{ownerName}</Typography>
            </Grid>
          </Tooltip>
          <Tooltip {...tootlipProps('crm_opportunity_amount')}>
            <Grid className={classes.opportunityInfo} item sm={2}>
              <AmountIcon color="#666" size={21} />
              <Typography className={classes.opportunityInfoText}>{amountFriendly}</Typography>
            </Grid>
          </Tooltip>
          <Tooltip {...tootlipProps('crm_opportunity_status')}>
            <Grid className={classes.opportunityInfo} item sm={3}>
              <StepIcon color="#666" size={21} />
              <Typography className={classes.opportunityInfoText}>{oportunityStatus}</Typography>
            </Grid>
          </Tooltip>
          <Tooltip {...tootlipProps('crm_opportunity_close_date')}>
            <Grid className={classes.opportunityInfo} item sm={2}>
              <CloseDateIcon color="#666" size={18} />
              <Typography className={classes.opportunityInfoText}>{closeDateFormatted}</Typography>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
};

interface OpportunityProps {
  result: SearchOpportunitiesResult;
}
