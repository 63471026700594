import { History } from '@styled-icons/fluentui-system-regular';
import React from 'react';

import { ListItem } from '../../../../material';
import { SanitizedHtmlTypography } from '../../../SanitizedHtmlTypography';
import { useStyles } from './styles';

export const QuerySuggestion: React.FC<QuerySuggestionProps> = ({
  mainText,
  isSelected,
  isSelectedInner,
  index,
  mouseEnter,
  mouseLeave,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <ListItem
      key={index}
      className={isSelected || isSelectedInner ? classes.querySelected : classes.queryItem}
      id={`query-suggestion-${index}`}
      onClick={onClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <History className={classes.actionIcon} size="20" title="history" />

      <div className={classes.suggestionTextContainer}>
        <SanitizedHtmlTypography className={classes.suggestionTextMain} html={mainText} />
      </div>
    </ListItem>
  );
};

interface QuerySuggestionProps {
  index: number;
  isSelected: boolean;
  isSelectedInner: boolean;
  mainText: string;
  mouseEnter: () => void;
  mouseLeave: () => void;
  onClick: () => void;
}
