import { Connector } from '@outmind/types';
import React from 'react';

import { useDeleteConnectors } from '../../../../hooks';
import { CancelConfigureSourceDialog } from './CancelConfigureSourcesDialog';

export const CancelConfigureSources: React.FC<CancelConfigureSourceProps> = ({
  connectors,
  onClose,
  onDelete,
  showReallyDeleteDialog,
}) => {
  const { mutate: deleteConnectors } = useDeleteConnectors();

  return (
    <CancelConfigureSourceDialog
      onClose={() => onClose()}
      onConfirmed={() => {
        deleteConnectors(connectors);
        onDelete();
      }}
      open={showReallyDeleteDialog}
    />
  );
};

interface CancelConfigureSourceProps {
  connectors: Pick<Connector, 'id' | 'source'>[];
  onClose: () => void;
  onDelete: () => void;
  showReallyDeleteDialog: boolean;
}
