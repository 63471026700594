import * as microsoftTeams from '@microsoft/teams-js';
import { useCallback } from 'react';

import { Actions, useDispatch, useSelector } from '../../store';
import { useRuntimeEnv } from '../useRuntimeEnv';

/**
 * Returns a link handler which a method that will correctly redirect to the desired href depending
 */
export const useLinkHandler = (
  href?: string,
  options: useLinkHandlerOptions = { targetBlank: true },
): (() => void) => {
  const inMicrosoftTeams = useSelector((s) => s.user.inMicrosoftTeams);
  const { inElectron } = useRuntimeEnv();
  const dispatch = useDispatch();

  const linkHandler = useCallback(() => {
    if (inMicrosoftTeams && href) {
      // When we are in Microsoft Teams app, the OAuth2 redirect flow should happen with
      // the help of `authentication.authenticate` method from Teams SDK
      microsoftTeams.authentication.authenticate({
        failureCallback: (err) =>
          console.error(`Something went wrong with Microsoft Teams popup : ${err}`),
        height: 800,
        successCallback: (next) => {
          if (next) document.location.href = next;
        },
        url: href,
        width: 600,
      });
    } else if (href) {
      if (options.isLocalFile) {
        if (inElectron) {
          const fileExists = window.electron!.fileExists(href);
          if (fileExists) window.electron!.openLocalFile(href);
          else dispatch(Actions.openFileNotFoundDialog());
        } else {
          dispatch(Actions.openAppDialog());
        }
      } else {
        window.open(href, options.targetBlank ? '_blank' : '_self');
      }
    }
  }, [href, inMicrosoftTeams, inElectron, options]);

  return linkHandler;
};

interface useLinkHandlerOptions {
  isLocalFile?: boolean;
  targetBlank?: boolean;
}
