import { makeStyles } from '../../../material';

export const useStyles = makeStyles(
  {
    openInOutmindButton: {
      marginRight: '15px',
    },
    openInOutmindButtonContainer: {
      background: 'white',
      borderRadius: '0 0 8px 8px',
      borderTop: '1px solid #f4f4f4',
      boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    openInOutmindButtonLabel: {
      '&::first-letter': {
        textTransform: 'uppercase',
      },
      fontSize: '0.825rem',
      fontWeight: 600,
      textTransform: 'none',
    },
  },
  { index: 1 },
);
