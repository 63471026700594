import { makeStyles } from '../../../material';

export const useStyles = makeStyles(
  {
    accountLogo: {
      '& svg': {
        width: '100%',
      },
      height: '21px',
      margin: '4px',
      width: '21px',
    },
    flex: {
      display: 'flex',
    },
    opportunityContainer: {
      '&:hover': {
        backgroundColor: '#E9F0FD',
        cursor: 'pointer',
      },
      borderBottom: '1px solid #eee',
      color: '#222222',
      display: 'block',
      padding: '16px 24px',
      textDecoration: 'none',
    },
    opportunityInfo: {
      alignItems: 'center',
      display: 'flex',
    },
    opportunityInfos: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    opportunityInfoText: {
      color: '#666',
      fontSize: '0.875rem',
      overflow: 'hidden',
      padding: '0 4px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    opportunityInnerContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    opportunityMetasContainer: {
      marginTop: '4px',
    },
  },
  { index: 1 },
);
