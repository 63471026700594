import React from 'react';

import { useStyles } from './styles';

export const ImagePreview: React.FC<ImagePreviewProps> = ({
  imageUrl,
  onImageError,
  onImageLoad,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.imageContainer}>
      <img
        alt="Zoomable"
        className={classes.image}
        onError={onImageError}
        onLoad={onImageLoad}
        src={imageUrl}
      />
    </div>
  );
};

interface ImagePreviewProps {
  imageUrl: string;
  onImageError?: () => void;
  onImageLoad?: () => void;
}
